import React from 'react'

import { useMvuData } from '../../../../providers/MvuDataContext'
import { VerticallyAligned } from '../../../../shared/layout/Alignment'
import { Col, Container, Row } from '../../../../shared/layout/Grid'
import { VerticalSpace } from '../../../../shared/layout/Space'
import { themeColors } from '../../../../Theme'
import { ImagePanel, Label, Panel } from '../../shared/SubComponents'
import { Template } from '../../shared/Template'

const Appendix: React.FC = () => {
	const { project, geographies } = useMvuData()
	const padding = 0

	return (
		<Template
			pageTitle="6. Appendix"
			project={project}
			reportType="Most Viable Use Case Analysis"
			localAuthority={geographies[0].name}
			region={geographies[1].name}
		>
			<Container size="fluid" removePadding={true}>
				<Row>
					<Panel height={664} padding={40} transparent={true}>
						<VerticallyAligned>
							<div>
								<Row gutterSize={6}>
									<Label point={12} align="center" color={themeColors.grey}>
										A few of our many data sources
									</Label>
								</Row>
								<VerticalSpace size="md" />
								<Row>
									<Col count={3}>
										<ImagePanel src={`${process.env.PUBLIC_URL}/img/logo/logo-ons.png`} padding={padding} />
									</Col>

									<Col count={3}>
										<ImagePanel src={`${process.env.PUBLIC_URL}/img/logo/logo-cqc.png`} padding={padding} />
									</Col>
									<Col count={3}>
										<ImagePanel src={`${process.env.PUBLIC_URL}/img/logo/logo-census.png`} padding={padding} />
									</Col>
									<Col count={3}>
										<ImagePanel src={`${process.env.PUBLIC_URL}/img/logo/logo-mhclg.png`} padding={padding} />
									</Col>
								</Row>
								<VerticalSpace size="xs" />
								<Row>
									<Col count={3}>
										<ImagePanel src={`${process.env.PUBLIC_URL}/img/logo/logo-dhsc.png`} padding={padding} />
									</Col>

									<Col count={3}>
										<ImagePanel src={`${process.env.PUBLIC_URL}/img/logo/logo-nhs.png`} padding={padding} />
									</Col>
									<Col count={3}>
										<ImagePanel src={`${process.env.PUBLIC_URL}/img/logo/logo-dwp.png`} padding={padding} />
									</Col>
									<Col count={3}>
										<ImagePanel src={`${process.env.PUBLIC_URL}/img/logo/logo-dsit.png`} padding={padding} />
									</Col>
								</Row>
								<VerticalSpace size="xs" />
								<Row>
									<Col count={3}>
										<ImagePanel src={`${process.env.PUBLIC_URL}/img/logo/logo-os.png`} padding={padding} />
									</Col>

									<Col count={3}>
										<ImagePanel src={`${process.env.PUBLIC_URL}/img/logo/logo-gm.png`} padding={padding} />
									</Col>
									<Col count={3}>
										<ImagePanel src={`${process.env.PUBLIC_URL}/img/logo/logo-hmlr.png`} padding={padding} />
									</Col>
									<Col count={3}>
										<ImagePanel src={`${process.env.PUBLIC_URL}/img/logo/logo-defra.png`} padding={padding} />
									</Col>
								</Row>
								<VerticalSpace size="xs" />
								<Row>
									<Col count={3}>
										<ImagePanel src={`${process.env.PUBLIC_URL}/img/logo/logo-ch.png`} padding={padding} />
									</Col>

									<Col count={3}>
										<ImagePanel src={`${process.env.PUBLIC_URL}/img/logo/logo-boe.png`} padding={padding} />
									</Col>
									<Col count={3}>
										<ImagePanel src={`${process.env.PUBLIC_URL}/img/logo/logo-knightfrank.png`} padding={padding} />
									</Col>
									<Col count={3}>
										<ImagePanel src={`${process.env.PUBLIC_URL}/img/logo/logo-propmatch.png`} padding={padding} />
									</Col>
								</Row>
							</div>
						</VerticallyAligned>
					</Panel>
				</Row>
			</Container>
		</Template>
	)
}

export { Appendix }
