import graphql from 'babel-plugin-relay/macro'

export const LaTableElderlyCareStatsFragment = graphql`
	fragment LaTableElderlyCareStatsFragment on CareStats {
		stats {
			code
			populationOver65
			numberOfBeds
		}
	}
`
