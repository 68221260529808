import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { currentYear } from '../../../../../Constants'
import {
	PopulationByCaQuery,
	RadiusValues,
} from '../../../../../graphql/queries/__generated__/PopulationByCaQuery.graphql'
import { PopulationByCa } from '../../../../../graphql/queries/PopulationByCa'
import { GraphQlGeography } from '../../../../../models/geography'
import { Project } from '../../../../../models/project'
import { Model } from '../../../../../shared/charts/types'
import { SpaceBetween } from '../../../../../shared/layout/Alignment'
import { VerticalSpace } from '../../../../../shared/layout/Space'
import { Loading } from '../../../../../shared/Loading'
import { themeColors } from '../../../../../Theme'
import { SimpleBarChart } from '../../../shared/SimpleBarChart'
import { Label, Panel } from '../../../shared/SubComponents'

interface Props {
	project: Project
	geographies: GraphQlGeography
	radius: RadiusValues
	height: string
}

const PopulationForecast: React.FC<Props> = ({ project, radius, height }) => {
	const futureOffset = 6

	const { populationByCa: popOver65 } = useLazyLoadQuery<PopulationByCaQuery>(PopulationByCa, {
		oaCode: project.oaCode,
		radius,
		startYear: currentYear,
		rangeSize: 100,
		minAge: 65,
		maxAge: 100,
		futureOffset,
		includeIntermediates: false,
	})

	const { populationByCa: popOver75To84 } = useLazyLoadQuery<PopulationByCaQuery>(PopulationByCa, {
		oaCode: project.oaCode,
		radius,
		startYear: currentYear,
		rangeSize: 100,
		minAge: 75,
		maxAge: 84,
		futureOffset,
		includeIntermediates: false,
	})

	const { populationByCa: popOver85 } = useLazyLoadQuery<PopulationByCaQuery>(PopulationByCa, {
		oaCode: project.oaCode,
		radius,
		startYear: currentYear,
		rangeSize: 100,
		minAge: 85,
		maxAge: 100,
		futureOffset,
		includeIntermediates: false,
	})

	if (!popOver65) {
		return null
	}

	const caOver65 = [...popOver65.geographies[0].ageRanges[0].values]
	const ca75To84 = [...popOver75To84.geographies[0].ageRanges[0].values]
	const caOver85 = [...popOver85.geographies[0].ageRanges[0].values]

	const model: Model = {
		labels: ['65+', '75-84', '85+'],
		datasets: caOver65.map((_, index) => ({
			values: [caOver65[index], ca75To84[index], caOver85[index]],
		})),
	}

	const years = popOver65.years

	const colorFunc = (index: number) => themeColors.primaryAlpha(index === 0 ? 1 : 0.5)

	return (
		<Panel height={height} padding={20}>
			<SpaceBetween>
				<Label point={9}>Elderly Population Forecast</Label>
				<div>
					{years.map((label, index) => (
						<Label key={index} point={6} color={colorFunc(index)} align="right">
							{label}
						</Label>
					))}
				</div>
			</SpaceBetween>
			<VerticalSpace size="lg" />
			<SimpleBarChart model={model} aspectRatio={1.7} colorFunc={colorFunc} />
		</Panel>
	)
}

const Suspended: React.FC<Props> = (props) => {
	return (
		<React.Suspense fallback={<Loading height="80vh" />}>
			<PopulationForecast {...props} />
		</React.Suspense>
	)
}

export { Suspended as PopulationForecast }
