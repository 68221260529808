import React, { useState } from 'react'
import { fetchQuery } from 'react-relay'

import { openAiVersions } from '../../Constants'
import { PromptAgentQuery } from '../../graphql/queries/__generated__/PromptAgentQuery.graphql'
import { PromptAgent } from '../../graphql/queries/PromptAgent'
import { Page } from '../../layout/page/Page'
import { environment } from '../../relay/RelayProviderWithAuth'
import { PrimaryButton } from '../../shared/Button'
import { Input } from '../../shared/Input'
import { Col, Container, Row } from '../../shared/layout/Grid'
import { VerticalSpace } from '../../shared/layout/Space'
import { Panel } from '../../shared/Panel'

const Agent: React.FC = () => {
	const [inputValue, setInputValue] = useState('')
	const [response, setResponse] = useState<string | null>(null)
	const [errorMessage, setErrorMessage] = useState<string | null>(null)
	const [isLoading, setIsLoading] = useState(false)

	// Handle form submission
	const handleSubmit = (e: React.FormEvent) => {
		e.preventDefault()
		setErrorMessage(null)
		setIsLoading(true)

		// Call fetchQuery manually when the form is submitted
		const observable = fetchQuery<PromptAgentQuery>(
			environment, // Your Relay environment
			PromptAgent, // The query you want to execute
			{ content: inputValue, version: openAiVersions.v4 }, // Variables for the query
		)

		// Use subscribe to handle the data from RelayObservable
		observable.subscribe({
			next: (data) => {
				// Handle the response
				if (data && data.promptAgent) {
					setResponse(data.promptAgent.response || 'No response')
				} else {
					setErrorMessage('No valid response from server.')
				}
			},
			error: (err: any) => {
				// Handle any error that occurs during the fetch
				console.error('Error:', err)
				setErrorMessage(err.message || 'An error occurred')
				setIsLoading(false)
			},
			complete: () => {
				// Set loading to false when the request finishes
				setIsLoading(false)
			},
		})
	}

	return (
		<Page title="SQL Agent">
			<Container>
				<Row>
					<Col count={12}>
						<Panel>
							<form onSubmit={handleSubmit}>
								<Input
									type="text"
									value={inputValue}
									onChange={(e) => setInputValue(e.target.value)}
									placeholder="Enter SQL request (e.g. 'List all users')"
								/>
								<VerticalSpace size="xs" />
								<PrimaryButton disabled={isLoading} type="submit">
									{isLoading ? 'Submitting...' : 'Submit'}
								</PrimaryButton>
							</form>
							{errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
							{response && <Table json={response} />}
						</Panel>
					</Col>
				</Row>
			</Container>
		</Page>
	)
}

interface TableProps {
	json: string
}

const Table: React.FC<TableProps> = ({ json }) => {
	const data = JSON.parse(json)

	const headers = Object.keys(data[0])

	const rows = data.map((row: any) => {
		return Object.values(row).map((value: any) => {
			if (typeof value === 'boolean') {
				return value ? 'True' : 'False'
			}
			if (typeof value !== 'string' && typeof value !== 'number') {
				return typeof value
			}
			if (typeof value === 'string') {
				return value.trim().length > 50 ? value.trim().substring(0, 50) + '...' : value.trim()
			}
			return value
		})
	})

	const tableStyle: React.CSSProperties = {
		tableLayout: 'fixed',
	}

	const cellStyle: React.CSSProperties = {
		wordWrap: 'break-word',
	}

	return (
		<table className="table" style={tableStyle}>
			<thead>
				<tr>
					{headers.map((header: string, index: number) => (
						<th key={index}>{header}</th>
					))}
				</tr>
			</thead>
			<tbody>
				{rows.map((row: any, index: number) => (
					<tr key={index}>
						{row.map((cell: any, index: number) => (
							<td key={index} style={cellStyle}>
								{cell}
							</td>
						))}
					</tr>
				))}
			</tbody>
		</table>
	)
}

export { Agent }
