import React from 'react'

import { GraphQlAaAwardTypes } from '../../../../../models/aaAwardTypes'
import { GraphqlDementiaStats } from '../../../../../models/dementiaStats'
import { GraphQlPopulation } from '../../../../../models/population'
import { VerticalSpace } from '../../../../../shared/layout/Space'
import { themeColors } from '../../../../../Theme'
import { formatNumber, formatPercentage } from '../../../../../utils/formatNumber'
import { getGeoValuesFromDataset } from '../../../../../utils/getGeoValuesFromDataset'
import { Label, Panel } from '../../../shared/SubComponents'
import { Table, TableData, TableRow } from '../../../shared/Table'

interface Props {
	regionName: string
	height: string
	over75: GraphQlPopulation
	dementiaStats: GraphqlDementiaStats
	allowanceAwards: GraphQlAaAwardTypes
}

const CareNeeds: React.FC<Props> = ({ regionName, over75, dementiaStats, allowanceAwards, height }) => {
	const {
		ca: caH,
		region: regionH,
		//country: countryH
	} = getGeoValuesFromDataset(allowanceAwards.higherRate)
	const {
		ca: caL,
		region: regionL, //country: countryL
	} = getGeoValuesFromDataset(allowanceAwards.lowerRate)

	const geos = over75?.geographies || []
	if (geos.length === 0) {
		return null
	}
	const idx = over75.years.length - 1

	const caPop75Plus = geos[0].ageRanges[0].values[idx]
	const regionPop75Plus = geos[geos.length - 2].ageRanges[0].values[idx]
	// const countryPop75Plus = geos[geos.length - 1].ageRanges[0].values[idx]

	const {
		ca: caDementiaEstimated,
		region: regionDementiaEstimated,
		// country: countryDementiaEstimated,
	} = getGeoValuesFromDataset(dementiaStats.estimated)
	const {
		ca: caDementiaRegistered,
		region: regionDementiaRegistered,
		// country: countryDementiaRegistered,
	} = getGeoValuesFromDataset(dementiaStats.registered)

	const headers: TableRow = ['', '5-Mile Catchment', { value: regionName, color: themeColors.grey }]

	const data: TableData = [
		[
			[
				{ value: 'Elderly 75+' },
				{ value: formatNumber(caPop75Plus), bold: true },
				{ value: formatNumber(regionPop75Plus) },
			],
		],
		[
			[
				{ value: 'Elderly in need of full-time care', whiteSpace: 'nowrap' },
				{ value: formatNumber(caH), bold: true },
				{ value: formatNumber(regionH) },
			],
			[
				{ value: '%' },
				{
					value: formatPercentage((caH / caPop75Plus) * 100, 1, 1),
					bold: caH / caPop75Plus > regionH / regionPop75Plus ? true : false,
					color: caH / caPop75Plus > regionH / regionPop75Plus ? themeColors.primary : undefined,
				},
				{ value: formatPercentage((regionH / regionPop75Plus) * 100, 1, 1) },
			],
		],
		[
			[
				{ value: 'Elderly in need of part-time care', whiteSpace: 'nowrap' },
				{ value: formatNumber(caL), bold: true },
				{ value: formatNumber(regionL) },
			],
			[
				{ value: '%' },
				{
					value: formatPercentage((caL / caPop75Plus) * 100, 1, 1),
					bold: caL / caPop75Plus > regionL / regionPop75Plus ? true : false,
					color: caL / caPop75Plus > regionL / regionPop75Plus ? themeColors.primary : undefined,
				},
				{ value: formatPercentage((regionL / regionPop75Plus) * 100, 1, 1) },
			],
		],
		[
			[
				{ value: 'Estimated Dementia Cases', whiteSpace: 'nowrap' },
				{
					value: formatNumber(caDementiaEstimated, 0, 0),
					bold: true,
				},
				{ value: formatNumber(regionDementiaEstimated, 0, 0) },
			],
			[
				{ value: '%' },
				{
					value: formatPercentage((caDementiaEstimated / caPop75Plus) * 100, 1, 1),
					bold: caDementiaEstimated / caPop75Plus > regionDementiaEstimated / regionPop75Plus ? true : false,
					color:
						caDementiaEstimated / caPop75Plus > regionDementiaEstimated / regionPop75Plus
							? themeColors.primary
							: undefined,
				},
				{ value: formatPercentage((regionDementiaEstimated / regionPop75Plus) * 100, 1, 1) },
			],
		],
		[
			[
				{ value: 'Registered Dementia Cases', whiteSpace: 'nowrap' },
				{
					value: formatNumber(caDementiaRegistered),
					bold: true,
				},
				{ value: formatNumber(regionDementiaRegistered) },
			],
			[
				{ value: '%' },
				{
					value: formatPercentage((caDementiaRegistered / caPop75Plus) * 100, 1, 1),
					bold: caDementiaRegistered / caPop75Plus > regionDementiaRegistered / regionPop75Plus ? true : false,
					color:
						caDementiaRegistered / caPop75Plus > regionDementiaRegistered / regionPop75Plus
							? themeColors.primary
							: undefined,
				},
				{ value: formatPercentage((regionDementiaRegistered / regionPop75Plus) * 100, 1, 1) },
			],
		],
	]

	return (
		<Panel height={height} padding={20}>
			<Label point={9}>Elderly with Care Needs</Label>
			<VerticalSpace size="xl" />
			<Table headers={headers} data={data} footers={['']} />
		</Panel>
	)
}

export { CareNeeds }
