import { GetTokenSilentlyOptions } from '@auth0/auth0-react'
import fetchWithRetries from 'fbjs/lib/fetchWithRetries'
import type { FetchFunction, RequestParameters } from 'relay-runtime'
import { Environment, Network, RecordSource, Store } from 'relay-runtime'

import { getAuthorizationParams } from '../Config'

export function createRelayEnvironment(
	apiUrl: string,
	getToken: (options?: GetTokenSilentlyOptions) => Promise<string>,
) {
	const isMutation = (request: RequestParameters) => request.operationKind === 'mutation'

	// Define a function that fetches the results of an operation (query/mutation/etc)
	// and returns its results as a Promise:
	const fetchFunction: FetchFunction = async (request, variables) => {
		const body = JSON.stringify({
			query: request.text, // GraphQL text from input
			variables,
		})

		const token = await getToken({
			authorizationParams: getAuthorizationParams(),
		})

		const headers = {
			Accept: 'application/json',
			Authorization: `Bearer ${token}`,
			'Content-type': 'application/json',
		}

		const response = await fetchWithRetries(apiUrl, {
			method: 'POST',
			headers,
			body,
			fetchTimeout: 20000,
			retryDelays: [
				1000,
				// 3000, 5000, 10000
			],
		})

		const data = await response.json()

		if (isMutation(request) && data.errors) {
			throw data
		}

		return data
	}

	// Create a network layer from the fetch function
	const network = Network.create(fetchFunction)
	const store = new Store(new RecordSource())

	const environment = new Environment({
		network,
		store,
	})

	return environment
}
