import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { Specialism } from '../../Constants'
import { CareStatsByLadCodeQuery } from '../../graphql/queries/__generated__/CareStatsByLadCodeQuery.graphql'
import { CareStatsByLadCode } from '../../graphql/queries/CareStatsByLad'
import { formatNumber } from '../../utils/formatNumber'
import { QuickFact, QuickFactPlaceholder } from '../QuickFact'

interface ElderlyBedShortagesFactProps {
	ladCode: string
	compareRegion?: boolean
}

const ElderlyCareBedShortagesByLad: React.FC<ElderlyBedShortagesFactProps> = ({ ladCode, compareRegion }) => {
	const ecsData = useLazyLoadQuery<CareStatsByLadCodeQuery>(CareStatsByLadCode, {
		specialism: Specialism.elderly,
		ladCode: ladCode,
	})

	const { stats } = ecsData.careStatsByLadCode

	const bedShortagesLad = (stats[0].numberOfBeds || 0) - (stats[0].higherRateAllowance || 0)
	const bedShortageRegion = (stats[1].numberOfBeds || 0) - (stats[1].higherRateAllowance || 0)
	const bedShortageNational = (stats[2].numberOfBeds || 0) - (stats[2].higherRateAllowance || 0)

	const comparison = compareRegion ? bedShortageRegion : bedShortageNational

	return (
		<QuickFact
			heading="Bed Shortage"
			value={formatNumber(bedShortagesLad, 0, 0)}
			comparison={formatNumber(comparison, 0, 0)}
			isComparable={false}
			valueColoringNegativeVsPositive={true}
		/>
	)
}

const Suspended: React.FC<ElderlyBedShortagesFactProps> = (props) => {
	return (
		<React.Suspense fallback={<QuickFactPlaceholder />}>
			<ElderlyCareBedShortagesByLad {...props} />
		</React.Suspense>
	)
}

export { Suspended as ElderlyCareBedShortagesByLad }
