/**
 * @generated SignedSource<<3493b456ebe3f7137f26b4f3e74e1535>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type RadiusValues = "eight" | "five" | "four" | "nine" | "one" | "seven" | "six" | "ten" | "three" | "two" | "%future added value";
export type AAAwardTypesByCaQuery$variables = {
  oaCode: string;
  radius: RadiusValues;
};
export type AAAwardTypesByCaQuery$data = {
  readonly aaAwardTypesByCa: {
    readonly higherRate: ReadonlyArray<{
      readonly code: string;
      readonly values: ReadonlyArray<number>;
    }>;
    readonly labels: ReadonlyArray<string>;
    readonly lowerRate: ReadonlyArray<{
      readonly code: string;
      readonly values: ReadonlyArray<number>;
    }>;
    readonly total: ReadonlyArray<{
      readonly code: string;
      readonly values: ReadonlyArray<number>;
    }>;
  };
};
export type AAAwardTypesByCaQuery = {
  response: AAAwardTypesByCaQuery$data;
  variables: AAAwardTypesByCaQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "oaCode"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "radius"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "code",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "values",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "oaCode",
        "variableName": "oaCode"
      },
      {
        "kind": "Variable",
        "name": "radius",
        "variableName": "radius"
      }
    ],
    "concreteType": "AAAwardTypes",
    "kind": "LinkedField",
    "name": "aaAwardTypesByCa",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "labels",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AAAwardTypeData",
        "kind": "LinkedField",
        "name": "higherRate",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AAAwardTypeData",
        "kind": "LinkedField",
        "name": "lowerRate",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AAAwardTypeData",
        "kind": "LinkedField",
        "name": "total",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AAAwardTypesByCaQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AAAwardTypesByCaQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "d49cd920870f27dac6ca0a8c65c6703f",
    "id": null,
    "metadata": {},
    "name": "AAAwardTypesByCaQuery",
    "operationKind": "query",
    "text": "query AAAwardTypesByCaQuery(\n  $oaCode: String!\n  $radius: RadiusValues!\n) {\n  aaAwardTypesByCa(oaCode: $oaCode, radius: $radius) {\n    labels\n    higherRate {\n      code\n      values\n    }\n    lowerRate {\n      code\n      values\n    }\n    total {\n      code\n      values\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0378d60c2901ef40c07fed3f6998119c";

export default node;
