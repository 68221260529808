import React from 'react'

import { VerticallyAligned } from '../shared/layout/Alignment'
import { VerticalSpace } from '../shared/layout/Space'
import { Panel } from '../shared/Panel'
import { HeadingPlaceholder } from '../shared/placeholder/Placeholder'
import { CenteredText, Heading } from '../shared/Text'
import { themeColors } from '../Theme'

interface QuickFactProps {
	heading: string
	value: string
	comparison: string
	isComparable?: boolean
	reverseComparison?: boolean
	comparisonRegion?: string
	valueColoringNegativeVsPositive?: boolean
}

const QuickFact: React.FC<QuickFactProps> = ({
	heading,
	value,
	comparison,
	isComparable = true,
	reverseComparison = false,
	valueColoringNegativeVsPositive = false,
}) => {
	const color = valueColoringNegativeVsPositive
		? parseFloat(value) > 0
			? themeColors.primary
			: themeColors.danger
		: isComparable
			? (reverseComparison ? value < comparison : value > comparison)
				? themeColors.primary
				: themeColors.danger
			: themeColors.darkText

	return (
		<Panel padding={16}>
			<VerticallyAligned>
				<CenteredText>
					<Heading level={6}>{heading}</Heading>
					<Heading level={3} color={color}>
						{value}
					</Heading>
					<Heading level={6} color={themeColors.grey}>
						{comparison}
					</Heading>
				</CenteredText>
			</VerticallyAligned>
		</Panel>
	)
}

const QuickFactPlaceholder: React.FC = () => (
	<Panel padding={24}>
		<VerticallyAligned>
			<div style={{ width: '100%' }}>
				<HeadingPlaceholder level={6} />
				<VerticalSpace size="xs" />
				<HeadingPlaceholder level={3} />
				<VerticalSpace size="xs" />
				<HeadingPlaceholder level={6} />
			</div>
		</VerticallyAligned>
	</Panel>
)

export { QuickFact, QuickFactPlaceholder }
