import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'

import { Specialism } from '../../Constants'
import { AAAwardTypesByCaQuery, RadiusValues } from '../../graphql/queries/__generated__/AAAwardTypesByCaQuery.graphql'
import { CareStatsByCaQuery } from '../../graphql/queries/__generated__/CareStatsByCaQuery.graphql'
import { SitesByCaQuery } from '../../graphql/queries/__generated__/SitesByCaQuery.graphql'
import { AAAwardTypesByCa } from '../../graphql/queries/AAAwardTypesByCa'
import { CareStatsByCa } from '../../graphql/queries/CareStatsByCa'
import { SitesByCa } from '../../graphql/queries/SitesByCa'
import { formatPercentage } from '../../utils/formatNumber'
import { QuickFact, QuickFactPlaceholder } from '../QuickFact'
import { ElderlyBedShortagesQuickFactFragment$key } from './__generated__/ElderlyBedShortagesQuickFactFragment.graphql'
import { ElderlyBedShortagesQuickFactFragment } from './ElderlyBedShortagesQuickFactFragment'

interface ElderlyBedShortagesFactProps {
	oaCode: string
	radius: RadiusValues
	compareRegion?: boolean
}

const ElderlyCareSupplyRateByCa: React.FC<ElderlyBedShortagesFactProps> = ({ oaCode, radius, compareRegion }) => {
	const demandData = useLazyLoadQuery<AAAwardTypesByCaQuery>(AAAwardTypesByCa, { oaCode, radius })
	const { labels, higherRate } = demandData.aaAwardTypesByCa

	const index = labels.length - 1
	const demandCa = higherRate[0].values[index]

	const ecsData = useLazyLoadQuery<CareStatsByCaQuery>(CareStatsByCa, {
		specialism: Specialism.elderly,
		oaCode,
		radius,
	})

	const { stats } = ecsData.careStatsByCa

	const sitesData = useLazyLoadQuery<SitesByCaQuery>(SitesByCa, {
		oaCode,
		radius,
	})

	const sites = useFragment<ElderlyBedShortagesQuickFactFragment$key>(
		ElderlyBedShortagesQuickFactFragment,
		sitesData.sitesByCa.sites,
	)

	const bedsCa = sites.reduce((acc, site) => acc + (site.numberOfBeds ?? 0), 0)

	const bedShortageCa = (bedsCa / demandCa) * 100

	const bedShortageRegion =
		((stats[stats.length - 2].numberOfBeds || 0) / (stats[stats.length - 2].higherRateAllowance || 0)) * 100
	const bedShortageNational =
		((stats[stats.length - 1].numberOfBeds || 0) / (stats[stats.length - 1].higherRateAllowance || 0)) * 100

	const comparison = compareRegion ? bedShortageRegion : bedShortageNational

	return (
		<QuickFact
			heading="Care Bed Supply Rate"
			value={formatPercentage(bedShortageCa, 0, 0)}
			comparison={formatPercentage(comparison, 0, 0)}
			isComparable={true}
		/>
	)
}

const Suspended: React.FC<ElderlyBedShortagesFactProps> = (props) => {
	return (
		<React.Suspense fallback={<QuickFactPlaceholder />}>
			<ElderlyCareSupplyRateByCa {...props} />
		</React.Suspense>
	)
}

export { Suspended as ElderlyCareSupplyRateByCa }
