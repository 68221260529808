/**
 * @generated SignedSource<<d8180eb34a0b55ddade4ce450688dda1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GeographyTypes = "country" | "lad" | "region" | "ward" | "%future added value";
export type Specialism = "dementia" | "elderly" | "%future added value";
export type CareStatsByTypesQuery$variables = {
  specialism: Specialism;
  types: ReadonlyArray<GeographyTypes>;
};
export type CareStatsByTypesQuery$data = {
  readonly careStatsByTypes: {
    readonly " $fragmentSpreads": FragmentRefs<"ElderlyCareStatsCaFragment" | "ElderlyCareStatsFragment" | "LaTableElderlyCareStatsFragment">;
  };
};
export type CareStatsByTypesQuery = {
  response: CareStatsByTypesQuery$data;
  variables: CareStatsByTypesQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "specialism"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "types"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "specialism",
    "variableName": "specialism"
  },
  {
    "kind": "Variable",
    "name": "types",
    "variableName": "types"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CareStatsByTypesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CareStats",
        "kind": "LinkedField",
        "name": "careStatsByTypes",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ElderlyCareStatsFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "LaTableElderlyCareStatsFragment"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ElderlyCareStatsCaFragment"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CareStatsByTypesQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CareStats",
        "kind": "LinkedField",
        "name": "careStatsByTypes",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "CareStatData",
            "kind": "LinkedField",
            "name": "stats",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "code",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "populationOver65",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "numberOfBeds",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b4d1c6df286b985f347e3024051d8c9a",
    "id": null,
    "metadata": {},
    "name": "CareStatsByTypesQuery",
    "operationKind": "query",
    "text": "query CareStatsByTypesQuery(\n  $specialism: Specialism!\n  $types: [GeographyTypes!]!\n) {\n  careStatsByTypes(specialism: $specialism, types: $types) {\n    ...ElderlyCareStatsFragment\n    ...LaTableElderlyCareStatsFragment\n    ...ElderlyCareStatsCaFragment\n  }\n}\n\nfragment ElderlyCareStatsCaFragment on CareStats {\n  stats {\n    code\n    populationOver65\n    numberOfBeds\n  }\n}\n\nfragment ElderlyCareStatsFragment on CareStats {\n  stats {\n    code\n    populationOver65\n    numberOfBeds\n  }\n}\n\nfragment LaTableElderlyCareStatsFragment on CareStats {\n  stats {\n    code\n    populationOver65\n    numberOfBeds\n  }\n}\n"
  }
};
})();

(node as any).hash = "b4f01120c64da14035a6e0b0f5353735";

export default node;
