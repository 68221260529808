import graphql from 'babel-plugin-relay/macro'

export const CareFundingByLad = graphql`
	query CareFundingByLadCodeQuery($careType: CareFundingType!, $ladCode: String!) {
		careFundingByLadCode(careType: $careType, ladCode: $ladCode) {
			labels
			StateFunded {
				code
				values
			}
			SelfFunded {
				code
				values
			}
		}
	}
`
