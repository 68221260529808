import { elderlySpecialismKey, siteRatingPoints } from '../Constants'

function filterEldery<T extends { specialisms?: string }>(sites: ReadonlyArray<T>): ReadonlyArray<T> {
	return sites.filter((site) => {
		const specialisms: { name: string }[] = JSON.parse(site.specialisms || '[]')

		return specialisms && specialisms.some((s) => s.name === elderlySpecialismKey)
	})
}

function calculateRating(siteRating: keyof typeof siteRatingPoints) {
	const ratingValue = siteRatingPoints[siteRating] || -1
	return ratingValue
}

export { calculateRating, filterEldery }
