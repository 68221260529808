import React from 'react'

import { themeColors } from '../Theme'

interface TrafficLightProps {
	color?: 'red' | 'yellow' | 'green'
	size?: number
}

const colorPalette = {
	red: themeColors.danger,
	yellow: themeColors.warning,
	green: themeColors.success,
}

const TrafficLight: React.FC<TrafficLightProps> = ({ color = 'green', size = 10 }) => {
	const style = {
		borderRadius: size ? size / 2 : 50,
		backgroundColor: colorPalette[color],
		height: size,
		width: size,
	}
	return <div style={style}></div>
}

export { TrafficLight }
