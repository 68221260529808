import { themeColors } from '../../Theme'
import { formatter } from '../../utils/formatNumber'

export const tooltipConfig = (prefix: string, suffix: string) => ({
	callbacks: {
		label: function (context: any) {
			const datasetLabel = context.dataset.label || ''
			const value = context.raw
			return `${datasetLabel}: ${formatter(prefix, suffix)(value)}`
		},
	},
	usePointStyle: true,
	bodyColor: themeColors.darkText,
	titleColor: themeColors.darkText,
	boxPadding: 4,
	borderColor: themeColors.darkText,
	borderWidth: 0.5,
	padding: 12,
})
