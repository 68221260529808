import graphql from 'babel-plugin-relay/macro'

export const CareFundingByCa = graphql`
	query CareFundingByCaQuery($careType: CareFundingType!, $oaCode: String!, $radius: RadiusValues!) {
		careFundingByCa(careType: $careType, oaCode: $oaCode, radius: $radius) {
			labels
			StateFunded {
				code
				values
			}
			SelfFunded {
				code
				values
			}
		}
	}
`
