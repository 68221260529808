import React from 'react'

import { milesRadiusValueInInts } from '../../../../../Constants'
import { RadiusValues } from '../../../../../graphql/queries/__generated__/PopulationByCaQuery.graphql'
import { GraphQlCareStats } from '../../../../../models/careStats'
import { GraphqlDementiaStats } from '../../../../../models/dementiaStats'
import { GraphQlPopulation } from '../../../../../models/population'
import { Col, Row } from '../../../../../shared/layout/Grid'
import { VerticalSpace } from '../../../../../shared/layout/Space'
import { CareScore } from './CareScore'

interface Props {
	regionName: string
	radius: RadiusValues
	elderlyPop: GraphQlPopulation
	elderlyCareStats: GraphQlCareStats
	dementiaCareStats: GraphQlCareStats
	dementiaStats: GraphqlDementiaStats
}

const ElderlyScores: React.FC<Props> = ({
	regionName,
	radius,
	elderlyCareStats,
	elderlyPop,
	dementiaCareStats,
	dementiaStats,
}) => {
	const radiusInt = milesRadiusValueInInts[radius]
	const radiusLabel = `${radiusInt} mile radius`

	return (
		<>
			<Row gutterSize={2}>
				<Col count={6}>
					<CareScore
						radiusLabel={radiusLabel}
						regionName={regionName}
						scoreType="nursing"
						title="Nursing Care"
						careStats={elderlyCareStats}
						dementiaStats={dementiaStats}
						elderlyPop={elderlyPop}
					/>
				</Col>
				<Col count={6}>
					<CareScore
						radiusLabel={radiusLabel}
						regionName={regionName}
						scoreType="residential"
						title="Residential Care"
						careStats={elderlyCareStats}
						dementiaStats={dementiaStats}
						elderlyPop={elderlyPop}
					/>
				</Col>
				<VerticalSpace size="xs" />
				<Col count={6}>
					<CareScore
						radiusLabel={radiusLabel}
						regionName={regionName}
						scoreType="extraCare"
						title="Extra Care"
						careStats={elderlyCareStats}
						dementiaStats={dementiaStats}
						elderlyPop={elderlyPop}
					/>
				</Col>
				<Col count={6}>
					<CareScore
						radiusLabel={radiusLabel}
						regionName={regionName}
						scoreType="dementia"
						title="Dementia Care"
						careStats={dementiaCareStats}
						dementiaStats={dementiaStats}
						elderlyPop={elderlyPop}
					/>
				</Col>
			</Row>
		</>
	)
}

export { ElderlyScores }
