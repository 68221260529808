import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { AADisablingConditionsByLadCodeQuery } from '../../graphql/queries/__generated__/AADisablingConditionsByLadCodeQuery.graphql'
import { GeographiesByCodesQuery } from '../../graphql/queries/__generated__/GeographiesByCodesQuery.graphql'
import { PopulationByLadCodeQuery } from '../../graphql/queries/__generated__/PopulationByLadCodeQuery.graphql'
import { AADisablingConditionsByLadCode } from '../../graphql/queries/AADisablingConditionsByLad'
import { GeographiesByCodes } from '../../graphql/queries/GeographiesByCodes'
import { PopulationByLadCode } from '../../graphql/queries/PopulationByLad'
import { Dataset, Model } from '../../shared/charts/types'
import { DementiaPrevalenceRateChart } from './DementiaPrevalenceRateChart'

interface LadDementiaPrevalenceRateChartProps {
	ladCode: string
}

const LadDementiaPrevalenceRateChart: React.FC<LadDementiaPrevalenceRateChartProps> = ({ ladCode }) => {
	// Fetch elderly population data
	const { populationByLadCode: pop } = useLazyLoadQuery<PopulationByLadCodeQuery>(PopulationByLadCode, {
		ladCode: ladCode,
		startYear: 2019,
		rangeSize: 100,
		minAge: 65,
		maxAge: 100,
		futureOffset: 5,
		includeIntermediates: true,
	})

	// Fetch dementia count data
	const { aaDisablingConditionsByLadCode: dis } = useLazyLoadQuery<AADisablingConditionsByLadCodeQuery>(
		AADisablingConditionsByLadCode,
		{
			ladCode,
		},
	)

	const codes = pop.geographies.map((d) => d.code)
	const { geographiesByCodes: geos = [] } = useLazyLoadQuery<GeographiesByCodesQuery>(GeographiesByCodes, {
		codes,
	})

	// Match the indexes of pop.years with dis.labels
	const yearIndexMap = new Map(dis.labels.map((label, index) => [label, index]))

	const datasets: Dataset[] = pop.geographies.map((geo, i) => ({
		id: geos.find((g) => g.code === geo.code)?.name || geo.code,
		values: geo.ageRanges[0].values.map((value, index) => {
			const year = pop.years[index]
			const disIndex = yearIndexMap.get(`${year}`)
			return disIndex !== undefined ? (dis.datasets[i].values[disIndex] / value) * 100 : 0
		}),
	}))

	const commonLabels = pop.years.filter((year) => dis.labels.includes(`${year}`))
	const labels = commonLabels.map((year) => year.toString())

	const model: Model = {
		labels,
		datasets,
	}

	return <DementiaPrevalenceRateChart model={model} />
}

export { LadDementiaPrevalenceRateChart }
