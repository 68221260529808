/**
 * @generated SignedSource<<66c38684a41b7a6db59ca8ee93e24a4b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type GeographyTypes = "country" | "lad" | "region" | "ward" | "%future added value";
export type RadiusValues = "eight" | "five" | "four" | "nine" | "one" | "seven" | "six" | "ten" | "three" | "two" | "%future added value";
export type Specialism = "dementia" | "elderly" | "%future added value";
export type CareStatsByCaQuery$variables = {
  oaCode: string;
  radius: RadiusValues;
  specialism: Specialism;
};
export type CareStatsByCaQuery$data = {
  readonly careStatsByCa: {
    readonly stats: ReadonlyArray<{
      readonly careHomeWorkers: number;
      readonly careSupplyRate: number | null | undefined;
      readonly code: string;
      readonly domiciliaryCareWorkers: number;
      readonly higherRateAllowance: number;
      readonly numberOfBeds: number;
      readonly nursingBeds: number;
      readonly occupancyRate: number;
      readonly rating: number;
      readonly residentialBeds: number;
      readonly type: GeographyTypes;
    }>;
  };
};
export type CareStatsByCaQuery = {
  response: CareStatsByCaQuery$data;
  variables: CareStatsByCaQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "oaCode"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "radius"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "specialism"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "oaCode",
        "variableName": "oaCode"
      },
      {
        "kind": "Variable",
        "name": "radius",
        "variableName": "radius"
      },
      {
        "kind": "Variable",
        "name": "specialism",
        "variableName": "specialism"
      }
    ],
    "concreteType": "CareStats",
    "kind": "LinkedField",
    "name": "careStatsByCa",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CareStatData",
        "kind": "LinkedField",
        "name": "stats",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "careSupplyRate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numberOfBeds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nursingBeds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "residentialBeds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "higherRateAllowance",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "careHomeWorkers",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "domiciliaryCareWorkers",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "occupancyRate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rating",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CareStatsByCaQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CareStatsByCaQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "c3f53668d88d28513500d6db490d8d22",
    "id": null,
    "metadata": {},
    "name": "CareStatsByCaQuery",
    "operationKind": "query",
    "text": "query CareStatsByCaQuery(\n  $specialism: Specialism!\n  $oaCode: String!\n  $radius: RadiusValues!\n) {\n  careStatsByCa(specialism: $specialism, oaCode: $oaCode, radius: $radius) {\n    stats {\n      code\n      type\n      careSupplyRate\n      numberOfBeds\n      nursingBeds\n      residentialBeds\n      higherRateAllowance\n      careHomeWorkers\n      domiciliaryCareWorkers\n      occupancyRate\n      rating\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "29e49bf11ba00e996f395173a150c88b";

export default node;
