import React from 'react'

interface InputProps {
	type?: string
	value: string
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
	placeholder?: string
	disabled?: boolean
	className?: string
}

const Input: React.FC<InputProps> = ({
	type = 'text',
	value,
	onChange,
	placeholder = 'Enter text...',
	disabled = false,
	className = '',
}) => {
	return (
		<input
			type={type}
			value={value}
			onChange={onChange}
			placeholder={placeholder}
			disabled={disabled}
			className={`form-control ${className}`}
		/>
	)
}

export { Input }
