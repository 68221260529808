import {
	CategoryScale,
	Chart as ChartJS,
	ChartData,
	ChartOptions,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	Title,
	Tooltip,
} from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Context } from 'chartjs-plugin-datalabels'
import React from 'react'
import { Line } from 'react-chartjs-2'

import { themeColors } from '../../Theme'
import { formatter } from '../../utils/formatNumber'
import { Pills } from '../Pills'
import { tooltipConfig } from './ChartToolTip'
import { Model } from './types'

type DataLabelDisplayOption = 'showAll' | 'hideAll' | 'showOnlyLast' | 'showForSelectedLabels'

// Register Chart.js components and annotation plugin
ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	annotationPlugin,
	ChartDataLabels,
)

interface LineChartProps {
	model: Model
	prefix?: string
	suffix?: string
	aspectRatio?: number
	dataLabelDisplayOption?: DataLabelDisplayOption
	selectedLabels?: string[]
	responsive?: boolean
	displayY?: boolean
	fontsize?: number
}

const shouldDisplayDataLabel = (
	context: Context,
	option: DataLabelDisplayOption,
	selectedLabels: string[],
): boolean => {
	const { dataset, dataIndex, chart } = context
	const dataLength = dataset.data.length
	const label = chart.data.labels ? chart.data.labels[dataIndex] : undefined

	switch (option) {
		case 'showAll':
			return true
		case 'hideAll':
			return false
		case 'showOnlyLast':
			return dataIndex === dataLength - 1
		case 'showForSelectedLabels':
			return label ? selectedLabels.includes(label as string) : false
		default:
			return false
	}
}

export const LineChart: React.FC<LineChartProps> = ({
	model: modelData,
	prefix = '',
	suffix = '',
	aspectRatio = 9 / 5,
	dataLabelDisplayOption = 'showAll',
	selectedLabels = [],
	responsive = true,
	displayY = true,
	fontsize,
}) => {
	// Cast to Model
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const model = modelData as any as Model

	const { colorPalette } = themeColors

	// Generate datasets with dynamic borderColor and borderWidth
	const datasets = model.datasets.map((d, i) => ({
		label: d.id,
		data: d.values,
		borderColor: colorPalette[i % colorPalette.length],
		borderWidth: i === 0 ? 2.5 : 2.5,
		tension: 0.3,
		pointRadius: 2,
		pointBorderWidth: 0,
		pointBackgroundColor: colorPalette[i % colorPalette.length],
		pointHitRadius: 20,
		pointHoverRadius: 6,
		pointHoverBackgroundColor: colorPalette[i % colorPalette.length],
		pointHoverBorderWidth: 2,
		pointHoverBorderColor: colorPalette[i % colorPalette.length],
		datalabels: {
			borderColor: colorPalette[i % colorPalette.length],
			color: colorPalette[i % colorPalette.length],
		},
	}))

	// Data for the chart
	const data: ChartData<'line'> = {
		labels: model.labels,
		datasets,
	}

	const scalesConfig = {
		x: {
			grid: {
				display: false,
			},
			ticks: {
				padding: 10,
				font: { size: fontsize },
			},
			border: {
				display: false,
			},
		},
		y: {
			grid: {
				display: true,
			},
			border: {
				display: false,
				padding: 20,
			},
			ticks: {
				callback: formatter(prefix, suffix),
				display: true,
				padding: 20,
			},
			display: displayY,
		},
	}

	// Options for the chart
	const options: ChartOptions<'line'> = {
		responsive,
		interaction: {
			intersect: false,
		},
		layout: {
			padding: {
				top: 20,
				right: 50,
				left: 0,
			},
		},
		scales: scalesConfig,
		aspectRatio,
		plugins: {
			legend: { display: false },
			tooltip: tooltipConfig(prefix, suffix),
			datalabels: {
				display: (context: Context) => shouldDisplayDataLabel(context, dataLabelDisplayOption, selectedLabels),
				padding: 6,
				borderRadius: 32,
				borderWidth: 3,
				font: {
					size: fontsize,
					weight: 700,
				},
				offset: -15,
				align: 'start',
				anchor: 'center',
				formatter: formatter(prefix, suffix),
				textAlign: 'center',
			},
		},
	}

	const ids: string[] = model.datasets.filter((d) => d.id).map((d) => d.id as string)

	return (
		<div>
			{ids.length > 0 && <Pills ids={ids} />}
			<Line data={data} options={options} />
		</div>
	)
}
