import React from 'react'

import { RadiusValues } from '../../../../../graphql/queries/__generated__/CareStatsByCaQuery.graphql'
import { GraphQlAaAwardTypes } from '../../../../../models/aaAwardTypes'
import { GraphQlCareFunding } from '../../../../../models/careFunding'
import { GraphQlCareStats } from '../../../../../models/careStats'
import { GraphqlDementiaStats } from '../../../../../models/dementiaStats'
import { GraphQlDemographics } from '../../../../../models/demographics'
import { GraphQlGeography } from '../../../../../models/geography'
import { GraphQlPopulation } from '../../../../../models/population'
import { Project } from '../../../../../models/project'
import { calculateMvuSummary } from '../../../../../utils/calculateMvuSummary'
import { SummaryPanel } from '../../../shared/SummaryPanel'

interface InfoPanelProps {
	project: Project
	geographies: GraphQlGeography
	radius: RadiusValues
	height: number
	mvu: string
	popIn3: GraphQlPopulation
	popIn6: GraphQlPopulation
	elderlyCareStats: GraphQlCareStats
	unemployment: GraphQlDemographics
	housePrices: GraphQlDemographics
	gdhi: GraphQlDemographics
	hourlyWages: GraphQlDemographics
	fees: GraphQlDemographics
	funding: GraphQlCareFunding
	allowanceAwards: GraphQlAaAwardTypes
	migration: GraphQlDemographics
	dementiaStats: GraphqlDementiaStats
}

const InfoPanel: React.FC<InfoPanelProps> = ({
	project,
	geographies,
	height,
	mvu,
	popIn3,
	popIn6,
	elderlyCareStats,
	unemployment,
	housePrices,
	hourlyWages,
	gdhi,
	fees,
	funding,
	allowanceAwards,
	migration,
	dementiaStats,
}) => {
	const numberOfBeds = project.settings.numberOfBeds || 0
	const regionName = geographies[1].name

	const promptData = calculateMvuSummary({
		numberOfBeds,
		popIn3,
		popIn6,
		elderlyCareStats,
		unemployment,
		housePrices,
		hourlyWages,
		gdhi,
		fees,
		funding,
		allowanceAwards,
		migration,
		dementiaStats,
	})
	return (
		<SummaryPanel
			title="Summary"
			prompt={`
Create a short and professional market summary (max 600 characters) for our care home property project. For numbers round to 2 decimals.
Please use line breaks for 1-2 paragraphs, but the total must stay within the 600 characters limit!!!

Focus on:
2) Elderly Care Quality and bed supply rates in the 5-mile catchment area (CA) vs. the region ${regionName}, where lower supply rates than the region mean better market opportunity / higher need!
3) Elderly Care Demand & Shortage Forecast (2024–2030) and 75+ population growth.

Key data:

• • Bed Supply Rate per 75+ (2024):  
   – Nursing: CA ${((promptData.caNursingBeds / promptData.caPop75_2024) * 100).toFixed(2)} vs. Region ${((promptData.regionNursingBeds / promptData.regionPop75_2024) * 100).toFixed(2)}  
   – Residential: CA ${((promptData.caResidentialBeds / promptData.caPop75_2024) * 100).toFixed(2)} vs. Region ${((promptData.regionResidentialBeds / promptData.regionPop75_2024) * 100).toFixed(2)}  
   – Total: CA ${((promptData.caBeds / promptData.caPop75_2024) * 100).toFixed(2)} vs. Region ${((promptData.regionBeds / promptData.regionPop75_2024) * 100).toFixed(2)}  
• Dementia Prevalence rate (2024): CA ${((promptData.caDementia / promptData.caPop75_2024) * 100).toFixed(2)} vs. Region ${((promptData.regionDementia / promptData.regionPop75_2024) * 100).toFixed(2)}  highlight if prevalence is higher for catchment area otherwise leave out.
• 75+ Population Forecast (CA): 2024=${promptData.caPop75_2024}, 2027=${promptData.caPop75_2027}, 2030=${promptData.caPop75_2030}
• Full-time Care Demand and bed shortages (CA): 2024=${promptData.caDemand_2024}, Shortage=${promptData.caBedShortage_2024}; 2027=${promptData.caDemand_2027}, Shortage=${promptData.caBedShortage_2027}; Shortage 2030=${promptData.caBedShortage_2030}

Write a concise paragraph of max 600 characters explaining why ${mvu} suits these capacity and demand metrics, highlighting regional comparisons where relevant.
`}
			cacheKey={`${project.id}_ma_27
				`}
			height={height}
		/>
	)
}

// 1) The identified use case: ${mvu}.
// • CA Beds: Nursing ${promptData.caNursingBeds}, Residential ${promptData.caResidentialBeds}, Total ${promptData.caBeds},

export { InfoPanel }
