import React from 'react'

import { knightFrank } from '../../../../../Constants'
import { GraphQlCareFunding } from '../../../../../models/careFunding'
import { GraphQlCareStats } from '../../../../../models/careStats'
import { GraphQlDemographics } from '../../../../../models/demographics'
import { VerticalSpace } from '../../../../../shared/layout/Space'
import { themeColors } from '../../../../../Theme'
import { formatCurrency, formatPercentage } from '../../../../../utils/formatNumber'
import { getGeosFromGeographies } from '../../../../../utils/getGeoValuesFromDataset'
import { Label, Panel } from '../../../shared/SubComponents'
import { Table, TableCell } from '../../../shared/Table'

interface Props {
	projectName: string
	regionName: string
	height: number
	funding: GraphQlCareFunding
	fees: GraphQlDemographics
	hourlyWages: GraphQlDemographics
	elderlyCareStats: GraphQlCareStats
}

const Profitability: React.FC<Props> = ({
	regionName,
	height,
	projectName,
	fees,
	funding,
	hourlyWages,
	elderlyCareStats,
}) => {
	const { datasets: hwDatasets } = hourlyWages

	const {
		ca: caHourlyWageData,
		region: regionHourlyWageData,
		country: countryHourlyWageData,
	} = getGeosFromGeographies(hwDatasets)

	const caHourlyWage = caHourlyWageData.values[0]
	const regionHourlyWage = regionHourlyWageData.values[0]
	const countryHourlyWage = countryHourlyWageData.values[0]

	// Fee Analysis
	const caRating = elderlyCareStats.stats[0].rating * 100

	const caMarginFraction =
		knightFrank.cqcMargins.baseCqcMargin + (knightFrank.cqcMargins.rangeCqcMargin * caRating) / 100
	const projectMarginFraction =
		knightFrank.cqcMargins.baseCqcMargin + knightFrank.cqcMargins.rangeCqcMargin * knightFrank.cqcMargins.projectRating
	const feeMultiplier = (1 - caMarginFraction) / (1 - projectMarginFraction)

	const caStateFundingShare = funding.StateFunded[0].values[0]
	const regionStateFundingShare = funding.StateFunded[funding.StateFunded.length - 2].values[0]

	const { datasets: feeDatasets } = fees
	const caLaFeeResidential = feeDatasets[0].values[4]
	const regionLaFeeResidential = feeDatasets[feeDatasets.length - 2].values[4]

	const caPrivateFeeResidential = caLaFeeResidential * knightFrank.privateBedFees.residentialStateToPrivate
	const regionPrivateFeeResidential = regionLaFeeResidential * knightFrank.privateBedFees.residentialStateToPrivate

	const caLaFeeNursing = feeDatasets[0].values[5]
	const regionLaFeeNursing = feeDatasets[feeDatasets.length - 2].values[5]

	const caPrivateFeeNursing = caLaFeeNursing * knightFrank.privateBedFees.nursingStateToPrivate
	const regionPrivateFeeNursing = regionLaFeeNursing * knightFrank.privateBedFees.nursingStateToPrivate

	const caExpectedAvgResidentialFee =
		(caStateFundingShare * caLaFeeResidential) / 100 + (1 - caStateFundingShare / 100) * caPrivateFeeResidential
	const projectExpectedResidentialFee = caExpectedAvgResidentialFee * feeMultiplier
	const regionExpectedAvgResidentialFee =
		(regionStateFundingShare * regionLaFeeResidential) / 100 +
		(1 - regionStateFundingShare / 100) * regionPrivateFeeResidential

	const caExpectedAvgNursingFee =
		(caStateFundingShare * caLaFeeNursing) / 100 + (1 - caStateFundingShare / 100) * caPrivateFeeNursing
	const projectExpectedNursingFee = caExpectedAvgNursingFee * feeMultiplier
	const regionExpectedAvgNursingFee =
		(regionStateFundingShare * regionLaFeeNursing) / 100 + (1 - regionStateFundingShare / 100) * regionPrivateFeeNursing

	// Profitability
	const caWageIndex = caHourlyWage / countryHourlyWage
	const regionWageIndex = regionHourlyWage / countryHourlyWage

	const countryStaffingCost_PC = knightFrank.annualStaffingCostPerBed.residential * knightFrank.occupancy.residential
	const caStaffingCost_PC = caWageIndex * countryStaffingCost_PC
	const regionStaffingCost_PC = regionWageIndex * countryStaffingCost_PC

	const countryStaffingCost_N = knightFrank.annualStaffingCostPerBed.nursing * knightFrank.occupancy.nursing
	const caStaffingCost_N = caWageIndex * countryStaffingCost_N
	const regionStaffingCost_N = regionWageIndex * countryStaffingCost_N

	const caOperatingCost_PC = caStaffingCost_PC + knightFrank.overheadCost.totalResidentialPerBed
	const regionOperatingCost_PC = regionStaffingCost_PC + knightFrank.overheadCost.totalResidentialPerBed

	const caOperatingCost_N = caStaffingCost_N + knightFrank.overheadCost.totalNursingPerBed
	const regionOperatingCost_N = regionStaffingCost_N + knightFrank.overheadCost.totalNursingPerBed

	const caTurnover_PC = caExpectedAvgResidentialFee * 52
	const regionTurnover_PC = regionExpectedAvgResidentialFee * 52
	const projectTurnover_PC = projectExpectedResidentialFee * 52

	const caTurnover_N = caExpectedAvgNursingFee * 52
	const regionTurnover_N = regionExpectedAvgNursingFee * 52
	const projectTurnover_N = projectExpectedNursingFee * 52

	const caProfit_PC = caTurnover_PC - caOperatingCost_PC
	const regionProfit_PC = regionTurnover_PC - regionOperatingCost_PC
	const projectProfit_PC = projectTurnover_PC - caOperatingCost_PC

	const caProfit_N = caTurnover_N - caOperatingCost_N
	const regionProfit_N = regionTurnover_N - regionOperatingCost_N
	const projectProfit_N = projectTurnover_N - caOperatingCost_N

	const caProfitMargin_PC = (caProfit_PC / caTurnover_PC) * 100
	const regionProfitMargin_PC = (regionProfit_PC / regionTurnover_PC) * 100
	const projectProfitMargin_PC = (projectProfit_PC / projectTurnover_PC) * 100

	const caProfitMargin_N = (caProfit_N / caTurnover_N) * 100
	const regionProfitMargin_N = (regionProfit_N / regionTurnover_N) * 100
	const projectProfitMargin_N = (projectProfit_N / projectTurnover_N) * 100

	const headers = [
		{ value: 'Annual Per Resident', point: 7, bold: true },
		projectName,
		{ value: '5-Mile Radius', color: themeColors.grey },
		{ value: regionName, color: themeColors.grey },
	]

	const nursingData: (string | TableCell)[][] = [
		[{ value: 'Nursing' }],
		[
			{ value: 'Turnover', bold: false },
			{ value: formatCurrency(projectTurnover_N, 0), bold: true },
			formatCurrency(caTurnover_N, 0),
			formatCurrency(regionTurnover_N, 0),
		],
		[
			{ value: 'Operating Cost', bold: false },
			{ value: formatCurrency(caOperatingCost_N, 0), bold: true },
			formatCurrency(caOperatingCost_N, 0),
			formatCurrency(regionOperatingCost_N, 0),
		],
		[
			{ value: 'EBITDARM	', bold: false },
			{ value: formatCurrency(projectProfit_N, 0), bold: true },
			formatCurrency(caProfit_N, 0),
			formatCurrency(regionProfit_N, 0),
		],
		[
			{ value: 'Margin', bold: false },
			{
				value: formatPercentage(projectProfitMargin_N, 0, 1),
				color: themeColors.primary,
				bold: true,
			},
			{ value: formatPercentage(caProfitMargin_N, 0, 1) },
			{ value: formatPercentage(regionProfitMargin_N, 0, 1) },
		],
	]

	const residential: (string | TableCell)[][] = [
		[{ value: 'Residential' }],
		[
			{ value: 'Turnover', bold: false },
			{ value: formatCurrency(projectTurnover_PC, 0), bold: true },
			formatCurrency(caTurnover_PC, 0),
			formatCurrency(regionTurnover_PC, 0),
		],
		[
			{ value: 'Operating Cost', bold: false },
			{ value: formatCurrency(caOperatingCost_PC, 0), bold: true },
			formatCurrency(caOperatingCost_PC, 0),
			formatCurrency(regionOperatingCost_PC, 0),
		],
		[
			{ value: 'EBITDARM	', bold: false },
			{ value: formatCurrency(projectProfit_PC, 0), bold: true },
			formatCurrency(caProfit_PC, 0),
			formatCurrency(regionProfit_PC, 0),
		],
		[
			{ value: 'Margin', bold: false },
			{
				value: formatPercentage(projectProfitMargin_PC, 0, 1),
				color: themeColors.primary,
				bold: true,
			},
			{ value: formatPercentage(caProfitMargin_PC, 0, 1) },
			{ value: formatPercentage(regionProfitMargin_PC, 0, 1) },
		],
	]

	const multiData = [nursingData, residential]

	return (
		<Panel height={height} padding={20}>
			<Label point={9}>Profitability per Resident</Label>
			<VerticalSpace size="sm" />
			<Table headers={headers} data={multiData} footers={['']} />
		</Panel>
	)
}

export { Profitability }
