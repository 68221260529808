import React from 'react'

import { RadiusValues } from '../../../../../graphql/queries/__generated__/CareStatsByCaQuery.graphql'
import { GraphQlAaAwardTypes } from '../../../../../models/aaAwardTypes'
import { GraphQlCareFunding } from '../../../../../models/careFunding'
import { GraphQlCareStats } from '../../../../../models/careStats'
import { GraphqlDementiaStats } from '../../../../../models/dementiaStats'
import { GraphQlDemographics } from '../../../../../models/demographics'
import { GraphQlGeography } from '../../../../../models/geography'
import { GraphQlPopulation } from '../../../../../models/population'
import { Project } from '../../../../../models/project'
import { calculateMvuSummary } from '../../../../../utils/calculateMvuSummary'
import { SummaryPanel } from '../../../shared/SummaryPanel'

interface InfoPanelProps {
	project: Project
	geographies: GraphQlGeography
	radius: RadiusValues
	height: number
	mvu: string
	popIn3: GraphQlPopulation
	popIn6: GraphQlPopulation
	elderlyCareStats: GraphQlCareStats
	unemployment: GraphQlDemographics
	housePrices: GraphQlDemographics
	gdhi: GraphQlDemographics
	hourlyWages: GraphQlDemographics
	fees: GraphQlDemographics
	funding: GraphQlCareFunding
	allowanceAwards: GraphQlAaAwardTypes
	migration: GraphQlDemographics
	dementiaStats: GraphqlDementiaStats
}

const InfoPanel: React.FC<InfoPanelProps> = ({
	project,
	geographies,
	height,
	popIn3,
	popIn6,
	elderlyCareStats,
	unemployment,
	housePrices,
	hourlyWages,
	gdhi,
	fees,
	funding,
	allowanceAwards,
	migration,
	dementiaStats,
}) => {
	const numberOfBeds = project.settings.numberOfBeds || 0
	const localAuthorityName = geographies[0].name
	const regionName = geographies[1].name

	const promptData = calculateMvuSummary({
		numberOfBeds,
		popIn3,
		popIn6,
		elderlyCareStats,
		unemployment,
		housePrices,
		hourlyWages,
		gdhi,
		fees,
		funding,
		allowanceAwards,
		migration,
		dementiaStats,
	})

	return (
		<SummaryPanel
			title="Summary"
			prompt={`
 

You are assessing the numbers for the business forecast of our care home project ${project.name} in ${localAuthorityName} and comparing it to the 5 mile catchment area and the region (${regionName}).
The project is a care home with ${project.settings.numberOfBeds} beds and the project is expected to have a CQC rating between Good and Outstanding (0.83).:
1) Staffing & Wages (e.g., higher wages = easier recruitment, lower wages = better margins).
	- CA wages: £${promptData.caPersonalCareWage} (personal), £${promptData.caCareWage} (nursing)
   - Region wages: £${promptData.regionPersonalCareWage} (personal), £${promptData.regionCareWage} (nursing)
   - Ease of recruitment vs. margin impact
   - Migration: ${promptData.caMigration}%, Region: ${promptData.regionMigration}%
   - Indicate staff availability and retention potential
2) Weekly Bed Fee Analysis (LA vs. private),    - CA Nursing LA Fee: £${promptData.caLaFeeNursing}, Private: £${promptData.caPrivateFeeNursing}
   - CA Residential LA Fee: £${promptData.caLaFeeResidential}, Private: £${promptData.caPrivateFeeResidential}
   - Fee multiplier ${promptData.feeMultiplier} = uplift for a CQC rating ~Good–Outstanding
3) Business Forecast: 
   • ${project.settings.numberOfBeds} beds,
   • Turnover: £${promptData.projectTurnoverMixedHome},
   • EBITDARM: £${promptData.projectEBITDARMMixedHome} (Margin: ${promptData.projectEBITDARMMarginMixedHome}%),
   • Rent: £${promptData.projectRentMixedHome},
   • Valuation: £${promptData.projectValuation},
   • EBITDA Margin: ${promptData.projectEBITDAMargin}%.

Write a concise summary on staffing, weekly bed fee analysis and business forecast explaining how these factors ensure financial viability and sufficient staffing for our care home project, use line breaks for paragraphs for better readability (but with no titles or bullet points) based on the metrics provided above.
Make absolutely sure to not exceed a strict maximum length of 600 characters in total (!!!). When quoting numbers round to 2 decimals if needed or/and quote big numbers like £1,331,796.21 as £1.3M and for margins add % as suffix. 
`}
			cacheKey={`${project.id}_ba_13`}
			height={height}
		/>
	)
}

export { InfoPanel }
