import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { Specialism } from '../../Constants'
import { CareStatsByCaQuery, RadiusValues } from '../../graphql/queries/__generated__/CareStatsByCaQuery.graphql'
import { CareStatsByCa } from '../../graphql/queries/CareStatsByCa'
import { formatNumber } from '../../utils/formatNumber'
import { QuickFact, QuickFactPlaceholder } from '../QuickFact'

interface ElderlyBedShortagesFactProps {
	oaCode: string
	radius: RadiusValues
	compareRegion?: boolean
}

const ElderlyCareBedShortagesByCaByCareStats: React.FC<ElderlyBedShortagesFactProps> = ({
	oaCode,
	radius,
	compareRegion,
}) => {
	const ecsData = useLazyLoadQuery<CareStatsByCaQuery>(CareStatsByCa, {
		specialism: Specialism.elderly,
		oaCode,
		radius,
	})

	const { stats } = ecsData.careStatsByCa

	const bedShortagesCa = (stats[0].numberOfBeds || 0) - (stats[0].higherRateAllowance || 0)
	const bedShortageRegion =
		(stats[stats.length - 2].numberOfBeds || 0) - (stats[stats.length - 2].higherRateAllowance || 0)
	const bedShortageNational =
		(stats[stats.length - 1].numberOfBeds || 0) - (stats[stats.length - 1].higherRateAllowance || 0)

	const comparison = compareRegion ? bedShortageRegion : bedShortageNational

	return (
		<QuickFact
			heading="Bed Shortage"
			value={formatNumber(bedShortagesCa, 0, 0)}
			comparison={formatNumber(comparison, 0, 0)}
			isComparable={true}
			valueColoringNegativeVsPositive={true}
		/>
	)
}

const Suspended: React.FC<ElderlyBedShortagesFactProps> = (props) => {
	return (
		<React.Suspense fallback={<QuickFactPlaceholder />}>
			<ElderlyCareBedShortagesByCaByCareStats {...props} />
		</React.Suspense>
	)
}

export { Suspended as ElderlyCareBedShortagesByCa }
