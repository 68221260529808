import './AppRoutes.css'

import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import { Admin } from '../pages/admin/Admin'
import { Agent } from '../pages/agent/Agent'
import { Ca } from '../pages/ca/Ca'
import { Lad } from '../pages/lad/Lad'
import { Lads } from '../pages/lads/Lads'
import { Me } from '../pages/me/Me'
import { Operator } from '../pages/operator/Operator'
import { OperatorGroup } from '../pages/operatorGroup/OperatorGroup'
import { OperatorGroups } from '../pages/operatorGroups/OperatorGroups'
import { Operators } from '../pages/operators/Operators'
import { CreateProjectPage } from '../pages/projects/CreateProject'
import { Projects } from '../pages/projects/Projects'
import { UpdateProjectPage } from '../pages/projects/UpdateProject'
import { Report } from '../pages/report/Report'
import { Data } from '../pages/report/table/Data'
import { Site } from '../pages/site/Site'
import { Sites } from '../pages/sites/Sites'
import { MainLayout } from './MainLayout'

const AppRoutes: React.FC = () => (
	<Router
		future={{
			v7_relativeSplatPath: true,
			v7_startTransition: true,
		}}
	>
		<Routes>
			<Route element={<MainLayout />}>
				<Route path="/" element={<Lad />} />
				<Route path="/agent" element={<Agent />} />
				<Route path="/admin" element={<Admin />} />
				<Route path="/ca" element={<Ca />} />
				<Route path="/ca/:latLong" element={<Ca />} />
				<Route path="/ca/:latLong/:radius" element={<Ca />} />
				<Route path="/ca/:latLong/:radius/:category" element={<Ca />} />
				<Route path="/ca/:latLong/:radius/:category/:tab" element={<Ca />} />
				<Route path="/groups" element={<OperatorGroups />} />
				<Route path="/groups/:id" element={<OperatorGroup />} />
				<Route path="/lads" element={<Lads />} />
				<Route path="/lads/:ladCode" element={<Lad />} />
				<Route path="/lads/:ladCode/:category" element={<Lad />} />
				<Route path="/lads/:ladCode/:category/:tab" element={<Lad />} />
				<Route path="/me" element={<Me />} />
				<Route path="/operators" element={<Operators />} />
				<Route path="/operators/:id" element={<Operator />} />
				<Route path="/projects" element={<Projects />} />
				<Route path="/projects/create" element={<CreateProjectPage />} />
				<Route path="/projects/update/:reference" element={<UpdateProjectPage />} />
				<Route path="/report/:reference" element={<Report />} />
				<Route path="/sites" element={<Sites />} />
				<Route path="/sites/:id" element={<Site />} />
				<Route path="/data/:reference" element={<Data />} />
				<Route path="/data/:reference/:beds" element={<Data />} />
			</Route>
		</Routes>
	</Router>
)

export { AppRoutes }
