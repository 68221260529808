/**
 * @generated SignedSource<<f97c269639eba505c3f0753cd216cf2d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LaTableElderlyCareStatsFragment$data = {
  readonly stats: ReadonlyArray<{
    readonly code: string;
    readonly numberOfBeds: number;
    readonly populationOver65: number;
  }>;
  readonly " $fragmentType": "LaTableElderlyCareStatsFragment";
};
export type LaTableElderlyCareStatsFragment$key = {
  readonly " $data"?: LaTableElderlyCareStatsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"LaTableElderlyCareStatsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "LaTableElderlyCareStatsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CareStatData",
      "kind": "LinkedField",
      "name": "stats",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "populationOver65",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numberOfBeds",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CareStats",
  "abstractKey": null
};

(node as any).hash = "c30fdac84a0a0aa6267cd0ec89b9089a";

export default node;
