/**
 * @generated SignedSource<<99c56c860e99cda09985dcd353ffa100>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ProjectStatus = "approved" | "pipeline" | "planning" | "%future added value";
export type ProjectsByUserIdQuery$variables = {
  userId: string;
};
export type ProjectsByUserIdQuery$data = {
  readonly projectsByUserId: {
    readonly projects: ReadonlyArray<{
      readonly address: string;
      readonly area: number;
      readonly client: string;
      readonly date: any;
      readonly id: any;
      readonly ladCode: string;
      readonly latitude: number;
      readonly longitude: number;
      readonly mapImageUrl: string;
      readonly name: string;
      readonly oaCode: string;
      readonly postalCode: string;
      readonly propertyIds: ReadonlyArray<string>;
      readonly reference: string;
      readonly settings: any | null | undefined;
      readonly status: ProjectStatus;
      readonly titleNumbers: ReadonlyArray<string>;
      readonly townCity: string;
      readonly userId: string;
    }>;
  };
};
export type ProjectsByUserIdQuery = {
  response: ProjectsByUserIdQuery$data;
  variables: ProjectsByUserIdQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "userId",
        "variableName": "userId"
      }
    ],
    "concreteType": "Projects",
    "kind": "LinkedField",
    "name": "projectsByUserId",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Project",
        "kind": "LinkedField",
        "name": "projects",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "address",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "area",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "client",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "date",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "ladCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "latitude",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "longitude",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "mapImageUrl",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "oaCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "postalCode",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "propertyIds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "reference",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "settings",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "titleNumbers",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "townCity",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userId",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ProjectsByUserIdQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ProjectsByUserIdQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "f8e3bd40e2012b1db0a8377df09fd158",
    "id": null,
    "metadata": {},
    "name": "ProjectsByUserIdQuery",
    "operationKind": "query",
    "text": "query ProjectsByUserIdQuery(\n  $userId: ID!\n) {\n  projectsByUserId(userId: $userId) {\n    projects {\n      address\n      area\n      client\n      date\n      id\n      ladCode\n      latitude\n      longitude\n      mapImageUrl\n      name\n      oaCode\n      postalCode\n      propertyIds\n      reference\n      settings\n      status\n      titleNumbers\n      townCity\n      userId\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a1e9872f0c72d4527945e83fb07322f6";

export default node;
