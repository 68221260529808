import React from 'react'

import { themeColors } from '../Theme'
import { BaseButton } from './Button'

interface ToggleButtonProps {
	active?: boolean
	className?: string
	onClick: () => void
	disabled?: boolean
	children?: React.ReactNode
}

interface ToggleButtonGroupProps {
	options: { label: string; value: string }[]
	activeValue: string
	onChange: (value: string) => void
}

const SecondaryToggleButton: React.FC<ToggleButtonProps> = ({
	onClick,
	disabled,
	children,
	active,
	className = '',
}) => {
	const styles: React.CSSProperties = {
		color: active ? themeColors.darkText : themeColors.grey,
		padding: '0.375rem 0rem',
	}

	return (
		<BaseButton onClick={onClick} disabled={disabled} className={`align-middle fw-bold ${className}`} style={styles}>
			{children}
		</BaseButton>
	)
}

const ToggleButtonGroup: React.FC<ToggleButtonGroupProps> = ({ options, activeValue, onChange }) => {
	return (
		<div className="d-flex gap-3">
			{options.map((option) => (
				<SecondaryToggleButton
					key={option.value}
					onClick={() => onChange(option.value)}
					active={activeValue === option.value}
					className="me-2"
				>
					{option.label}
				</SecondaryToggleButton>
			))}
		</div>
	)
}

export { ToggleButtonGroup }
