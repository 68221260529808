import { createContext, useContext } from 'react'

import { RadiusValues } from '../graphql/queries/__generated__/CareStatsByCaQuery.graphql'
import { GraphQlAaAwardTypes } from '../models/aaAwardTypes'
import { GraphQlCareFunding } from '../models/careFunding'
import { GraphQlCareStats } from '../models/careStats'
import { GraphqlDementiaStats } from '../models/dementiaStats'
import { GraphQlDemographics } from '../models/demographics'
import { GraphQlGeography } from '../models/geography'
import { GraphQlPopulation } from '../models/population'
import { Project } from '../models/project'

export interface Population {
	workingAge: GraphQlPopulation
	over75: GraphQlPopulation
	over75In3: GraphQlPopulation
	over75In6: GraphQlPopulation
	total: GraphQlPopulation
}

export interface CareStats {
	elderly: GraphQlCareStats
	dementia: GraphQlCareStats
	fees: GraphQlDemographics
	funding: GraphQlCareFunding
	aaAwardTypes: GraphQlAaAwardTypes
	dementiaStats: GraphqlDementiaStats
}

export interface Demographics {
	industryDistribution: GraphQlDemographics
	hourlyWage: GraphQlDemographics
	gdhi: GraphQlDemographics
	unemployment: GraphQlDemographics
	housePrices: GraphQlDemographics
	migration: GraphQlDemographics
}

// Define the shape of our context
export interface DataContextType {
	project: Project
	geographies: GraphQlGeography
	mvu: string
	radius: RadiusValues
	population: Population
	careStats: CareStats
	demographics: Demographics
}
// Create a context with a default value
const MvuDataContext = createContext<DataContextType | undefined>(undefined)

export const useMvuData = () => {
	const context = useContext(MvuDataContext)
	if (!context) {
		throw new Error('useData must be used within a DataProvider')
	}
	return context
}

export { MvuDataContext }
