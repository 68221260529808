import graphql from 'babel-plugin-relay/macro'

export const DementiaStatsByCa = graphql`
	query DementiaStatsByCaQuery($oaCode: String!, $radius: RadiusValues!) {
		dementiaStatsByCa(oaCode: $oaCode, radius: $radius) {
			labels
			estimated {
				code
				values
			}
			rates {
				code
				values
			}
			registered {
				code
				values
			}
		}
	}
`
