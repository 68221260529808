import React from 'react'

interface FixedProps {
	children: React.ReactNode
	top?: number
	left?: number
	right?: number
	bottom?: number
	zIndex?: number
}

const Fixed: React.FC<FixedProps> = ({ children, top, left, right, bottom, zIndex = 100 }) => {
	const defaultStyle: React.CSSProperties = {
		position: 'fixed',
		top,
		left,
		right,
		bottom,
		zIndex,
	}

	return <div style={defaultStyle}>{children}</div>
}

export { Fixed }
