import React from 'react'

import { knightFrank, valuationProxies } from '../../../../../Constants'
import { GraphQlCareFunding } from '../../../../../models/careFunding'
import { GraphQlCareStats } from '../../../../../models/careStats'
import { GraphQlDemographics } from '../../../../../models/demographics'
import { Col, Row } from '../../../../../shared/layout/Grid'
import { VerticalSpace } from '../../../../../shared/layout/Space'
import { TrafficLight } from '../../../../../shared/TrafficLight'
import { themeColors } from '../../../../../Theme'
import { formatCurrency, formatNumber, formatPercentage } from '../../../../../utils/formatNumber'
import { getGeoValuesFromDataset } from '../../../../../utils/getGeoValuesFromDataset'
import { Label, Panel, SectionPanel } from '../../../shared/SubComponents'
import { Table, TableData } from '../../../shared/Table'

interface Props {
	projectBeds: number
	elderlyCareStats: GraphQlCareStats
	fees: GraphQlDemographics
	funding: GraphQlCareFunding
	hourlyWages: GraphQlDemographics
}

const StabilisedForecast: React.FC<Props> = ({ projectBeds, hourlyWages, elderlyCareStats, fees, funding }) => {
	// Fee Analysis
	const caRating = elderlyCareStats.stats[0].rating * 100

	const caMarginFraction =
		knightFrank.cqcMargins.baseCqcMargin + (knightFrank.cqcMargins.rangeCqcMargin * caRating) / 100
	const projectMarginFraction =
		knightFrank.cqcMargins.baseCqcMargin + knightFrank.cqcMargins.rangeCqcMargin * knightFrank.cqcMargins.projectRating
	const feeMultiplier = (1 - caMarginFraction) / (1 - projectMarginFraction)

	const caStateFundingShare = funding.StateFunded[0].values[0]

	const caLaFeeResidential = fees.datasets[0].values[4]

	const caPrivateFeeResidential = caLaFeeResidential * knightFrank.privateBedFees.residentialStateToPrivate

	const caLaFeeNursing = fees.datasets[0].values[5]

	const caPrivateFeeNursing = caLaFeeNursing * knightFrank.privateBedFees.nursingStateToPrivate

	const caExpectedAvgResidentialFee =
		(caStateFundingShare * caLaFeeResidential) / 100 + (1 - caStateFundingShare / 100) * caPrivateFeeResidential
	const projectExpectedResidentialFee = caExpectedAvgResidentialFee * feeMultiplier

	const caExpectedAvgNursingFee =
		(caStateFundingShare * caLaFeeNursing) / 100 + (1 - caStateFundingShare / 100) * caPrivateFeeNursing
	const projectExpectedNursingFee = caExpectedAvgNursingFee * feeMultiplier

	const { ca, country } = getGeoValuesFromDataset(hourlyWages.datasets)

	// Profitability
	const caWageIndex = ca / country

	const countryStaffingCost_PC = knightFrank.annualStaffingCostPerBed.residential * knightFrank.occupancy.residential
	const caStaffingCost_PC = caWageIndex * countryStaffingCost_PC

	const countryStaffingCost_N = knightFrank.annualStaffingCostPerBed.nursing * knightFrank.occupancy.nursing
	const caStaffingCost_N = caWageIndex * countryStaffingCost_N

	// Business Forecasts
	const occupancy = valuationProxies.occupancy

	// Nursing Home

	// Mixed: Residential and Nursing
	const projectTurnoverMixedHome =
		((1 - valuationProxies.nursingShareMixed) * projectExpectedResidentialFee +
			valuationProxies.nursingShareMixed * projectExpectedNursingFee) *
		projectBeds *
		52 *
		occupancy
	const projectStaffingCostMixedHome =
		((1 - valuationProxies.nursingShareMixed) * caStaffingCost_PC +
			valuationProxies.nursingShareMixed * caStaffingCost_N) *
		projectBeds
	const projectOverheadCostMixedHome = projectStaffingCostMixedHome * knightFrank.overheadCost.overheadStaffingRatio
	const projectOperatingCostMixedHome = projectStaffingCostMixedHome + projectOverheadCostMixedHome
	const projectEBITDARMMixedHome = projectTurnoverMixedHome - projectOperatingCostMixedHome
	const projectEBITDARMMarginMixedHome = (projectEBITDARMMixedHome / projectTurnoverMixedHome) * 100
	const projectRentMixedHome = projectEBITDARMMixedHome / valuationProxies.rentCover

	const projectEBITDA = projectEBITDARMMixedHome - projectRentMixedHome
	const projectEBITDAMargin = (projectEBITDA / projectTurnoverMixedHome) * 100

	const cap4Point5 = projectRentMixedHome / 0.045
	const cap5Point5 = projectRentMixedHome / 0.055
	const cap6Point5 = projectRentMixedHome / 0.065

	const pAndLHeaders = [
		{ value: 'P&L', point: 8 },
		{ value: "in £'000", color: themeColors.grey, bold: false },
	]
	const pAndLData: TableData = [
		[
			['Turnover', formatCurrency(projectTurnoverMixedHome / 1000, 0)],
			['Staffing Costs', formatCurrency(projectStaffingCostMixedHome / 1000, 0)],
			['Overhead Costs', formatCurrency(projectOverheadCostMixedHome / 1000, 0)],
			[
				{ value: 'EBITDARM', color: themeColors.primary },
				{ value: formatCurrency(projectEBITDARMMixedHome / 1000, 0), color: themeColors.primary, bold: true },
			],
			['%', formatPercentage(projectEBITDARMMarginMixedHome, 0, 0)],
		],
		[
			[
				{ value: 'Rent', color: themeColors.primary, bold: true },
				{ value: formatCurrency(projectRentMixedHome / 1000, 0), color: themeColors.primary, bold: true },
			],
			[{ value: 'Rent Cover' }, { value: formatNumber(valuationProxies.rentCover, 1), bold: true }],
			['EBITDA', formatCurrency(projectEBITDA / 1000, 0)],
			['%', formatPercentage(projectEBITDAMargin, 0, 0)],
		],
	]

	const capHeaders = [
		{ value: 'Valuation', point: 8 },
		{ value: "in £'000,000", color: themeColors.grey, bold: false },
	]
	const cap: TableData = [
		[
			[
				{ value: 'Cap Rate', bold: true, color: themeColors.grey },
				{ value: 'Rent-based valuation', bold: true, color: themeColors.grey },
			],
		],
		[
			['4.5%', formatCurrency(cap4Point5 / 1000000, 1)],
			[
				{ value: '5.5%', bold: true, color: themeColors.primary },
				{ value: formatCurrency(cap5Point5 / 1000000, 1), bold: true, color: themeColors.primary },
			],
			['6.5%', formatCurrency(cap6Point5 / 1000000, 1)],
		],
	]

	return (
		<>
			<Panel height={281} padding={20}>
				<Label point={9}>
					Stabilised Forecast&nbsp;|&nbsp;
					<span style={{ color: themeColors.primary }}>{projectBeds} Beds</span>
				</Label>
				<VerticalSpace size="sm" />
				<Row gutterSize={4}>
					<Col count={6}>
						<div>
							<Table headers={pAndLHeaders} data={pAndLData} footers={['']} />
						</div>
					</Col>
					<Col count={6}>
						<div>
							<Table headers={capHeaders} data={cap} footers={['']} />
							<VerticalSpace size="lg" />
							<Label bold={false} point={5}>
								This is a stabilised forecast for residential and nursing beds at 90% occupancy, with a rent-based cap
								rate valuation.
							</Label>
						</div>
						<VerticalSpace size="xs" />
					</Col>
				</Row>
			</Panel>
			<VerticalSpace size="xs" />
			<SectionPanel title="Planning Green Light:" point={7}>
				<div className="ps-3">
					<TrafficLight size={20} />
				</div>
			</SectionPanel>
		</>
	)
}

export { StabilisedForecast }
