/**
 * @generated SignedSource<<eb25c7188fdbcbb9dd2e08515ab1a937>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CompetitionTableFragment$data = ReadonlyArray<{
  readonly currentRatings: any | null | undefined;
  readonly id: any;
  readonly location: string | null | undefined;
  readonly name: string;
  readonly numberOfBeds: number | null | undefined;
  readonly onspdLatitude: number | null | undefined;
  readonly onspdLongitude: number | null | undefined;
  readonly operatorId: string | null | undefined;
  readonly operatorName: string | null | undefined;
  readonly registrationDate: any | null | undefined;
  readonly serviceTypes: string | null | undefined;
  readonly specialisms: any | null | undefined;
  readonly " $fragmentType": "CompetitionTableFragment";
}>;
export type CompetitionTableFragment$key = ReadonlyArray<{
  readonly " $data"?: CompetitionTableFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CompetitionTableFragment">;
}>;

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "CompetitionTableFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "location",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfBeds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "operatorName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "operatorId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceTypes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "specialisms",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "registrationDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentRatings",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "onspdLatitude",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "onspdLongitude",
      "storageKey": null
    }
  ],
  "type": "Site",
  "abstractKey": null
};

(node as any).hash = "e7b5bec0a8be7eff8e97e287c178ebda";

export default node;
