import React from 'react'
import { useParams } from 'react-router-dom'

import { Page } from '../../../layout/page/Page'
import { Container } from '../../../shared/layout/Grid'
import { Paragraph } from '../../../shared/Text'
import { Panel } from '../shared/SubComponents'
import { Table } from './Table'

type RouteParams = Record<string, string | undefined>

const Data: React.FC = () => {
	const { reference } = useParams<RouteParams>() // Get lad from the route

	return (
		<Page title={`Table - ${reference}`}>
			<Container size="lg">
				<Panel padding={20}>
					{reference && <Table reference={reference} />}
					{!reference && <Paragraph>No reference found</Paragraph>}
				</Panel>
			</Container>
		</Page>
	)
}

export { Data }
