import graphql from 'babel-plugin-relay/macro'

export const CompetitionTableFragment = graphql`
	fragment CompetitionTableFragment on Site @relay(plural: true) {
		id
		location
		name
		numberOfBeds
		operatorName
		operatorId
		serviceTypes
		specialisms
		registrationDate
		currentRatings
		onspdLatitude
		onspdLongitude
	}
`
