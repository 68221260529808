import {
	ProjectsByUserIdQuery$data,
	ProjectStatus,
} from '../graphql/queries/__generated__/ProjectsByUserIdQuery.graphql'

export type GraphQlProject = ProjectsByUserIdQuery$data['projectsByUserId']['projects'][0]

export interface ProjectSettings {
	readonly numberOfBeds?: number
	readonly numberOfExtraCareUnits?: number
	readonly mapZoom?: number
}

export interface Project {
	readonly fullAddress: string
	readonly address: string
	readonly area: number
	readonly client: string
	readonly date: any
	readonly id: any
	readonly ladCode: string
	readonly latitude: number
	readonly longitude: number
	readonly mapImageUrl: string
	readonly name: string
	readonly oaCode: string
	readonly postalCode: string
	readonly propertyIds: ReadonlyArray<string>
	readonly reference: string
	readonly settings: ProjectSettings
	readonly status: ProjectStatus
	readonly statusInt: number
	readonly titleNumbers: ReadonlyArray<string>
	readonly townCity: string
	readonly userId: string
}

const defaultSettings = {
	mapZoom: 18,
}

function createProjectModel(gp: GraphQlProject): Project {
	const settings = {
		...defaultSettings,
		...JSON.parse(gp.settings || '{}'),
	}

	const fullAddress = `${gp.address}, ${gp.townCity} ${gp.postalCode}`
	const statusInt = ['approved', 'pipeline', 'planning'].indexOf(gp.status)

	const project: Project = {
		fullAddress,
		address: gp.address,
		area: gp.area,
		client: gp.client,
		date: gp.date,
		id: gp.id,
		ladCode: gp.ladCode,
		latitude: gp.latitude,
		longitude: gp.longitude,
		mapImageUrl: gp.mapImageUrl,
		name: gp.name,
		oaCode: gp.oaCode,
		postalCode: gp.postalCode,
		propertyIds: gp.propertyIds,
		reference: gp.reference,
		settings: {
			numberOfBeds: settings.numberOfBeds,
			numberOfExtraCareUnits: settings.numberOfExtraCareUnits,
			mapZoom: settings.mapZoom,
		},
		status: gp.status,
		statusInt,
		titleNumbers: gp.titleNumbers,
		townCity: gp.townCity,
		userId: gp.userId,
	}
	return project
}

export { createProjectModel }
