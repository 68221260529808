/**
 * @generated SignedSource<<83422ef5b301df1cf4afd877f4fe4d08>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CareFundingType = "care_home" | "community" | "%future added value";
export type RadiusValues = "eight" | "five" | "four" | "nine" | "one" | "seven" | "six" | "ten" | "three" | "two" | "%future added value";
export type CareFundingByCaQuery$variables = {
  careType: CareFundingType;
  oaCode: string;
  radius: RadiusValues;
};
export type CareFundingByCaQuery$data = {
  readonly careFundingByCa: {
    readonly SelfFunded: ReadonlyArray<{
      readonly code: string;
      readonly values: ReadonlyArray<number>;
    }>;
    readonly StateFunded: ReadonlyArray<{
      readonly code: string;
      readonly values: ReadonlyArray<number>;
    }>;
    readonly labels: ReadonlyArray<string>;
  };
};
export type CareFundingByCaQuery = {
  response: CareFundingByCaQuery$data;
  variables: CareFundingByCaQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "careType"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "oaCode"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "radius"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "code",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "values",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "careType",
        "variableName": "careType"
      },
      {
        "kind": "Variable",
        "name": "oaCode",
        "variableName": "oaCode"
      },
      {
        "kind": "Variable",
        "name": "radius",
        "variableName": "radius"
      }
    ],
    "concreteType": "CareFundings",
    "kind": "LinkedField",
    "name": "careFundingByCa",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "labels",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CareFundingData",
        "kind": "LinkedField",
        "name": "StateFunded",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CareFundingData",
        "kind": "LinkedField",
        "name": "SelfFunded",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CareFundingByCaQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CareFundingByCaQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "9b49d17a9cf819dada91b1db5db4b867",
    "id": null,
    "metadata": {},
    "name": "CareFundingByCaQuery",
    "operationKind": "query",
    "text": "query CareFundingByCaQuery(\n  $careType: CareFundingType!\n  $oaCode: String!\n  $radius: RadiusValues!\n) {\n  careFundingByCa(careType: $careType, oaCode: $oaCode, radius: $radius) {\n    labels\n    StateFunded {\n      code\n      values\n    }\n    SelfFunded {\n      code\n      values\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2df7288a35799bad13df135db622aabe";

export default node;
