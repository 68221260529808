import React from 'react'

import { BarChart } from '../../../shared/charts/BarChart'
import { Model } from '../../../shared/charts/types'
import { CenterAligned } from '../../../shared/layout/Alignment'
import { Col, Row } from '../../../shared/layout/Grid'
import { VerticalSpace } from '../../../shared/layout/Space'
import { themeColors } from '../../../Theme'
import { Label } from './SubComponents'

interface Props {
	model: Model
	aspectRatio?: number
	barThickness?: number
	colorFunc?: (i: number) => string
}

const SimpleBarChart: React.FC<Props> = ({ model, aspectRatio = 0.9, barThickness, colorFunc }) => {
	const colorFn = colorFunc || (() => themeColors.primary)
	return (
		<div>
			<BarChart
				model={model}
				showTicks={false}
				colorFn={colorFn}
				barThickness={barThickness}
				aspectRatio={aspectRatio}
				fontSize={7}
				bottomBorder={true}
			/>
			<VerticalSpace size="xs" />
			<div className="p-1">
				<Row gutterSize={2}>
					{model.labels.map((label, index) => (
						<Col key={index}>
							<CenterAligned>
								<Label key={index} color={themeColors.grey}>
									{label}
								</Label>
							</CenterAligned>
						</Col>
					))}
				</Row>
			</div>
		</div>
	)
}

export { SimpleBarChart }
