/**
 * @generated SignedSource<<2b60fca71dd3186149f8b447f0ca01b3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ElderlyCareStatsFragment$data = {
  readonly stats: ReadonlyArray<{
    readonly code: string;
    readonly numberOfBeds: number;
    readonly populationOver65: number;
  }>;
  readonly " $fragmentType": "ElderlyCareStatsFragment";
};
export type ElderlyCareStatsFragment$key = {
  readonly " $data"?: ElderlyCareStatsFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ElderlyCareStatsFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ElderlyCareStatsFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "CareStatData",
      "kind": "LinkedField",
      "name": "stats",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "code",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "populationOver65",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "numberOfBeds",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "CareStats",
  "abstractKey": null
};

(node as any).hash = "21fda055b9bb28e5aed967e3ad98c9ed";

export default node;
