/**
 * @generated SignedSource<<745c54478dd1744c9bc04db73e82db30>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type OpenAiVersions = "v3" | "v4" | "%future added value";
export type PromptAgentQuery$variables = {
  content: string;
  version: OpenAiVersions;
};
export type PromptAgentQuery$data = {
  readonly promptAgent: {
    readonly response: string;
    readonly version: string;
  };
};
export type PromptAgentQuery = {
  response: PromptAgentQuery$data;
  variables: PromptAgentQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "content"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "version"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "content",
        "variableName": "content"
      },
      {
        "kind": "Variable",
        "name": "version",
        "variableName": "version"
      }
    ],
    "concreteType": "OpenAiChatResponse",
    "kind": "LinkedField",
    "name": "promptAgent",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "response",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "version",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PromptAgentQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PromptAgentQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "c469398f17b6d13783ca004d64924acf",
    "id": null,
    "metadata": {},
    "name": "PromptAgentQuery",
    "operationKind": "query",
    "text": "query PromptAgentQuery(\n  $content: String!\n  $version: OpenAiVersions!\n) {\n  promptAgent(content: $content, version: $version) {\n    response\n    version\n  }\n}\n"
  }
};
})();

(node as any).hash = "57baa3621d43ecc1733fb338823f0463";

export default node;
