import React from 'react'

import { useMvuData } from '../../../../../providers/MvuDataContext'
import { Col, Container, Row } from '../../../../../shared/layout/Grid'
import { VerticalSpace } from '../../../../../shared/layout/Space'
import { Loading } from '../../../../../shared/Loading'
import { formatAcres } from '../../../../../utils/formatNumber'
import { BackgroundImagePanel, SectionPanel, TextPanel } from '../../../shared/SubComponents'
import { Template } from '../../../shared/Template'
import { AreaProfile } from './AreaProfile'
import { ElderlyScores } from './ElderlyScores'
import { InfoPanel } from './InfoPanel'
import { StabilisedForecast } from './StabilisedForecast'
import { TrendAnalysis } from './TrendAnalysis'

const ExecutiveSummary: React.FC = () => {
	const { project, geographies, radius, population: pop, careStats: cs, demographics: dem, mvu } = useMvuData()

	return (
		<Template
			pageTitle="1. Executive Summary"
			project={project}
			reportType="Most Viable Use Case Analysis"
			localAuthority={geographies[0].name}
			region={geographies[1].name}
		>
			<Container size="fluid" removePadding={true}>
				<Row gutterSize={2}>
					<Col count={4}>
						<Row gutterSize={2}>
							<Col count={4}>
								<TextPanel title="Site Size" subtitle={formatAcres(project.area)} />
							</Col>
							<Col count={8}>
								<TextPanel title="Address" subtitle={project.fullAddress} />
							</Col>
						</Row>
						<VerticalSpace size="xs" />
						<Row gutterSize={2}>
							<Col count={12}>
								<AreaProfile
									regionName={geographies[1].name}
									totalPop={pop.total}
									elderlyPop={pop.over75}
									unemployment={dem.unemployment}
									gdhi={dem.gdhi}
									housePrices={dem.housePrices}
									hourlyWages={dem.hourlyWage}
									radius={radius}
								/>
								<VerticalSpace size="xs" />
								<InfoPanel
									height={371}
									project={project}
									geographies={geographies}
									radius={radius}
									mvu={mvu}
									popIn3={pop.over75In3}
									popIn6={pop.over75In6}
									elderlyCareStats={cs.elderly}
									unemployment={dem.unemployment}
									gdhi={dem.gdhi}
									housePrices={dem.housePrices}
									hourlyWages={dem.hourlyWage}
									fees={cs.fees}
									funding={cs.funding}
									migration={dem.migration}
									allowanceAwards={cs.aaAwardTypes}
									dementiaStats={cs.dementiaStats}
								/>
							</Col>
						</Row>
					</Col>
					<Col count={4}>
						<Row gutterSize={2}>
							<Col count={12}>
								<SectionPanel title="PM - Market Opportunity Scores ™" point={8} />
								<VerticalSpace size="xs" />
								<ElderlyScores
									regionName={geographies[1].name}
									elderlyPop={pop.over75}
									radius={radius}
									elderlyCareStats={cs.elderly}
									dementiaCareStats={cs.dementia}
									dementiaStats={cs.dementiaStats}
								/>
								<VerticalSpace size="xs" />
								<SectionPanel title="Most Viable Use:" subtitle={mvu} point={8} primary={true} />
								<VerticalSpace size="xs" />
								<BackgroundImagePanel src={`${process.env.PUBLIC_URL}/img/care-home.png`} height={227} />
							</Col>
						</Row>
					</Col>
					<Col count={4}>
						<SectionPanel title="Trend Analysis" point={8} />
						<VerticalSpace size="xs" />
						<TrendAnalysis elderlyPop={pop.over75} elderlyCareStats={cs.elderly} aaAwardTypes={cs.aaAwardTypes} />
						<VerticalSpace size="xs" />
						<StabilisedForecast
							projectBeds={project.settings.numberOfBeds || 0}
							elderlyCareStats={cs.elderly}
							hourlyWages={dem.hourlyWage}
							funding={cs.funding}
							fees={cs.fees}
						/>
					</Col>
				</Row>
			</Container>
		</Template>
	)
}
const Suspended: React.FC = () => {
	return (
		<React.Suspense fallback={<Loading height="80vh" />}>
			<ExecutiveSummary />
		</React.Suspense>
	)
}

export { Suspended as ExecutiveSummary }
