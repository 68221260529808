/**
 * @generated SignedSource<<c7fe5e64a2fca091140f9333a56c688f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type ProjectStatus = "approved" | "pipeline" | "planning" | "%future added value";
export type UpdateProjectInput = {
  client?: string | null | undefined;
  date?: any | null | undefined;
  latitude?: number | null | undefined;
  longitude?: number | null | undefined;
  name?: string | null | undefined;
  propertyIds?: ReadonlyArray<string> | null | undefined;
  reference?: string | null | undefined;
  settings?: any | null | undefined;
  status?: ProjectStatus | null | undefined;
  titleNumbers?: ReadonlyArray<string> | null | undefined;
};
export type UpdateProjectMutation$variables = {
  id: any;
  input: UpdateProjectInput;
};
export type UpdateProjectMutation$data = {
  readonly updateProject: {
    readonly address: string;
    readonly area: number;
    readonly client: string;
    readonly date: any;
    readonly id: any;
    readonly ladCode: string;
    readonly latitude: number;
    readonly longitude: number;
    readonly mapImageUrl: string;
    readonly name: string;
    readonly oaCode: string;
    readonly postalCode: string;
    readonly propertyIds: ReadonlyArray<string>;
    readonly reference: string;
    readonly settings: any | null | undefined;
    readonly status: ProjectStatus;
    readonly titleNumbers: ReadonlyArray<string>;
    readonly townCity: string;
    readonly userId: string;
  };
};
export type UpdateProjectMutation = {
  response: UpdateProjectMutation$data;
  variables: UpdateProjectMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "id"
      },
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "Project",
    "kind": "LinkedField",
    "name": "updateProject",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "address",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "area",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "client",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "date",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "ladCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "latitude",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "longitude",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mapImageUrl",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "oaCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "postalCode",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "propertyIds",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "reference",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "settings",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "status",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "titleNumbers",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "townCity",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "userId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UpdateProjectMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UpdateProjectMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "16d2338b21ad8c67b7533ece1816b7aa",
    "id": null,
    "metadata": {},
    "name": "UpdateProjectMutation",
    "operationKind": "mutation",
    "text": "mutation UpdateProjectMutation(\n  $id: UUID!\n  $input: UpdateProjectInput!\n) {\n  updateProject(id: $id, input: $input) {\n    address\n    area\n    client\n    date\n    id\n    ladCode\n    latitude\n    longitude\n    mapImageUrl\n    name\n    oaCode\n    postalCode\n    propertyIds\n    reference\n    settings\n    status\n    titleNumbers\n    townCity\n    userId\n  }\n}\n"
  }
};
})();

(node as any).hash = "c112bb4439d8146b631d46e7b9f5f924";

export default node;
