import graphql from 'babel-plugin-relay/macro'

export const CareStatsByLadCode = graphql`
	query CareStatsByLadCodeQuery($specialism: Specialism!, $ladCode: String!) {
		careStatsByLadCode(specialism: $specialism, ladCode: $ladCode) {
			stats {
				code
				type
				careSupplyRate
				numberOfBeds
				nursingBeds
				residentialBeds
				higherRateAllowance
				careHomeWorkers
				domiciliaryCareWorkers
				occupancyRate
				rating
			}
		}
	}
`
