import React, { useEffect, useState } from 'react'

import { themeColors } from '../../../Theme'

interface PdfProps {
	children: React.ReactNode
	landscape?: boolean
	backgroundColor?: string
}

const Pdf: React.FC<PdfProps> = ({ children, landscape = true, backgroundColor = themeColors.canvas }) => {
	const [scale, setScale] = useState(1)

	const widthMm = landscape ? 297 : 210
	const heightMm = landscape ? 210 : 297

	// Convert A4 mm to px (1mm ≈ 3.78px)
	const widthPx = widthMm * 3.78
	const heightPx = heightMm * 3.78

	useEffect(() => {
		const resizeListener = () => {
			const navbarHeight = 80 // Adjust for your navbar height
			const availableHeight = window.innerHeight - navbarHeight - 40 // Space for navbar and margins

			// Calculate scale factors for width and height
			const scaleX = window.innerWidth / widthPx
			const scaleY = availableHeight / heightPx

			// Choose the smaller scale factor to fit inside viewport
			const newScale = Math.min(scaleX, scaleY)
			setScale(newScale)
		}

		window.addEventListener('resize', resizeListener)
		resizeListener() // Initial calculation

		return () => {
			window.removeEventListener('resize', resizeListener)
		}
	}, [widthPx, heightPx])

	return (
		<div
			style={{
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				width: `${widthPx * scale}px`, // Fixed A4 width
				height: `${heightPx * scale}px`, // Fixed A4 height
				marginBottom: '20px', // Space between PDFs
			}}
		>
			<div
				className="pdf-page"
				style={{
					width: `${widthPx}px`, // Fixed A4 width
					height: `${heightPx}px`, // Fixed A4 height
					transform: `scale(${scale})`, // Scale the entire PDF while keeping content fixed
					transformOrigin: 'center', // Ensures proper scaling from the top
					backgroundColor,
					boxShadow: '0px 0px 25px rgba(80, 80, 80, 0.1)',
					border: '4px solid white',
					outline: '1px solid #ddd',
					overflow: 'hidden',
				}}
			>
				{children}
			</div>
		</div>
	)
}

export { Pdf }
