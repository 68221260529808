import React, { useState } from 'react'
import * as i from 'react-feather'
import { useMutation } from 'react-relay'

import { RemoveOperatorsFromGroupMutation } from '../graphql/queries/RemoveOperatorsFromGroup'
import { DangerButton, SecondaryButton, SecondaryOutlineButton } from '../shared/Button'
import { Modal } from '../shared/modal/Modal'

interface RemoveOperatorFromGroupButtonProps {
	operatorId: string
	groupId: string
}

const RemoveOperatorFromGroupButton: React.FC<RemoveOperatorFromGroupButtonProps> = ({ operatorId, groupId }) => {
	const [showModal, setShowModal] = useState(false)
	const [commitMutation, isMutationInFlight] = useMutation(RemoveOperatorsFromGroupMutation)

	const handleShow = () => setShowModal(true)
	const handleClose = () => setShowModal(false)

	const handleConfirm = () => {
		commitMutation({
			variables: {
				id: groupId,
				operatorIds: [operatorId],
			},
			onCompleted: () => {
				handleClose()
				window.location.reload()
			},
			onError: (error) => {
				console.error('Mutation error:', error)
			},
		})
	}

	return (
		<>
			<SecondaryOutlineButton onClick={handleShow}>
				<i.Trash size={14} color="#666" /> Remove from group
			</SecondaryOutlineButton>

			<Modal
				isOpen={showModal}
				onClose={handleClose}
				title="Confirm Removal"
				footer={
					<>
						<SecondaryButton onClick={handleClose} disabled={isMutationInFlight}>
							Cancel
						</SecondaryButton>
						<DangerButton onClick={handleConfirm} disabled={isMutationInFlight}>
							<i.Trash size={14} />
						</DangerButton>
					</>
				}
			>
				<p>Are you sure you want to remove this operator from the group? This action cannot be undone.</p>
			</Modal>
		</>
	)
}

export { RemoveOperatorFromGroupButton }
