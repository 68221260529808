/**
 * @generated SignedSource<<ec5b880c9d01a92954d690d33a0d89ec>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type CareFundingType = "care_home" | "community" | "%future added value";
export type CareFundingByLadCodeQuery$variables = {
  careType: CareFundingType;
  ladCode: string;
};
export type CareFundingByLadCodeQuery$data = {
  readonly careFundingByLadCode: {
    readonly SelfFunded: ReadonlyArray<{
      readonly code: string;
      readonly values: ReadonlyArray<number>;
    }>;
    readonly StateFunded: ReadonlyArray<{
      readonly code: string;
      readonly values: ReadonlyArray<number>;
    }>;
    readonly labels: ReadonlyArray<string>;
  };
};
export type CareFundingByLadCodeQuery = {
  response: CareFundingByLadCodeQuery$data;
  variables: CareFundingByLadCodeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "careType"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "ladCode"
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "code",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "values",
    "storageKey": null
  }
],
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "careType",
        "variableName": "careType"
      },
      {
        "kind": "Variable",
        "name": "ladCode",
        "variableName": "ladCode"
      }
    ],
    "concreteType": "CareFundings",
    "kind": "LinkedField",
    "name": "careFundingByLadCode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "labels",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CareFundingData",
        "kind": "LinkedField",
        "name": "StateFunded",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CareFundingData",
        "kind": "LinkedField",
        "name": "SelfFunded",
        "plural": true,
        "selections": (v1/*: any*/),
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CareFundingByLadCodeQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CareFundingByLadCodeQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "310887ee70ffac81a803dd2eacdc5fcc",
    "id": null,
    "metadata": {},
    "name": "CareFundingByLadCodeQuery",
    "operationKind": "query",
    "text": "query CareFundingByLadCodeQuery(\n  $careType: CareFundingType!\n  $ladCode: String!\n) {\n  careFundingByLadCode(careType: $careType, ladCode: $ladCode) {\n    labels\n    StateFunded {\n      code\n      values\n    }\n    SelfFunded {\n      code\n      values\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "53012d07a8e445c61616daf3ef6940e4";

export default node;
