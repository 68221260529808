/**
 * @generated SignedSource<<ba77e5f021eb397c4cbf0c16803784dc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type GeographyTypes = "country" | "lad" | "region" | "ward" | "%future added value";
export type Specialism = "dementia" | "elderly" | "%future added value";
export type CareStatsByLadCodeQuery$variables = {
  ladCode: string;
  specialism: Specialism;
};
export type CareStatsByLadCodeQuery$data = {
  readonly careStatsByLadCode: {
    readonly stats: ReadonlyArray<{
      readonly careHomeWorkers: number;
      readonly careSupplyRate: number | null | undefined;
      readonly code: string;
      readonly domiciliaryCareWorkers: number;
      readonly higherRateAllowance: number;
      readonly numberOfBeds: number;
      readonly nursingBeds: number;
      readonly occupancyRate: number;
      readonly rating: number;
      readonly residentialBeds: number;
      readonly type: GeographyTypes;
    }>;
  };
};
export type CareStatsByLadCodeQuery = {
  response: CareStatsByLadCodeQuery$data;
  variables: CareStatsByLadCodeQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "ladCode"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "specialism"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "ladCode",
        "variableName": "ladCode"
      },
      {
        "kind": "Variable",
        "name": "specialism",
        "variableName": "specialism"
      }
    ],
    "concreteType": "CareStats",
    "kind": "LinkedField",
    "name": "careStatsByLadCode",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "CareStatData",
        "kind": "LinkedField",
        "name": "stats",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "type",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "careSupplyRate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "numberOfBeds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "nursingBeds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "residentialBeds",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "higherRateAllowance",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "careHomeWorkers",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "domiciliaryCareWorkers",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "occupancyRate",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "rating",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CareStatsByLadCodeQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CareStatsByLadCodeQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "9ea199556d026224c63b6e70598a2081",
    "id": null,
    "metadata": {},
    "name": "CareStatsByLadCodeQuery",
    "operationKind": "query",
    "text": "query CareStatsByLadCodeQuery(\n  $specialism: Specialism!\n  $ladCode: String!\n) {\n  careStatsByLadCode(specialism: $specialism, ladCode: $ladCode) {\n    stats {\n      code\n      type\n      careSupplyRate\n      numberOfBeds\n      nursingBeds\n      residentialBeds\n      higherRateAllowance\n      careHomeWorkers\n      domiciliaryCareWorkers\n      occupancyRate\n      rating\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "51800afadb52e7c2b3586077494cfa64";

export default node;
