import React from 'react'

import { elderlyCareScores, staffResidentRatio } from '../../../../../Constants'
import { GraphQlCareStats } from '../../../../../models/careStats'
import { GraphqlDementiaStats } from '../../../../../models/dementiaStats'
import { GraphQlPopulation } from '../../../../../models/population'
import { getGeoValuesFromDataset } from '../../../../../utils/getGeoValuesFromDataset'
import { ScorePanel } from '../../../shared/ScorePanel'

interface Props {
	title: string
	radiusLabel: string
	scoreType: 'nursing' | 'residential' | 'extraCare' | 'dementia'
	regionName: string
	elderlyPop: GraphQlPopulation
	careStats: GraphQlCareStats
	dementiaStats: GraphqlDementiaStats
}

const CareScore: React.FC<Props> = ({
	radiusLabel,
	title,
	scoreType,
	regionName,
	elderlyPop,
	careStats,
	dementiaStats,
}) => {
	const geos = elderlyPop?.geographies || []
	if (geos.length === 0) {
		return null
	}

	const idx = elderlyPop.years.length - 1

	// Population values based on scoreType
	const caPop75 = geos[0].ageRanges[0].values[idx]
	const regionPop75 = geos[geos.length - 2].ageRanges[0].values[idx]

	const { ca: caDementia, region: regionDementia } = getGeoValuesFromDataset(dementiaStats.estimated)

	// Determine the correct fields to use for beds and rates based on scoreType
	let caNominator, regionNominator, caDenominator, regionDenominator
	let min, max

	switch (scoreType) {
		case 'nursing':
			caNominator = careStats.stats[0].nursingBeds
			regionNominator = careStats.stats[careStats.stats.length - 2].nursingBeds
			min = elderlyCareScores.nursing.min
			max = elderlyCareScores.nursing.max
			caDenominator = caPop75
			regionDenominator = regionPop75
			break
		case 'residential':
			caNominator = careStats.stats[0].residentialBeds
			regionNominator = careStats.stats[careStats.stats.length - 2].residentialBeds
			min = elderlyCareScores.residential.min
			max = elderlyCareScores.residential.max
			caDenominator = caPop75
			regionDenominator = regionPop75
			break
		case 'extraCare':
			caNominator = careStats.stats[0].domiciliaryCareWorkers * staffResidentRatio.homecare
			regionNominator = careStats.stats[careStats.stats.length - 2].domiciliaryCareWorkers * staffResidentRatio.homecare
			min = elderlyCareScores.domiciliary.min
			max = elderlyCareScores.domiciliary.max
			caDenominator = caPop75
			regionDenominator = regionPop75
			break
		case 'dementia':
			caNominator = careStats.stats[0].numberOfBeds
			regionNominator = careStats.stats[careStats.stats.length - 2].numberOfBeds
			min = elderlyCareScores.dementia.min
			max = elderlyCareScores.dementia.max
			caDenominator = caDementia
			regionDenominator = regionDementia
			break
		default:
			return null
	}

	// Calculate the rates
	const caRate = caNominator / caDenominator
	const regionRate = regionNominator / regionDenominator

	const ca = ((caRate - min) / (max - min)) * 100
	const region = ((regionRate - min) / (max - min)) * 100

	const main = 100 - ca
	const secondary = 100 - region

	const regionLabel = regionName.length > 15 ? regionName.slice(0, 15) + `...` : regionName

	const labels = [radiusLabel, regionLabel]

	return <ScorePanel title={title} values={[main, secondary]} labels={labels} />
}

export { CareScore }
