import React from 'react'

import { milesRadiusValueInInts } from '../../../../../Constants'
import { RadiusValues } from '../../../../../graphql/queries/__generated__/CareStatsByCaQuery.graphql'
import { GraphQlCareStats } from '../../../../../models/careStats'
import { Dataset, Model } from '../../../../../shared/charts/types'
import { VerticalSpace } from '../../../../../shared/layout/Space'
import { themeColors } from '../../../../../Theme'
import { formatNumber, formatPercentage } from '../../../../../utils/formatNumber'
import { DonutChart } from '../../../shared/ScorePanel'
import { Label, Panel } from '../../../shared/SubComponents'
import { Table, TableData } from '../../../shared/Table'

interface Props {
	regionName: string
	radius: RadiusValues
	elderlyCareStats: GraphQlCareStats
	dementiaCareStats: GraphQlCareStats
	height: number
}

const CareSupply: React.FC<Props> = ({ elderlyCareStats, dementiaCareStats, regionName, radius, height }) => {
	const { stats: ecStats } = elderlyCareStats
	const { stats: dcStats } = dementiaCareStats

	const ecCa = ecStats[0]
	const ecRegion = ecStats[ecStats.length - 2]

	const dcCa = dcStats[0]
	const dcRegion = dcStats[ecStats.length - 2]

	const radiusInt = milesRadiusValueInInts[radius]

	const staffingHeaders = ['', `${radiusInt} Mile Catchment`, { value: regionName, color: themeColors.grey }]

	const staffingData: TableData = [
		[
			[
				'Total Elderly Care Beds',
				{ value: formatNumber(ecCa.numberOfBeds), bold: true },
				formatNumber(ecRegion.numberOfBeds),
			],
			[
				'Avg. Occupancy Rate',
				{
					value: formatPercentage(ecCa.occupancyRate, 0, 1),
					bold: true,
					color: ecCa.occupancyRate > ecRegion.occupancyRate ? themeColors.primary : undefined,
				},
				formatPercentage(ecRegion.occupancyRate, 0, 1),
			],
			[
				'Avg. CQC Rating',
				{
					value: formatPercentage(ecCa.rating * 100, 0, 1),
					bold: true,
					color: ecCa.rating < ecRegion.rating ? themeColors.primary : undefined,
				},
				formatPercentage(ecRegion.rating * 100, 0, 1),
			],
			[
				{ value: 'Dementia Suitable Beds', whiteSpace: 'nowrap' },
				{ value: formatNumber(dcCa.numberOfBeds), bold: true },
				formatNumber(dcRegion.numberOfBeds),
			],
			[
				'%',
				{
					value: formatPercentage((dcCa.numberOfBeds / ecCa.numberOfBeds) * 100, 0, 1),
					bold: true,
					color:
						dcCa.numberOfBeds / ecCa.numberOfBeds < dcRegion.numberOfBeds / ecRegion.numberOfBeds
							? themeColors.primary
							: undefined,
				},
				{
					value: formatPercentage((dcRegion.numberOfBeds / ecRegion.numberOfBeds) * 100, 0, 1),
				},
			],
		],
	]

	const colors = [
		['#ccc', ...themeColors.severity],
		['#eee', themeColors.primaryAlpha(0.5), themeColors.infoAlpha(0.5)],
	]

	const datasets: Dataset[] = [
		{
			id: 'ca',
			values: [ecCa.nursingBeds, ecCa.residentialBeds, ecCa.domiciliaryCareWorkers],
		},
		{
			id: 'regional',
			values: [ecRegion.nursingBeds, ecRegion.residentialBeds, ecRegion.domiciliaryCareWorkers],
		},
	]

	const typeDistributionData: TableData = [
		[
			[
				{ value: `Residential beds`, color: themeColors.primary },
				formatNumber(ecCa.residentialBeds),
				formatNumber(ecRegion.residentialBeds),
			],
			[
				{ value: `Domiciliary beds`, color: themeColors.info },
				formatNumber(ecCa.domiciliaryCareWorkers),
				formatNumber(ecRegion.domiciliaryCareWorkers),
			],
			[
				{ value: `Nursing beds`, color: themeColors.grey },
				formatNumber(ecCa.nursingBeds),
				formatNumber(ecRegion.nursingBeds),
			],
		],
	]

	const typeDistributionHeaders = ['', `${radiusInt} Mile Catchment`, { value: regionName, color: themeColors.grey }]

	const model: Model = {
		labels: [],
		datasets,
	}

	return (
		<Panel height={height} padding={20}>
			<Label point={9}>Elderly Care Supply</Label>
			<VerticalSpace size="md" />
			<Table headers={staffingHeaders} data={staffingData} footers={['']} />
			<VerticalSpace size="xs" />
			<Label point={7}>Care Type Distribution</Label>
			<DonutChart model={model} colors={colors} size={140} showCenterLabel={false} />
			<VerticalSpace size="sm" />
			<Table headers={typeDistributionHeaders} data={typeDistributionData} footers={['']} />
		</Panel>
	)
}
export { CareSupply }
