import {
	BarElement,
	CategoryScale,
	Chart as ChartJS,
	ChartData,
	ChartDataset,
	ChartOptions,
	Legend,
	LinearScale,
	Title,
	Tooltip,
} from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { Options } from 'chartjs-plugin-datalabels/types/options'
import React from 'react'
import { Bar } from 'react-chartjs-2'

import { themeColors } from '../../Theme'
import { Pills } from '../Pills'
import { tooltipConfig } from './ChartToolTip'
import { Model } from './types'

// Register Chart.js components and annotation plugin for Bar charts
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, annotationPlugin, ChartDataLabels)

interface BarChartProps {
	model: Model
	prefix?: string
	suffix?: string
	aspectRatio?: number
	horizontal?: boolean
	stacked?: boolean
	showLabels?: boolean
	alpha?: number
	showTicks?: boolean
	barThickness?: number
	fontSize?: number
	bottomBorder?: boolean
	colorFn?: (idx: number) => string
}

export const BarChart: React.FC<BarChartProps> = ({
	model: modelData,
	prefix = '',
	suffix = '',
	aspectRatio = 1,
	horizontal = false,
	stacked = false,
	showLabels = true,
	alpha = 0.6,
	showTicks = true,
	barThickness,
	fontSize = 10,
	bottomBorder = false,
	colorFn,
}) => {
	const displayPrefix = prefix || ''
	const displaySuffix = suffix || ''

	// Cast to Model
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const model = modelData as any as Model

	const { colorPalette, colorPaletteAlpha } = themeColors

	// Generate datasets with dynamic backgroundColor for bars
	const datasets: ChartDataset<'bar', (number | [number, number] | null)[]>[] = model.datasets.map((d, i) => ({
		label: d.id,
		data: d.values,
		backgroundColor: colorFn ? colorFn(i) : colorPaletteAlpha[i % colorPalette.length](alpha),
		borderColor: colorFn ? colorFn(i) : colorPalette[i % colorPalette.length],
		borderWidth: colorFn ? 0 : 1,
		barThickness,
		// barPercentage: 1, // Make the bars more narrow
		// categoryPercentage: 0.9, // Make the bars more narrow
		borderRadius: 2,
	}))

	// Data for the chart
	const data: ChartData<'bar'> = {
		labels: model.labels,
		datasets,
	}

	const labelPosition: Options = horizontal
		? {
				color: '#fff',
			}
		: {
				color: '#000',
				anchor: 'end',
				align: 'end',
			}

	const datalabels: Options = {
		display: showLabels,
		font: {
			size: fontSize,
			weight: 'bold',
		},
		...labelPosition,
		formatter: function (value) {
			// Just return the value with prefix and suffix
			const locale = 'en-GB'
			const formattedValue = new Intl.NumberFormat(locale).format(value as number)
			return displayPrefix + formattedValue + displaySuffix
		},
	}

	// Options for the chart
	const options: ChartOptions<'bar'> = {
		indexAxis: horizontal ? 'y' : 'x', // This makes it a horizontal bar chart
		aspectRatio,
		responsive: true,
		interaction: {
			intersect: false,
		},
		layout: {
			padding: {
				top: 30,
				right: 0,
				bottom: 0,
				left: 0,
			},
		},
		scales: {
			x: {
				// Configuration for the x-axis (horizontal)
				beginAtZero: true,
				ticks: {
					display: showTicks,
					// maxTicksLimit: 1, // Limit the number of ticks on the x-axis
					font: {
						size: fontSize, // Increase font size for x-axis labels if needed
					},
					padding: 0,
					maxRotation: 90,
					minRotation: 90,
				},
				grid: {
					display: showTicks,
					drawTicks: showTicks, // Hide tick lines
				},
				stacked,
			},
			y: {
				// Configuration for the y-axis (vertical)
				display: showTicks,
				ticks: {
					// display: showTicks,
					// padding: 20, // Increase padding between the labels and the chart
					font: {
						size: 14, // Adjust font size for y-axis labels
					},
				},
				stacked,
			},
		},

		plugins: {
			legend: { display: false },
			tooltip: tooltipConfig(displayPrefix, displaySuffix),
			datalabels: datalabels,
		},
	}

	const ids: string[] = model.datasets.filter((d) => d.id).map((d) => d.id as string)

	const style = bottomBorder ? { borderBottom: '1px solid black' } : {}

	return (
		<div style={style}>
			{ids.length > 0 && <Pills ids={ids} />}
			<Bar data={data} options={options} />
		</div>
	)
}
