import React from 'react'

import { themeColors } from '../../../Theme'
import { Label } from './SubComponents'

export interface TableCell {
	backgroundColor?: string
	color?: string
	textAlign?: 'left' | 'right' | 'center'
	point?: number
	value: string
	bold?: boolean
	verticalAlign?: 'top' | 'middle' | 'bottom'
	whiteSpace?: 'normal' | 'nowrap'
}

export type TableRow = (string | TableCell)[]
export type TableSection = TableRow[]
export type TableData = TableSection[]

interface Props {
	headers?: TableRow
	data: TableData
	footers?: string[]
}

const Table: React.FC<Props> = ({ headers = [], data, footers = [] }) => {
	const defaultCell = (value: string, index: number): TableCell => ({
		backgroundColor: '#fff',
		color: themeColors.darkText,
		textAlign: index ? 'right' : 'left',
		point: 6,
		value,
		verticalAlign: 'middle',
		bold: !index,
		whiteSpace: 'normal',
	})

	const defaultHeader = (value: string, index: number): TableCell => ({
		backgroundColor: '#fff',
		color: themeColors.darkText,
		textAlign: index ? 'right' : 'left',
		point: 6,
		value,
		verticalAlign: 'middle',
		bold: true,
		whiteSpace: 'normal',
	})

	return (
		<table className="table table-borderless table-sm">
			<thead style={{ borderBottom: '1px solid #000' }}>
				{headers.length > 0 && (
					<tr>
						{headers.map((cell, index) => {
							if (typeof cell !== 'object') {
								cell = defaultHeader(cell, index)
							} else {
								const defaults = defaultHeader(cell.value, index)
								cell = { ...defaults, ...cell }
							}
							return (
								<th
									key={index}
									style={{
										backgroundColor: cell.backgroundColor,
										verticalAlign: cell.verticalAlign,
										whiteSpace: cell.whiteSpace,
									}}
								>
									<Label point={cell.point} bold={cell.bold} align={cell.textAlign} color={cell.color}>
										{cell.value}
									</Label>
								</th>
							)
						})}
					</tr>
				)}
			</thead>
			{data.map((section, sectionIndex) => (
				<tbody key={sectionIndex} style={{ borderTop: '1px solid #000' }}>
					{section.map((row, rowIndex) => (
						<tr key={rowIndex}>
							{row.map((cell, index) => {
								if (typeof cell !== 'object') {
									cell = defaultCell(cell, index)
								} else {
									const defaults = defaultCell(cell.value, index)
									cell = { ...defaults, ...cell }
								}
								return (
									<td
										key={index}
										style={{
											backgroundColor: cell.backgroundColor,
											verticalAlign: cell.verticalAlign,
											whiteSpace: cell.whiteSpace,
										}}
									>
										<Label point={cell.point} bold={cell.bold} align={cell.textAlign} color={cell.color}>
											{cell.value}
										</Label>
									</td>
								)
							})}
						</tr>
					))}
				</tbody>
			))}
			{footers.length > 0 && (
				<tfoot style={{ borderTop: '1px solid #000' }}>
					<tr>
						{footers.map((label, index) => (
							<td key={index}>
								<Label point={6}>{label}</Label>
							</td>
						))}
					</tr>
				</tfoot>
			)}
		</table>
	)
}

export { Table }
