import React from 'react'
import * as i from 'react-feather'

import { themeColors } from '../Theme'
import { isValidUrl } from '../utils/url'
import { VerticallyAligned } from './layout/Alignment'

interface ImageProps {
	src: string
	alt: string
	width?: number | string
	height?: number | string
	className?: string
}

const Image: React.FC<ImageProps> = ({ src, alt, width, height, className }) => {
	const style = {
		backgroundColor: themeColors.canvas,
		width,
		height,
	}

	if (!isValidUrl(src)) {
		return (
			<div style={style}>
				<VerticallyAligned>
					<i.Image size={30} color={themeColors.grey} />
				</VerticallyAligned>
			</div>
		)
	}

	return <img src={src} alt={alt} width={width} height={height} className={className} style={style} />
}

interface StaticMapImageProps {
	url: string
	scale?: number
	zoom?: number
	mapType?: string
	height?: number | string
	width?: number | string
	className?: string
}

const BackgroundImage: React.FC<StaticMapImageProps> = ({ url, width = 600, height = 400, className }) => {
	const style = {
		backgroundColor: themeColors.canvas,
		backgroundImage: `url(${url})`,
		backgroundSize: 'cover',
		backgroundPosition: 'center',
		width,
		height,
	}

	return <div style={style} className={className} />
}

export { BackgroundImage, Image }
