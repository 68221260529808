import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { openAiVersions } from '../../Constants'
import { OpenAiVersions, PromptQuery } from '../../graphql/queries/__generated__/PromptQuery.graphql'
import { Prompt } from '../../graphql/queries/Prompt'
import { Heading, Paragraph } from '../Text'

interface AiTextProps {
	prompt: string
	cacheKey: string
	version?: OpenAiVersions
}

const AIStructuredText: React.FC<AiTextProps> = ({ prompt, cacheKey, version = openAiVersions.v3 }) => {
	const data = useLazyLoadQuery<PromptQuery>(Prompt, { content: prompt, key: cacheKey, version })

	const paragraphArray = data.prompt.response.split('\n')
	const heading = paragraphArray.shift()

	return (
		<>
			<Heading level={3}>{heading}</Heading>
			<div>
				{paragraphArray.map((item, index) => (
					<Paragraph key={index}>{item}</Paragraph>
				))}
			</div>
		</>
	)
}

export { AIStructuredText }
