// eslint-disable-file @typescript-eslint/no-unused-vars

import React from 'react'

import { knightFrank } from '../../../../../Constants'
import { GraphQlCareFunding } from '../../../../../models/careFunding'
import { GraphQlCareStats } from '../../../../../models/careStats'
import { GraphQlDemographics } from '../../../../../models/demographics'
import { VerticalSpace } from '../../../../../shared/layout/Space'
import { themeColors } from '../../../../../Theme'
import { formatCurrency, formatNumber, formatPercentage } from '../../../../../utils/formatNumber'
import { Label, Panel } from '../../../shared/SubComponents'
import { Table, TableData } from '../../../shared/Table'

interface Props {
	projectName: string
	regionName: string
	height: number
	elderlyCareStats: GraphQlCareStats
	careFees: GraphQlDemographics
	careFunding: GraphQlCareFunding
}

const BedFeeAnalysis: React.FC<Props> = ({
	height,
	projectName,
	regionName,
	elderlyCareStats,
	careFees,
	careFunding,
}) => {
	// Fee Analysis
	const caRating = elderlyCareStats.stats[0].rating * 100
	const regionRating = elderlyCareStats.stats[elderlyCareStats.stats.length - 2].rating * 100

	const caMarginFraction =
		knightFrank.cqcMargins.baseCqcMargin + (knightFrank.cqcMargins.rangeCqcMargin * caRating) / 100
	const projectMarginFraction =
		knightFrank.cqcMargins.baseCqcMargin + knightFrank.cqcMargins.rangeCqcMargin * knightFrank.cqcMargins.projectRating
	const feeMultiplier = (1 - caMarginFraction) / (1 - projectMarginFraction)

	const caStateFundingShare = careFunding.StateFunded[0].values[0]
	const regionStateFundingShare = careFunding.StateFunded[careFunding.StateFunded.length - 2].values[0]

	const caLaFeeResidential = careFees.datasets[0].values[4]
	const regionLaFeeResidential = careFees.datasets[careFees.datasets.length - 2].values[4]
	const projectLAFeeResidential = caLaFeeResidential * feeMultiplier

	const caPrivateFeeResidential = caLaFeeResidential * knightFrank.privateBedFees.residentialStateToPrivate
	const regionPrivateFeeResidential = regionLaFeeResidential * knightFrank.privateBedFees.residentialStateToPrivate
	const projectPrivateFeeResidential = caPrivateFeeResidential * feeMultiplier

	const caLaFeeNursing = careFees.datasets[0].values[5]
	const regionLaFeeNursing = careFees.datasets[careFees.datasets.length - 2].values[5]
	const projectLAFeeNursing = caLaFeeNursing * feeMultiplier

	const caPrivateFeeNursing = caLaFeeNursing * knightFrank.privateBedFees.nursingStateToPrivate
	const regionPrivateFeeNursing = regionLaFeeNursing * knightFrank.privateBedFees.nursingStateToPrivate

	const projectPrivateFeeNursing = caPrivateFeeNursing * feeMultiplier

	const caExpectedAvgResidentialFee =
		(caStateFundingShare * caLaFeeResidential) / 100 + (1 - caStateFundingShare / 100) * caPrivateFeeResidential
	const projectExpectedResidentialFee = caExpectedAvgResidentialFee * feeMultiplier
	const regionExpectedAvgResidentialFee =
		(regionStateFundingShare * regionLaFeeResidential) / 100 +
		(1 - regionStateFundingShare / 100) * regionPrivateFeeResidential

	const caExpectedAvgNursingFee =
		(caStateFundingShare * caLaFeeNursing) / 100 + (1 - caStateFundingShare / 100) * caPrivateFeeNursing
	const projectExpectedNursingFee = caExpectedAvgNursingFee * feeMultiplier
	const regionExpectedAvgNursingFee =
		(regionStateFundingShare * regionLaFeeNursing) / 100 + (1 - regionStateFundingShare / 100) * regionPrivateFeeNursing

	const headers = [
		{ value: 'Weekly Per Bed', point: 7, bold: true },
		projectName,
		{ value: '5-Mile Radius', color: themeColors.grey },
		{ value: regionName, color: themeColors.grey },
	]

	const data: TableData = [
		[
			[
				{ value: 'Local Authority Funding Share', color: themeColors.grey, whiteSpace: 'nowrap' },
				{ value: formatPercentage(caStateFundingShare), bold: true },
				{ value: formatPercentage(caStateFundingShare), bold: false },
				{ value: formatPercentage(regionStateFundingShare) },
			],
			[
				{ value: 'CQC Rating', color: themeColors.grey },
				{ value: formatPercentage((5 / 6) * 100, 0, 0), color: themeColors.primary, bold: true },
				{ value: formatPercentage(caRating, 0, 0), color: themeColors.darkText, bold: false },
				{ value: formatPercentage(regionRating, 0, 0), color: themeColors.darkText, bold: false },
			],
			[
				{ value: 'CQC Rating Fee Uplift', color: themeColors.grey },
				{ value: formatNumber(feeMultiplier, 0, 3), color: themeColors.primary, bold: true },
				{ value: '1', color: themeColors.grey, bold: true },
				{ value: '-' },
			],
		],
		[
			[{ value: 'Nursing' }],
			[
				{ value: 'Average Local Authority Fee Paid', bold: false, whiteSpace: 'nowrap' },
				{ value: formatCurrency(projectLAFeeNursing, 0), bold: true },
				{ value: formatCurrency(caLaFeeNursing, 0) },
				{ value: formatCurrency(regionLaFeeNursing, 0) },
			],
			[
				{ value: 'Average Private Fee Paid', bold: false, whiteSpace: 'nowrap' },
				{ value: formatCurrency(projectPrivateFeeNursing, 0), bold: true },
				{ value: formatCurrency(caPrivateFeeNursing, 0) },
				{ value: formatCurrency(regionPrivateFeeNursing, 0) },
			],
			[
				{ value: 'Expected Average Fee Paid', bold: false, whiteSpace: 'nowrap' },
				{
					value: formatCurrency(projectExpectedNursingFee, 0),
					bold: true,
				},
				{ value: formatCurrency(caExpectedAvgNursingFee, 0) },
				{ value: formatCurrency(regionExpectedAvgNursingFee, 0) },
			],
		],
		[
			[{ value: 'Residential' }],
			[
				{ value: 'Average Local Authority Fee Paid', bold: false, whiteSpace: 'nowrap' },
				{ value: formatCurrency(projectLAFeeResidential, 0), bold: true },
				{ value: formatCurrency(caLaFeeResidential, 0) },
				{ value: formatCurrency(regionLaFeeResidential, 0) },
			],
			[
				{ value: 'Average Private Fee Paid', bold: false },
				{ value: formatCurrency(projectPrivateFeeResidential, 0), bold: true },
				{ value: formatCurrency(caPrivateFeeResidential, 0) },
				{ value: formatCurrency(regionPrivateFeeResidential, 0) },
			],
			[
				{ value: 'Expected Average Fee Paid', bold: false },
				{
					value: formatCurrency(projectExpectedResidentialFee, 0),
					bold: true,
				},
				{ value: formatCurrency(caExpectedAvgResidentialFee, 0) },
				{ value: formatCurrency(regionExpectedAvgResidentialFee, 0) },
			],
		],
	]

	return (
		<Panel height={height} padding={20}>
			<Label point={9}>Bed Fee Analysis</Label>
			<VerticalSpace size="xl" />
			<Table headers={headers} data={data} footers={['']} />
		</Panel>
	)
}

export { BedFeeAnalysis }
