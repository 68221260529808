import graphql from 'babel-plugin-relay/macro'

export const CareStatsByTypes = graphql`
	query CareStatsByTypesQuery($specialism: Specialism!, $types: [GeographyTypes!]!) {
		careStatsByTypes(specialism: $specialism, types: $types) {
			...ElderlyCareStatsFragment
			...LaTableElderlyCareStatsFragment
			...ElderlyCareStatsCaFragment
		}
	}
`
