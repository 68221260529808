import graphql from 'babel-plugin-relay/macro'

export const SitesByCa = graphql`
	query SitesByCaQuery($oaCode: String!, $radius: RadiusValues!) {
		sitesByCa(oaCode: $oaCode, radius: $radius) {
			sites {
				...CareHomesSitesByCaFragment
				...SitesTableFragment
				...ElderlySitesChartSiteFragment
				...ElderlyBedShortagesForecastChartSiteFragment
				...ElderlyBedsTimeSeriesChartSiteFragment
				...ElderlyBedShortagesQuickFactFragment
				...CompetitionTableFragment
			}
		}
	}
`
