import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { Specialism } from '../../Constants'
import { CareStatsByLadCodeQuery } from '../../graphql/queries/__generated__/CareStatsByLadCodeQuery.graphql'
import { CareStatsByLadCode } from '../../graphql/queries/CareStatsByLad'
import { formatPercentage } from '../../utils/formatNumber'
import { QuickFact, QuickFactPlaceholder } from '../QuickFact'

interface ElderlyCareSupplyRateFactProps {
	ladCode: string
	compareRegion?: boolean
}

const ElderlyCareSupplyRateByLad: React.FC<ElderlyCareSupplyRateFactProps> = ({ ladCode, compareRegion }) => {
	const ecsData = useLazyLoadQuery<CareStatsByLadCodeQuery>(CareStatsByLadCode, {
		specialism: Specialism.elderly,
		ladCode: ladCode,
	})

	const { stats } = ecsData.careStatsByLadCode

	const supplyRateLad = stats[0].careSupplyRate || 0
	const supplyRateRegion = stats[1].careSupplyRate || 0
	const supplyRateNational = stats[2].careSupplyRate || 0

	const comparison = compareRegion ? supplyRateRegion : supplyRateNational

	return (
		<QuickFact
			heading="Care Bed Supply Rate"
			value={formatPercentage(supplyRateLad * 100, 0, 0)}
			comparison={formatPercentage(comparison * 100, 0, 1)}
			isComparable={true}
		/>
	)
}

const Suspended: React.FC<ElderlyCareSupplyRateFactProps> = (props) => {
	return (
		<React.Suspense fallback={<QuickFactPlaceholder />}>
			<ElderlyCareSupplyRateByLad {...props} />
		</React.Suspense>
	)
}

export { Suspended as ElderlyCareSupplyRateByLad }
