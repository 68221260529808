import { knightFrank, staffResidentRatio, valuationProxies } from '../Constants'
import { GraphQlAaAwardTypes } from '../models/aaAwardTypes'
import { GraphQlCareFunding } from '../models/careFunding'
import { GraphQlCareStats } from '../models/careStats'
import { GraphqlDementiaStats } from '../models/dementiaStats'
import { GraphQlDemographics } from '../models/demographics'
import { GraphQlPopulation } from '../models/population'
import { getGeoValuesFromDataset } from './getGeoValuesFromDataset'

type Sources = {
	numberOfBeds: number
	popIn3: GraphQlPopulation
	popIn6: GraphQlPopulation
	elderlyCareStats: GraphQlCareStats
	unemployment: GraphQlDemographics
	housePrices: GraphQlDemographics
	gdhi: GraphQlDemographics
	hourlyWages: GraphQlDemographics
	fees: GraphQlDemographics
	funding: GraphQlCareFunding
	allowanceAwards: GraphQlAaAwardTypes
	migration: GraphQlDemographics
	dementiaStats: GraphqlDementiaStats
}

function calculateMvuSummary(sources: Sources) {
	const {
		numberOfBeds = 0,
		popIn3,
		popIn6,
		elderlyCareStats,
		unemployment,
		housePrices,
		gdhi,
		hourlyWages,
		fees,
		funding,
		allowanceAwards,
		migration,
		dementiaStats,
	} = sources
	// ─────────────────────────────────────────────────────────────────────────────
	// 1. PROJECT & CARE STATS DATA

	const caData = elderlyCareStats.stats[0]

	const caNursingBeds = caData.nursingBeds
	const caResidentialBeds = caData.residentialBeds
	const caBeds = caData.numberOfBeds
	const caDomCap = caData.domiciliaryCareWorkers * staffResidentRatio.homecare
	const caTotalCap = caDomCap + caBeds
	const regionNursingBeds = elderlyCareStats.stats[elderlyCareStats.stats.length - 2].nursingBeds
	const regionResidentialBeds = elderlyCareStats.stats[elderlyCareStats.stats.length - 2].residentialBeds
	const regionBeds = elderlyCareStats.stats[elderlyCareStats.stats.length - 2].numberOfBeds
	const regionDomCap =
		elderlyCareStats.stats[elderlyCareStats.stats.length - 2].domiciliaryCareWorkers * staffResidentRatio.homecare
	const regionTotalCap = regionDomCap + regionBeds

	// Assume getGeoValuesFromDataset returns an object with a 'ca' property
	const caDementia = getGeoValuesFromDataset(dementiaStats.estimated).ca
	const regionDementia = getGeoValuesFromDataset(dementiaStats.estimated).region

	const geosIn3 = popIn3?.geographies || []
	const geosIn6 = popIn6?.geographies || []
	const caPop75_2024 = geosIn3[0].ageRanges[0].values[0]
	const caPop75_2027 = geosIn3[0].ageRanges[0].values[1]
	const caPop75_2030 = geosIn6[0].ageRanges[0].values[1]
	const regionPop75_2024 = geosIn3[geosIn3.length - 2].ageRanges[0].values[0]
	const regionPop75_2027 = geosIn3[geosIn3.length - 2].ageRanges[0].values[1]
	const regionPop75_2030 = geosIn6[geosIn6.length - 2].ageRanges[0].values[1]

	const { ca: caDemand_2027 } = getGeoValuesFromDataset(allowanceAwards.higherRate)

	const { ca: caDemand_2024 } = getGeoValuesFromDataset(allowanceAwards.higherRate)

	const caHRPopRatio = caDemand_2024 / caPop75_2024

	const caBedShortage_2024 = Math.round(caBeds - caDemand_2024)
	const caBedShortage_2027 = Math.round(caBeds - caHRPopRatio * caPop75_2027)
	const caBedShortage_2030 = Math.round(caBeds - caHRPopRatio * caPop75_2030)

	// ─────────────────────────────────────────────────────────────────────────────
	// 2. LOCAL ECONOMY & DEMOGRAPHICS

	const {
		ca: caGDHI,
		region: regionGDHI,
		//country: countryGDHI
	} = getGeoValuesFromDataset(gdhi.datasets)
	const {
		ca: caUnemployment,
		region: regionUnemployment, //country: countryUe
	} = getGeoValuesFromDataset(unemployment.datasets)
	const {
		ca: caHousePrice,
		region: regionHousePrice, //country: countryHp
	} = getGeoValuesFromDataset(housePrices.datasets)

	// ─────────────────────────────────────────────────────────────────────────────
	// 3. STAFFING & WAGE INFO

	const { ca: hwCa, region: hwRegion, country: hwCountry } = getGeoValuesFromDataset(hourlyWages.datasets)

	const caWageIndex = hwCa / hwCountry
	const regionWageIndex = hwRegion / hwCountry

	const countryPersonalCareWage = knightFrank.hourlyWages.residential
	const caPersonalCareWage = caWageIndex * countryPersonalCareWage
	const regionPersonalCareWage = regionWageIndex * countryPersonalCareWage

	const countryCareWage = knightFrank.hourlyWages.nursing
	const caCareWage = caWageIndex * countryCareWage
	const regionCareWage = regionWageIndex * countryCareWage

	const caCareHomeWorkforce = elderlyCareStats.stats[0].careHomeWorkers
	const regionCareHomeWorkforce = elderlyCareStats.stats[elderlyCareStats.stats.length - 2].careHomeWorkers

	const caCareHomeWorkforcePerElderly = (caCareHomeWorkforce / caPop75_2024) * 100
	const regionCareHomeWorkforcePerElderly = (regionCareHomeWorkforce / regionPop75_2024) * 100

	const caDomWorkforce = elderlyCareStats.stats[0].domiciliaryCareWorkers
	const regionDomWorkforce = elderlyCareStats.stats[elderlyCareStats.stats.length - 2].domiciliaryCareWorkers

	// const caDomWorkforcePerElderly = (caDomWorkforce / caPop75_2024) * 100
	// const regionDomWorkforcePerElderly = (regionDomWorkforce / regionPop75_2024) * 100

	const caTotalCareWorkforcePerElderly = ((caCareHomeWorkforce + caDomWorkforce) / caPop75_2024) * 100
	const regionTotalCareWorkforcePerElderly = ((regionCareHomeWorkforce + regionDomWorkforce) / regionPop75_2024) * 100

	const caDomestic = migration.datasets[0].values[0]
	const regionDomestic = migration.datasets[migration.datasets.length - 2].values[0]

	const caInternational = migration.datasets[0].values[1]
	const regionInternational = migration.datasets[migration.datasets.length - 2].values[1]

	const caMigration = caDomestic + caInternational
	const regionMigration = regionDomestic + regionInternational

	// ─────────────────────────────────────────────────────────────────────────────
	// 4. FEE & FUNDING CALCULATIONS
	const caRating = elderlyCareStats.stats[0].rating * 100
	//const regionRating = careStats.stats[careStats.stats.length - 2].rating * 100

	//const regionLaFeeResidential = fees.datasets[fees.datasets.length - 2]?.values[4] || 0
	//const regionStateFundingShare = funding.StateFunded[funding.StateFunded.length - 2]?.values[0] || 0

	// ─────────────────────────────────────────────────────────────────────────────
	// 5. BUSINESS FORECAST - MIXED CARE HOME (50% Residential, 50% Nursing)

	// Fee Analysis

	const caMarginFraction =
		knightFrank.cqcMargins.baseCqcMargin + (knightFrank.cqcMargins.rangeCqcMargin * caRating) / 100
	const projectMarginFraction =
		knightFrank.cqcMargins.baseCqcMargin + knightFrank.cqcMargins.rangeCqcMargin * knightFrank.cqcMargins.projectRating
	const feeMultiplier = (1 - caMarginFraction) / (1 - projectMarginFraction)

	const caStateFundingShare = funding.StateFunded[0].values[0]

	const caLaFeeResidential = fees.datasets[0].values[4]
	const projectLAFeeResidential = caLaFeeResidential * feeMultiplier

	const caPrivateFeeResidential = caLaFeeResidential * knightFrank.privateBedFees.residentialStateToPrivate
	// const regionPrivateFeeResidential = regionLaFeeResidential * knightFrank.privateBedFees.residentialStateToPrivate
	const projectPrivateFeeResidential = caPrivateFeeResidential * feeMultiplier

	const caLaFeeNursing = fees.datasets[0].values[5]
	// const regionLaFeeNursing = careFees.datasets[careFees.datasets.length - 2].values[5]
	const projectLAFeeNursing = caLaFeeNursing * feeMultiplier

	const caPrivateFeeNursing = caLaFeeNursing * knightFrank.privateBedFees.nursingStateToPrivate
	// const regionPrivateFeeNursing = regionLaFeeNursing * knightFrank.privateBedFees.nursingStateToPrivate

	const projectPrivateFeeNursing = caPrivateFeeNursing * feeMultiplier

	const caExpectedAvgResidentialFee =
		(caStateFundingShare * caLaFeeResidential) / 100 + (1 - caStateFundingShare / 100) * caPrivateFeeResidential
	const projectExpectedResidentialFee = caExpectedAvgResidentialFee * feeMultiplier
	// const regionExpectedAvgResidentialFee = (regionStateFundingShare * regionLaFeeResidential) / 100 + (1 - regionStateFundingShare / 100) * regionPrivateFeeResidential

	const caExpectedAvgNursingFee =
		(caStateFundingShare * caLaFeeNursing) / 100 + (1 - caStateFundingShare / 100) * caPrivateFeeNursing
	const projectExpectedNursingFee = caExpectedAvgNursingFee * feeMultiplier
	// const regionExpectedAvgNursingFee = (regionStateFundingShare * regionLaFeeNursing) / 100 + (1 - regionStateFundingShare / 100) * regionPrivateFeeNursing

	// Profitability

	const countryStaffingCost_PC = knightFrank.annualStaffingCostPerBed.residential * knightFrank.occupancy.residential
	const caStaffingCost_PC = caWageIndex * countryStaffingCost_PC

	const countryStaffingCost_N = knightFrank.annualStaffingCostPerBed.nursing * knightFrank.occupancy.nursing
	const caStaffingCost_N = caWageIndex * countryStaffingCost_N

	// Business Forecasts
	const occupancy = valuationProxies.occupancy

	// Nursing Home

	// Mixed: Residential and Nursing
	const projectTurnoverMixedHome =
		((1 - valuationProxies.nursingShareMixed) * projectExpectedResidentialFee +
			valuationProxies.nursingShareMixed * projectExpectedNursingFee) *
		numberOfBeds *
		52 *
		occupancy
	const projectStaffingCostMixedHome =
		((1 - valuationProxies.nursingShareMixed) * caStaffingCost_PC +
			valuationProxies.nursingShareMixed * caStaffingCost_N) *
		numberOfBeds
	const projectOverheadCostMixedHome = projectStaffingCostMixedHome * knightFrank.overheadCost.overheadStaffingRatio
	const projectOperatingCostMixedHome = projectStaffingCostMixedHome + projectOverheadCostMixedHome
	const projectEBITDARMMixedHome = projectTurnoverMixedHome - projectOperatingCostMixedHome
	const projectEBITDARMMarginMixedHome = (projectEBITDARMMixedHome / projectTurnoverMixedHome) * 100
	const projectRentMixedHome = projectEBITDARMMixedHome / valuationProxies.rentCover

	const projectEBITDA = projectEBITDARMMixedHome - projectRentMixedHome
	const projectEBITDAMargin = (projectEBITDA / projectTurnoverMixedHome) * 100

	const projectValuation = projectRentMixedHome / 0.055
	const round2 = (value: number): number => Number(value.toFixed(2))

	// ─────────────────────────────────────────────────────────────────────────────
	// 6. CONSOLIDATED CONSTANTS FOR THE PROMPT
	return {
		// Economic
		caGDHI: round2(caGDHI),
		regionGDHI: round2(regionGDHI),
		caHousePrice: round2(caHousePrice),
		regionHousePrice: round2(regionHousePrice),
		caUnemployment: round2(caUnemployment),
		regionUnemployment: round2(regionUnemployment),

		//Elderly Population (use this also to calculate per capita ratios to compare with region if needed)
		caPop75_2024: round2(caPop75_2024),
		caPop75_2027: round2(caPop75_2027),
		caPop75_2030: round2(caPop75_2030),
		regionPop75_2024: round2(regionPop75_2024),
		regionPop75_2027: round2(regionPop75_2027),
		regionPop75_2030: round2(regionPop75_2030),

		// Elderly Care Capacity and Quality of Supply (caRating refers to the average CQC rating of care homes in the ca (ratings range from inadequate-0, needs improvement-0.33, good - 0.66, outstanding-1)
		caNursingBeds: round2(caNursingBeds),
		caResidentialBeds: round2(caResidentialBeds),
		regionNursingBeds: round2(regionNursingBeds),
		regionResidentialBeds: round2(regionResidentialBeds),
		caBeds: round2(caBeds),
		regionBeds: round2(regionBeds),
		caDomCap: round2(caDomCap),
		caTotalCap: round2(caTotalCap),
		regionTotalCap: round2(regionTotalCap),
		caDementia: round2(caDementia),
		regionDementia: round2(regionDementia),
		caRating: round2(caRating),
		projectRating: 0.83, // Project rating is assumed to be between good and outstanding

		// Elderly Care Demand and Bed Shortage Forecast (2024, 2027, 2030)
		caDemand_2024: round2(caDemand_2024),
		caBedShortage_2024: round2(caBedShortage_2024),
		caDemand_2027: round2(caDemand_2027),
		caBedShortage_2027: round2(caBedShortage_2027),
		caBedShortage_2030: round2(caBedShortage_2030),

		// Staffing & Wages (higher wages imply ease in attracting and retaining staff, lower wages imply better margins or/and room to offer higher wages to attract staff)
		caCareHomeWorkforcePerElderly: round2(caCareHomeWorkforcePerElderly),
		regionCareHomeWorkforcePerElderly: round2(regionCareHomeWorkforcePerElderly),
		caPersonalCareWage: round2(caPersonalCareWage),
		regionPersonalCareWage: round2(regionPersonalCareWage),
		caCareWage: round2(caCareWage),
		regionCareWage: round2(regionCareWage),
		caTotalCareWorkforcePerElderly: round2(caTotalCareWorkforcePerElderly),
		regionTotalCareWorkforcePerElderly: round2(regionTotalCareWorkforcePerElderly),

		// Migration Indicators (ease to attract and retain staff)
		caMigration: round2(caMigration),
		regionMigration: round2(regionMigration),

		// Weekly Bed Fee Analyis (where LA refers to fees paid by the local authority and Private refers to fees paid by private individuals, few multiplier is a potential fee uplift for our project due to CQC rating as we expect a rating between good and outstanding = 0.83)
		caLaFeeNursing: round2(caLaFeeNursing),
		caPrivateFeeNursing: round2(caPrivateFeeNursing),
		caPrivateFeeResidential: round2(caPrivateFeeResidential),
		caLaFeeResidential: round2(caLaFeeResidential),
		feeMultiplier: round2(feeMultiplier),
		projectLAFeeNursing: round2(projectLAFeeNursing),
		projectPrivateFeeNursing: round2(projectPrivateFeeNursing),
		projectLAFeeResidential: round2(projectLAFeeResidential),
		projectPrivateFeeResidential: round2(projectPrivateFeeResidential),

		// P&L Business Forecast: stabilised forecast for a care home with both residential and nursing beds assuming an average occupancy rate of 90% due to high elderly care demand
		projectTurnoverMixedHome: round2(projectTurnoverMixedHome),
		projectEBITDARMMixedHome: round2(projectEBITDARMMixedHome),
		projectEBITDARMMarginMixedHome: round2(projectEBITDARMMarginMixedHome),
		projectRentMixedHome: round2(projectRentMixedHome),
		projectValuation: round2(projectValuation),
		projectEBITDAMargin: round2(projectEBITDAMargin),
	}
}

export { calculateMvuSummary }
