import graphql from 'babel-plugin-relay/macro'

export const UpdateProject = graphql`
	mutation UpdateProjectMutation($id: UUID!, $input: UpdateProjectInput!) {
		updateProject(id: $id, input: $input) {
			address
			area
			client
			date
			id
			ladCode
			latitude
			longitude
			mapImageUrl
			name
			oaCode
			postalCode
			propertyIds
			reference
			settings
			status
			titleNumbers
			townCity
			userId
		}
	}
`
