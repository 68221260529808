import React from 'react'

interface Props {
	className?: string
	children: React.ReactNode
	flexDirection?: 'row' | 'column'
	height?: string | number
}

const LeftAligned: React.FC<Props> = ({ children, className, flexDirection = 'row', height = '100%' }) => (
	<div
		className={className}
		style={{
			display: 'flex',
			flexDirection,
			justifyContent: 'flex-start',
			alignItems: 'center',
			height,
			width: '100%',
		}}
	>
		{children}
	</div>
)

const CenterAligned: React.FC<Props> = ({ children, className, flexDirection = 'row', height = '100%' }) => (
	<div
		className={className}
		style={{
			display: 'flex',
			flexDirection,
			justifyContent: 'center',
			alignItems: 'center',
			height,
		}}
	>
		{children}
	</div>
)

const RightAligned: React.FC<Props> = ({ children, className, flexDirection = 'row', height = '100%' }) => (
	<div
		className={className}
		style={{
			display: 'flex',
			flexDirection,
			justifyContent: 'flex-end',
			alignItems: 'center',
			height,
		}}
	>
		{children}
	</div>
)

const SpaceBetween: React.FC<Props> = ({ children, className, flexDirection = 'row' }) => (
	<div
		className={className}
		style={{
			display: 'flex',
			flexDirection,
			justifyContent: 'space-between',
			alignItems: 'center',
			width: '100%',
		}}
	>
		{children}
	</div>
)

interface VerticallyAlignedProps {
	children: React.ReactNode
}

const VerticallyAligned: React.FC<VerticallyAlignedProps> = ({ children }) => {
	return (
		<div className="d-flex justify-content-center align-items-center" style={{ height: '100%' }}>
			{children}
		</div>
	)
}

export { CenterAligned, LeftAligned, RightAligned, SpaceBetween, VerticallyAligned }
