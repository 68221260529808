import React from 'react'

import { milesRadiusValueInInts } from '../../../../../Constants'
import { RadiusValues } from '../../../../../graphql/queries/__generated__/HourlyWagesByCaQuery.graphql'
import { GraphQlDemographics } from '../../../../../models/demographics'
import { GraphQlPopulation } from '../../../../../models/population'
import { themeColors } from '../../../../../Theme'
import { formatCurrency, formatNumber, formatPercentage } from '../../../../../utils/formatNumber'
import { getGeoValuesFromDataset, getValuesFromPopulationDataset } from '../../../../../utils/getGeoValuesFromDataset'
import { Label, Panel } from '../../../shared/SubComponents'
import { Table, TableData } from '../../../shared/Table'

interface Props {
	regionName: string
	radius: RadiusValues
	totalPop: GraphQlPopulation
	elderlyPop: GraphQlPopulation
	unemployment: GraphQlDemographics
	housePrices: GraphQlDemographics
	gdhi: GraphQlDemographics
	hourlyWages: GraphQlDemographics
}

const AreaProfile: React.FC<Props> = ({
	regionName,
	radius,
	totalPop,
	elderlyPop,
	unemployment,
	housePrices,
	gdhi,
	hourlyWages,
}) => {
	const radiusInt = milesRadiusValueInInts[radius]

	const { ca: caPop, region: regionPop } = getValuesFromPopulationDataset(totalPop)
	const { ca: caElderly, region: regionElderly } = getValuesFromPopulationDataset(elderlyPop)
	const caElderlyPercentage = (caElderly / caPop) * 100
	const regionElderlyPercentage = (regionElderly / regionPop) * 100

	const { ca: caGDHI, region: regionGDHI } = getGeoValuesFromDataset(gdhi.datasets)
	const { ca: caHw, region: regionHw } = getGeoValuesFromDataset(hourlyWages.datasets)
	const { ca: caUe, region: regionUe } = getGeoValuesFromDataset(unemployment.datasets)
	const { ca: caHp, region: regionHp } = getGeoValuesFromDataset(housePrices.datasets)

	const headers = ['', `${radiusInt} mile catchment`, regionName]
	const data: TableData = [
		[['Population', formatNumber(caPop), formatNumber(regionPop)]],
		[
			[{ value: 'Elderly (75+)' }, { value: formatNumber(caElderly) }, { value: formatNumber(regionElderly) }],
			[
				{ value: '%' },
				{
					value: formatPercentage(caElderlyPercentage, 0, 1),
					bold: caElderlyPercentage > regionElderlyPercentage ? true : false,
					color: caElderlyPercentage > regionElderlyPercentage ? themeColors.primary : undefined,
				},
				{ value: formatPercentage(regionElderlyPercentage, 0, 1) },
			],
		],
		[
			['Gross Disposable Income', formatCurrency(caGDHI, 0), formatCurrency(regionGDHI, 0)],
			['Hourly Wage', formatCurrency(caHw, 2), formatCurrency(regionHw, 2)],
			['Unemployment Rate', formatPercentage(caUe, 0, 1), formatPercentage(regionUe, 0, 1)],
			['House Price Average', formatCurrency(caHp, 0), formatCurrency(regionHp, 0)],
		],
	]

	return (
		<Panel height="230px" padding={20}>
			<Label point={9}>Area Profile</Label>
			<Table headers={headers} data={data}></Table>
		</Panel>
	)
}

export { AreaProfile }
