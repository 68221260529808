/**
 * @generated SignedSource<<8690edb43315bcb60185c49ce0b97c08>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type RadiusValues = "eight" | "five" | "four" | "nine" | "one" | "seven" | "six" | "ten" | "three" | "two" | "%future added value";
export type AAUniversalCreditPopulationRatiosByCaQuery$variables = {
  oaCode: string;
  radius: RadiusValues;
};
export type AAUniversalCreditPopulationRatiosByCaQuery$data = {
  readonly aaUniversalCreditPopulationRatiosByCa: {
    readonly datasets: ReadonlyArray<{
      readonly code: string;
      readonly values: ReadonlyArray<number>;
    }>;
    readonly labels: ReadonlyArray<string>;
  };
};
export type AAUniversalCreditPopulationRatiosByCaQuery = {
  response: AAUniversalCreditPopulationRatiosByCaQuery$data;
  variables: AAUniversalCreditPopulationRatiosByCaQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "oaCode"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "radius"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "oaCode",
        "variableName": "oaCode"
      },
      {
        "kind": "Variable",
        "name": "radius",
        "variableName": "radius"
      }
    ],
    "concreteType": "AAUniversalCreditPopulationRatios",
    "kind": "LinkedField",
    "name": "aaUniversalCreditPopulationRatiosByCa",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "labels",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "AAUniversalCreditPopulationRatioData",
        "kind": "LinkedField",
        "name": "datasets",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "code",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "values",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AAUniversalCreditPopulationRatiosByCaQuery",
    "selections": (v1/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AAUniversalCreditPopulationRatiosByCaQuery",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "e0d4388b6b1e46dcf6f9f7c55cf0b51e",
    "id": null,
    "metadata": {},
    "name": "AAUniversalCreditPopulationRatiosByCaQuery",
    "operationKind": "query",
    "text": "query AAUniversalCreditPopulationRatiosByCaQuery(\n  $oaCode: String!\n  $radius: RadiusValues!\n) {\n  aaUniversalCreditPopulationRatiosByCa(oaCode: $oaCode, radius: $radius) {\n    labels\n    datasets {\n      code\n      values\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a1387d562d85f0bec4fa388c90b37808";

export default node;
