import React from 'react'
import { useLazyLoadQuery } from 'react-relay'
import { useParams } from 'react-router-dom'

import { getUserId } from '../../../Config'
import { radiusValues, Specialism } from '../../../Constants'
import { ProjectsByUserIdQuery } from '../../../graphql/queries/__generated__/ProjectsByUserIdQuery.graphql'
import { ProjectsByUserId } from '../../../graphql/queries/ProjectsByUserId'
import { createProjectModel } from '../../../models/project'
import { Container, Row } from '../../../shared/layout/Grid'
import { VerticalSpace } from '../../../shared/layout/Space'
import { Loading } from '../../../shared/Loading'
import { Paragraph } from '../../../shared/Text'
import { squareMetersToAcres } from '../../../utils/acres'
import { formatNumber } from '../../../utils/formatNumber'
import { createStaticImageUrl, createStaticMapWithPin } from '../../../utils/staticMap'
import {
	AnchorRow,
	BedRows,
	BusinessAnalysisGroupRow,
	CareScoreRow,
	CareWageRows,
	CompetitionRows,
	DementiaBedsNursingRow,
	DementiaBedsResidentialRow,
	DementiaBedsRow,
	DementiaRow,
	DementiaScoreRow,
	DisRow,
	DomiciliaryCapacityRow,
	ECIFTNRow,
	ExtraCareScoreRow,
	GDHIRow,
	GeoRow,
	Header,
	HealthWorkforceRow,
	HPRow,
	HWRow,
	MarketAnalyticsRow,
	MigrantFactorRow,
	MostViableUseCaseRow,
	NursingScoreRow,
	OccupancyRateRow,
	PopulationRow,
	RatingRow,
	ResidentialScoreRow,
	SRRow,
	TableRow,
	UERow,
} from './Rows'

interface TableProps {
	reference: string
}

interface TableParams extends Record<string, string | undefined> {
	beds: string
}

const Table: React.FC<TableProps> = ({ reference }) => {
	const params = useParams<TableParams>()

	const data = useLazyLoadQuery<ProjectsByUserIdQuery>(ProjectsByUserId, { userId: getUserId() })
	const graphQlProjects = data.projectsByUserId?.projects || []
	const graphQlProject = graphQlProjects.find((p) => p.reference === reference)

	if (!graphQlProject) {
		return <Paragraph>Project not found</Paragraph>
	}

	const project = createProjectModel(graphQlProject)
	const careHomeBeds = project.settings.numberOfBeds || 0
	const oaCode = project.oaCode
	const radius = radiusValues.five
	const radiusCompetition = radiusValues.three
	const numberOfBeds = params.beds ? parseInt(params.beds) : careHomeBeds

	return (
		<Container size="fluid">
			<Row>
				<table className="table">
					<tbody className="table-group-divider">
						<Header data={['Project Info', '', project.name, '', '']}></Header>
						<TableRow data={['Project Id', '', project.id, '', '']}></TableRow>
						<TableRow data={['Project Reference Number', '', project.reference, '', '']}></TableRow>
						<TableRow data={['Street', '', project.address, '', '']}></TableRow>
						<TableRow data={['Postcode', '', project.postalCode, '', '']}></TableRow>
						<TableRow data={['Name of next city', '', project.townCity, '', '']}></TableRow>
						<TableRow data={['Title Numbers', '', project.titleNumbers.join(', '), '', '']}></TableRow>
						<TableRow
							data={['Site Size in acres', '', formatNumber(squareMetersToAcres(project.area)) + ' acres', '', '']}
						></TableRow>
						<GeoRow ladCode={project.ladCode} oaCode={oaCode} radius={radius} />
						<AnchorRow label="Polygon on Map" src={createStaticImageUrl(project.mapImageUrl, 800, 800)} />
						<AnchorRow label="Pin on England Map" src={createStaticMapWithPin(project.latitude, project.longitude)} />
					</tbody>
				</table>
				<VerticalSpace size="lg" />
				<table className="table">
					<thead>
						<Header data={['Statistics', '', '5-Mile Catchment', 'Region', 'England']}></Header>
					</thead>
					<tbody className="table-group-divider">
						<Header data={['MOST VIABLE USE CASE', '', '', '', '']}></Header>
						<MostViableUseCaseRow oaCode={oaCode} radius={radius}></MostViableUseCaseRow>
						<Header data={['POPULATION', '', '', '', '']}></Header>
						<PopulationRow label="Population" oaCode={oaCode} radius={radius}></PopulationRow>
						<PopulationRow label="Working Age" oaCode={oaCode} radius={radius} minAge={16} maxAge={65}></PopulationRow>
						<PopulationRow label="Elderly (65+)" oaCode={oaCode} radius={radius} minAge={65}></PopulationRow>
						<PopulationRow
							label="Elderly (65+) 2032"
							oaCode={oaCode}
							radius={radius}
							minAge={65}
							futureOffset={8}
						></PopulationRow>
						<PopulationRow label="Elderly (75+)" oaCode={oaCode} radius={radius} minAge={75}></PopulationRow>
						<PopulationRow
							label="Elderly (75+) 2032"
							oaCode={oaCode}
							radius={radius}
							minAge={75}
							futureOffset={8}
						></PopulationRow>
						<PopulationRow label="Elderly (65-74)" oaCode={oaCode} radius={radius} minAge={65} maxAge={74} />
						<PopulationRow
							label="Elderly (65-74) 2032"
							oaCode={oaCode}
							radius={radius}
							minAge={65}
							maxAge={74}
							futureOffset={8}
						/>
						<PopulationRow label="Elderly (75-84)" oaCode={oaCode} radius={radius} minAge={75} maxAge={84} />
						<PopulationRow
							label="Elderly (75-84) 2032"
							oaCode={oaCode}
							radius={radius}
							minAge={75}
							maxAge={84}
							futureOffset={8}
						/>
						<PopulationRow label="Elderly (85+)" oaCode={oaCode} radius={radius} minAge={85} />
						<PopulationRow label="Elderly (85+) 2032" oaCode={oaCode} radius={radius} minAge={85} futureOffset={8} />

						<Header data={['AREA PROFILE', '', '', '', '']}></Header>
						<GDHIRow oaCode={oaCode} radius={radius} />
						<HPRow oaCode={oaCode} radius={radius} />
						<SRRow oaCode={oaCode} radius={radius} />
						<HWRow oaCode={oaCode} radius={radius} />
						<UERow oaCode={oaCode} radius={radius} />

						<Header data={['ELDERLY WITH CARE NEEDS', '', '', '', '']}></Header>
						<ECIFTNRow oaCode={oaCode} radius={radius} />
						<DementiaRow oaCode={oaCode} radius={radius} />
						<DisRow oaCode={oaCode} radius={radius} />

						<Header data={['ELDERLY CARE SUPPLY', '', '', '', '']}></Header>
						<OccupancyRateRow oaCode={oaCode} radius={radius} />
						<RatingRow oaCode={oaCode} radius={radius} />
						<BedRows oaCode={oaCode} radius={radius} />
						<DementiaBedsRow oaCode={oaCode} radius={radius} />
						<DementiaBedsNursingRow oaCode={oaCode} radius={radius} />
						<DementiaBedsResidentialRow oaCode={oaCode} radius={radius} />
						<DomiciliaryCapacityRow oaCode={oaCode} radius={radius} />
						<MarketAnalyticsRow oaCode={oaCode} radius={radius} />

						<Header data={['CARE SCORES', '', '', '', '']}></Header>
						<CareScoreRow specialism={Specialism.elderly} oaCode={oaCode} radius={radius} />
						<NursingScoreRow oaCode={oaCode} radius={radius} />
						<ResidentialScoreRow oaCode={oaCode} radius={radius} />
						<ExtraCareScoreRow oaCode={oaCode} radius={radius} />
						<DementiaScoreRow oaCode={oaCode} radius={radius} />
						<Header data={['STAFFING MARKET', '', '', '', '']}></Header>
						<MigrantFactorRow oaCode={oaCode} radius={radius} />
						<HealthWorkforceRow oaCode={oaCode} radius={radius} />
						<CareWageRows oaCode={oaCode} radius={radius} />

						<Header data={['WEEKLY BED FEES', 'Project', '5-Mile CA', 'Region', 'England']}></Header>
						<BusinessAnalysisGroupRow oaCode={oaCode} radius={radius} careHomeBeds={numberOfBeds} />
					</tbody>
				</table>

				<VerticalSpace size="lg" />
				<table className="table">
					<thead>
						<Header data={['Local Competition', '', '', '', '', '']}></Header>
						<Header data={['Name', 'Distance miles', 'Operator', 'Year', 'Type', 'Beds', 'CQC Rating']}></Header>
					</thead>
					<tbody className="table-group-divider">
						<CompetitionRows
							oaCode={oaCode}
							radius={radiusCompetition}
							lat={project.latitude}
							long={project.longitude}
						/>
					</tbody>
				</table>
			</Row>
		</Container>
	)
}

const Suspended: React.FC<TableProps> = (props) => {
	return (
		<React.Suspense fallback={<Loading height="80vh" />}>
			<Table {...props} />
		</React.Suspense>
	)
}

export { Suspended as Table }
