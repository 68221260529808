import { elderlyCareScores, staffResidentRatio } from '../Constants'
import { GraphQlCareStats } from '../models/careStats'
import { GraphqlDementiaStats } from '../models/dementiaStats'
import { GraphQlPopulation } from '../models/population'
import { getGeoValuesFromDataset } from './getGeoValuesFromDataset'

function calculateMVU(
	totalPop: GraphQlPopulation,
	eldelyCareStats: GraphQlCareStats,
	dementiaCareStats: GraphQlCareStats,
	dementiaStats: GraphqlDementiaStats,
): string {
	const {
		ca: caDementia,
		region: regionDementia,
		country: countryDementia,
	} = getGeoValuesFromDataset(dementiaStats.estimated)

	// Define thresholds
	const significantDiff = 10
	const extraCareThreshold = 50 // Adjust if needed

	// Extract Geographical Data
	const geos = totalPop?.geographies || []
	const idx = totalPop.years.length - 1

	const caPop75 = geos[0].ageRanges[0].values[idx]
	const regionPop75 = geos[geos.length - 2].ageRanges[0].values[idx]
	const countryPop75 = geos[geos.length - 1].ageRanges[0].values[idx]

	const caElderlyCareStats = eldelyCareStats.stats[0]
	const regionElderlyCareStats = eldelyCareStats.stats[eldelyCareStats.stats.length - 2]
	const countryElderlyCareStats = eldelyCareStats.stats[eldelyCareStats.stats.length - 1]

	const caDementiaCareStats = dementiaCareStats.stats[0]
	const regionDementiaCareStats = dementiaCareStats.stats[dementiaCareStats.stats.length - 2]
	const countryDementiaCareStats = dementiaCareStats.stats[dementiaCareStats.stats.length - 1]

	// Destruct data
	const {
		nursingBeds: caBeds_N,
		residentialBeds: caBeds_PC,
		domiciliaryCareWorkers: caDomCareWorkers,
	} = caElderlyCareStats
	const {
		nursingBeds: regionBeds_N,
		residentialBeds: regionBeds_PC,
		domiciliaryCareWorkers: regionDomCareWorkers,
	} = regionElderlyCareStats
	const {
		nursingBeds: countryBeds_N,
		residentialBeds: countryBeds_PC,
		domiciliaryCareWorkers: countryDomCareWorkers,
	} = countryElderlyCareStats

	const { numberOfBeds: caBeds_D } = caDementiaCareStats
	const { numberOfBeds: regionBeds_D } = regionDementiaCareStats
	const { numberOfBeds: countryBeds_D } = countryDementiaCareStats

	const {
		nursing: { min: min_N, max: max_N },
		residential: { min: min_PC, max: max_PC },
		domiciliary: { min: min_H, max: max_H },
		dementia: { min: min_D, max: max_D },
	} = elderlyCareScores

	// Calculate Dom Cap
	const caDomCap = caDomCareWorkers * staffResidentRatio.homecare
	const regionDomCap = regionDomCareWorkers * staffResidentRatio.homecare
	const countryDomCap = countryDomCareWorkers * staffResidentRatio.homecare

	// Create rate calculators
	const calculateCaRate = calculateRate(caPop75)
	const calculateRegionRate = calculateRate(regionPop75)
	const calculateCountryRate = calculateRate(countryPop75)
	const calculateCaDementiaRate = calculateRate(caDementia)
	const calculateRegionDementiaRate = calculateRate(regionDementia)
	const calculateCountryDementiaRate = calculateRate(countryDementia)

	// Calculate rates
	const caRate_N = calculateCaRate(caBeds_N)
	const regionRate_N = calculateRegionRate(regionBeds_N)
	const countryRate_N = calculateCountryRate(countryBeds_N)

	const caRate_PC = calculateCaRate(caBeds_PC)
	const regionRate_PC = calculateRegionRate(regionBeds_PC)
	const countryRate_PC = calculateCountryRate(countryBeds_PC)

	const caRate_H = calculateCaRate(caDomCap)
	const regionRate_H = calculateRegionRate(regionDomCap)
	const countryRate_H = calculateCountryRate(countryDomCap)

	const caRate_D = calculateCaDementiaRate(caBeds_D)
	const regionRate_D = calculateRegionDementiaRate(regionBeds_D)
	const countryRate_D = calculateCountryDementiaRate(countryBeds_D)

	// Calculate Oppurtunity
	const caOpportunity_N = calculateOpportunity(caRate_N, min_N, max_N)
	const regionOpportunity_N = calculateOpportunity(regionRate_N, min_N, max_N)
	const countryOpportunity_N = calculateOpportunity(countryRate_N, min_N, max_N)

	const caOpportunity_PC = calculateOpportunity(caRate_PC, min_PC, max_PC)
	const regionOpportunity_PC = calculateOpportunity(regionRate_PC, min_PC, max_PC)
	const countryOpportunity_PC = calculateOpportunity(countryRate_PC, min_PC, max_PC)

	const caOpportunity_H = calculateOpportunity(caRate_H, min_H, max_H)
	const regionOpportunity_H = calculateOpportunity(regionRate_H, min_H, max_H)
	const countryOpportunity_H = calculateOpportunity(countryRate_H, min_H, max_H)

	const caOpportunity_D = calculateOpportunity(caRate_D, min_D, max_D)
	const regionOpportunity_D = calculateOpportunity(regionRate_D, min_D, max_D)
	const countryOpportunity_D = calculateOpportunity(countryRate_D, min_D, max_D)

	// For CA
	let caBestOpportunity = '-'
	if (caOpportunity_N >= 50 || caOpportunity_PC >= 50) {
		if (caOpportunity_N >= 50 && caOpportunity_PC < 50) {
			caBestOpportunity = 'Nursing Care Home'
		} else if (caOpportunity_PC >= 50 && caOpportunity_N < 50) {
			caBestOpportunity = 'Residential Care Home'
		} else {
			if (Math.abs(caOpportunity_N - caOpportunity_PC) > significantDiff) {
				caBestOpportunity = caOpportunity_N > caOpportunity_PC ? 'Nursing Care Home' : 'Residential Care Home'
			} else {
				caBestOpportunity = 'Nursing and Residential Care Home'
			}
		}
	}
	// Incorporate Extra Care (Home Care)
	if (caOpportunity_H >= extraCareThreshold) {
		if (caBestOpportunity === '-') {
			caBestOpportunity = 'Extra Care'
		} else {
			caBestOpportunity += ' & Extra Care'
		}
	}
	// Incorporate Dementia (if opportunity is high enough)
	if (caOpportunity_D >= 60) {
		if (caBestOpportunity.includes('Nursing Care Home')) {
			caBestOpportunity = caBestOpportunity.replace('Nursing Care Home', 'Dementia Nursing Care Home')
		} else if (caBestOpportunity.includes('Residential Care Home')) {
			caBestOpportunity = caBestOpportunity.replace('Residential Care Home', 'Dementia Residential Care Home')
		} else if (caBestOpportunity.includes('Nursing and Residential Care Home')) {
			caBestOpportunity = caBestOpportunity.replace(
				'Nursing and Residential Care Home',
				'Nursing and Residential Dementia Care Home',
			)
		} else if (caBestOpportunity.includes('Extra Care')) {
			caBestOpportunity = 'Dementia Extra Care'
		}
	}

	// For Region
	let regionBestOpportunity = '-'
	if (regionOpportunity_N >= 50 || regionOpportunity_PC >= 50) {
		if (regionOpportunity_N >= 50 && regionOpportunity_PC < 50) {
			regionBestOpportunity = 'Nursing Care Home'
		} else if (regionOpportunity_PC >= 50 && regionOpportunity_N < 50) {
			regionBestOpportunity = 'Residential Care Home'
		} else {
			if (Math.abs(regionOpportunity_N - regionOpportunity_PC) > significantDiff) {
				regionBestOpportunity =
					regionOpportunity_N > regionOpportunity_PC ? 'Nursing Care Home' : 'Residential Care Home'
			} else {
				regionBestOpportunity = 'Nursing and Residential Care Home'
			}
		}
	}
	// Extra Care for Region
	if (regionOpportunity_H >= extraCareThreshold) {
		if (regionBestOpportunity === '-') {
			regionBestOpportunity = 'Extra Care'
		} else {
			regionBestOpportunity += ' & Extra Care'
		}
	}
	// Dementia for Region
	if (regionOpportunity_D >= 60) {
		if (regionBestOpportunity.includes('Nursing Care Home')) {
			regionBestOpportunity = regionBestOpportunity.replace('Nursing Care Home', 'Dementia Nursing Care Home')
		} else if (regionBestOpportunity.includes('Residential Care Home')) {
			regionBestOpportunity = regionBestOpportunity.replace('Residential Care Home', 'Dementia Residential Care Home')
		} else if (regionBestOpportunity.includes('Nursing and Residential Care Home')) {
			regionBestOpportunity = regionBestOpportunity.replace(
				'Nursing and Residential Care Home',
				'Nursing and Residential Dementia Care Home',
			)
		} else if (regionBestOpportunity.includes('Extra Care')) {
			regionBestOpportunity = 'Dementia Extra Care'
		}
	}

	// For Country
	let countryBestOpportunity = '-'
	if (countryOpportunity_N >= 50 || countryOpportunity_PC >= 50) {
		if (countryOpportunity_N >= 50 && countryOpportunity_PC < 50) {
			countryBestOpportunity = 'Nursing Care Home'
		} else if (countryOpportunity_PC >= 50 && countryOpportunity_N < 50) {
			countryBestOpportunity = 'Residential Care Home'
		} else {
			if (Math.abs(countryOpportunity_N - countryOpportunity_PC) > significantDiff) {
				countryBestOpportunity =
					countryOpportunity_N > countryOpportunity_PC ? 'Nursing Care Home' : 'Residential Care Home'
			} else {
				countryBestOpportunity = 'Nursing and Residential Care'
			}
		}
	}
	// Extra Care for Country
	if (countryOpportunity_H >= extraCareThreshold) {
		if (countryBestOpportunity === '-') {
			countryBestOpportunity = 'Extra Care'
		} else {
			countryBestOpportunity += ' & Extra Care'
		}
	}
	// Dementia for Country
	if (countryOpportunity_D >= 60) {
		if (countryBestOpportunity.includes('Nursing Care Home')) {
			countryBestOpportunity = countryBestOpportunity.replace('Nursing Care Home', 'Dementia Nursing Care')
		} else if (countryBestOpportunity.includes('Residential Care Home')) {
			countryBestOpportunity = countryBestOpportunity.replace('Residential Care Home', 'Dementia Residential Care')
		} else if (countryBestOpportunity.includes('Nursing and Residential Care')) {
			countryBestOpportunity = countryBestOpportunity.replace(
				'Nursing and Residential Care',
				'Nursing and Residential Dementia Care',
			)
		} else if (countryBestOpportunity.includes('Extra Care')) {
			countryBestOpportunity = 'Dementia Extra Care'
		}
	}

	// For the table display
	return caBestOpportunity
}

function calculateOpportunity(rate: number, min: number, max: number): number {
	return 100 - ((rate - min) / (max - min)) * 100
}

function calculateRate(population: number) {
	return (beds: number): number => {
		return beds / population
	}
}

export { calculateMVU }
