import graphql from 'babel-plugin-relay/macro'

export const PromptAgent = graphql`
	query PromptAgentQuery($content: String!, $version: OpenAiVersions!) {
		promptAgent(content: $content, version: $version) {
			response
			version
		}
	}
`
