import { ArcElement, Chart as ChartJS, ChartData, ChartOptions, Legend, Tooltip } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import React from 'react'
import { Doughnut } from 'react-chartjs-2'

import { themeColors } from '../../Theme'
// Adjust the import path as needed
import { Model } from './types'

// Register Chart.js components and plugins
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels)

interface PieChartProps {
	model: Model
	aspectRatio?: number
	responsive?: boolean
	showDataLabels?: boolean
	colors?: string[][]
	cutout?: string | number
}

export const PieChart: React.FC<PieChartProps> = ({
	model,
	aspectRatio = 1,
	responsive = true,
	showDataLabels = true,
	colors,
	cutout,
}) => {
	const colorPalette = themeColors.colorPalette

	// Assign colors dynamically based on index, looping through colorPalette
	const getDynamicColors = (length: number) => {
		return Array.from({ length }, (_, i) => colorPalette[i % colorPalette.length])
	}

	// Prepare individual datasets for multi-series pie, each dataset will have same color palette
	const pieDatasets = model.datasets.map((d, i) => ({
		label: d.id,
		data: d.values,
		backgroundColor: colors ? colors[i] : getDynamicColors(d.values.length),
		borderWidth: 1,
	}))

	// Combine labels and datasets to match multi-series pie chart structure
	const combinedData: ChartData<'doughnut'> = {
		labels: model.labels,
		datasets: pieDatasets as ChartData<'doughnut'>['datasets'],
	}

	// Chart options (copied and adjusted from Retool version)
	const options: ChartOptions<'doughnut'> = {
		cutout,
		responsive,
		aspectRatio,
		plugins: {
			legend: {
				position: 'top',
				align: 'start',
				labels: {
					generateLabels: function (chart) {
						const originalLabels = ChartJS.overrides.doughnut.plugins.legend.labels.generateLabels(chart)
						const datasetColors = chart.data.datasets.map((e) => e.backgroundColor).flat()

						originalLabels.forEach((label) => {
							if (typeof label.index !== 'undefined' && datasetColors[label.index]) {
								label.fillStyle = datasetColors[label.index] as string
							}
						})

						return originalLabels
					},
				},
				onClick: (e, legendItem, legend) => {
					const index = legendItem.datasetIndex
					if (typeof index !== 'undefined') {
						const meta = legend.chart.getDatasetMeta(index)
						meta.hidden = !legend.chart.isDatasetVisible(index)
						legend.chart.update()
					}
				},
			},
			tooltip: {
				callbacks: {
					label: (context) => {
						const datasetLabel = context.dataset.label || ''
						const label = context.label || ''
						const value = context.parsed
						return `${datasetLabel} - ${label}: ${value}%`
					},
				},
				usePointStyle: true,
				bodyColor: themeColors.darkText,
				titleColor: themeColors.darkText,
				boxPadding: 4,
				borderColor: themeColors.darkText,
				borderWidth: 0.5,
				padding: 12,
			},
			datalabels: {
				padding: 6,
				borderRadius: 32,
				borderWidth: 1,
				borderColor: themeColors.darkText,
				offset: 8,
				display: function (context) {
					// Only show the label for the first data point (index 0) of each dataset
					return showDataLabels && context.dataIndex === 0
				},
				formatter: function (value, context) {
					// Get the dataset label, which corresponds to the region name
					const regionLabel = context.dataset.label || ''
					return `${regionLabel}`
				},
			},
		},
	}

	return (
		<div>
			<Doughnut data={combinedData} options={options} />
		</div>
	)
}
