import React from 'react'

import { Project } from '../../../models/project'
import { Col, Container, Row } from '../../../shared/layout/Grid'
import { formatDate } from '../../../utils/formatNumber'
import { PageTitle, PropmatchLogoPanel, TextPanel } from './SubComponents'

interface TemplateProps {
	pageTitle?: string
	pageSubtitle?: string
	reportType: string
	children: React.ReactNode
	localAuthority: string
	region: string
	project: Project
}

const Template: React.FC<TemplateProps> = ({
	pageTitle,
	pageSubtitle,
	reportType,
	children,
	project,
	localAuthority,
	region,
}) => {
	const titleNumberString = project.titleNumbers.join(' | ')
	const titleNumbersOrPlaceholder = titleNumberString || '-'
	const created = new Date().toLocaleDateString('en-Gb', {
		weekday: 'long',
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	})
	const displayDate = project.date ? formatDate(project.date) : created

	return (
		<>
			<Container size="fluid" removePadding={true}>
				<div className="p-2">
					<Row gutterSize={2}>
						<Col count={12}>
							<Row gutterSize={2}>
								<Col count={10}>{pageTitle && <PageTitle title={pageTitle} subtitle={pageSubtitle} />}</Col>
								<Col count={2}>
									<PropmatchLogoPanel />
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
			</Container>
			<div className="px-2">{children}</div>
			<Container size="fluid" removePadding={true}>
				<div className="p-2">
					<Row gutterSize={2}>
						<Col count={4}>
							<Row gutterSize={2}>
								<Col count={6}>
									<TextPanel title="Project Reference" subtitle={project.reference} transparent={true} />
								</Col>
								<Col count={6}>
									<TextPanel title="Site Title Numbers" subtitle={titleNumbersOrPlaceholder} transparent={true} />
								</Col>
							</Row>
						</Col>
						<Col count={4}>
							<Row gutterSize={2}>
								<Col count={6}>
									<TextPanel title="Report Type" subtitle={reportType} transparent={true} />
								</Col>
								<Col count={6}>
									<TextPanel title={localAuthority} subtitle={region} transparent={true} />
								</Col>
							</Row>
						</Col>
						<Col count={4}>
							<Row gutterSize={2}>
								<Col count={6}>
									<TextPanel title="Client" subtitle={project.client} transparent={true} />
								</Col>
								<Col count={6}>
									<TextPanel title="Date" subtitle={displayDate} transparent={true} />
								</Col>
							</Row>
						</Col>
					</Row>
				</div>
			</Container>
		</>
	)
}

export { Template }
