import React from 'react'

import { knightFrank } from '../../../../../Constants'
import { GraphQlCareStats } from '../../../../../models/careStats'
import { GraphQlDemographics } from '../../../../../models/demographics'
import { GraphQlGeography } from '../../../../../models/geography'
import { GraphQlPopulation } from '../../../../../models/population'
import { Model } from '../../../../../shared/charts/types'
import { SpaceBetween } from '../../../../../shared/layout/Alignment'
import { VerticalSpace } from '../../../../../shared/layout/Space'
import { themeColors } from '../../../../../Theme'
import { formatCurrency, formatNumber, formatPercentage } from '../../../../../utils/formatNumber'
import { getGeosFromGeographies, getGeoValuesFromDataset } from '../../../../../utils/getGeoValuesFromDataset'
import { DonutChart } from '../../../shared/ScorePanel'
import { Label, Panel } from '../../../shared/SubComponents'
import { Table, TableData } from '../../../shared/Table'

interface Props {
	geographies: GraphQlGeography
	height: number
	workingPop: GraphQlPopulation
	elderly75PlusPop: GraphQlPopulation
	industryDistribution: GraphQlDemographics
	hourlyWages: GraphQlDemographics
	elderlyCareStats: GraphQlCareStats
}

const StaffingMarket: React.FC<Props> = ({
	geographies,
	height,
	workingPop,
	elderly75PlusPop,
	industryDistribution,
	elderlyCareStats,
	hourlyWages,
}) => {
	const { region } = getGeosFromGeographies(geographies)

	const { ca: hwCa, region: hwRegion, country: hwCountry } = getGeoValuesFromDataset(hourlyWages.datasets)

	const caWageIndex = hwCa / hwCountry
	const regionWageIndex = hwRegion / hwCountry

	const countryPersonalCareWage = knightFrank.hourlyWages.residential
	const caPersonalCareWage = caWageIndex * countryPersonalCareWage
	const regionPersonalCareWage = regionWageIndex * countryPersonalCareWage

	const countryCareWage = knightFrank.hourlyWages.nursing
	const caCareWage = caWageIndex * countryCareWage
	const regionCareWage = regionWageIndex * countryCareWage

	const geosWorking = workingPop?.geographies || []
	const geos75Plus = elderly75PlusPop?.geographies || []

	const idx = workingPop.years.length - 1
	const caPop = geosWorking[0].ageRanges[0].values[idx]
	const regionPop = geosWorking[geosWorking.length - 2].ageRanges[0].values[idx]
	// const countryPop = geosWorking[geosWorking.length - 1].ageRanges[0].values[idx]

	const idx75Plus = elderly75PlusPop.years.length - 1

	const caPop75Plus = geos75Plus[0].ageRanges[0].values[idx75Plus]
	const regionPop75Plus = geos75Plus[geosWorking.length - 2].ageRanges[0].values[idx75Plus]
	// const countryPop75Plus = geos75Plus[geosWorking.length - 1].ageRanges[0].values[idx75Plus]

	const caWorkforceShare = industryDistribution.datasets[0].values[7]
	const regionWorkforceShare = industryDistribution.datasets[industryDistribution.datasets.length - 2].values[7]
	// const countryWorkforceShare = datasets[datasets.length - 1].values[7]

	const caWorkforce = (caPop * caWorkforceShare) / 100
	const regionWorkforce = (regionPop * regionWorkforceShare) / 100
	// const countryWorkforce = (countryPop * countryWorkforceShare) / 100

	const caHealthPerElderly = (caWorkforce / caPop75Plus) * 100
	const regionHealthPerElderly = (regionWorkforce / regionPop75Plus) * 100
	// const countryHealthPerElderly = (countryWorkforce / countryPop75Plus) * 100

	const caCareHomeWorkforce = elderlyCareStats.stats[0].careHomeWorkers
	const regionCareHomeWorkforce = elderlyCareStats.stats[elderlyCareStats.stats.length - 2].careHomeWorkers
	// const countryCareHomeWorkforce = stats.stats[stats.stats.length - 1].careHomeWorkers

	const caCareHomeWorkforcePerElderly = (caCareHomeWorkforce / caPop75Plus) * 100
	const regionCareHomeWorkforcePerElderly = (regionCareHomeWorkforce / regionPop75Plus) * 100
	// const countryCareHomeWorkforcePerElderly = (countryCareHomeWorkforce / countryPop75Plus) * 100

	const caDomWorkforce = elderlyCareStats.stats[0].domiciliaryCareWorkers
	const regionDomWorkforce = elderlyCareStats.stats[elderlyCareStats.stats.length - 2].domiciliaryCareWorkers
	// const countryDomWorkforce = stats.stats[stats.stats.length - 1].domiciliaryCareWorkers

	const caDomWorkforcePerElderly = (caDomWorkforce / caPop75Plus) * 100
	const regionDomWorkforcePerElderly = (regionDomWorkforce / regionPop75Plus) * 100
	// const countryDomWorkforcePerElderly = (countryDomWorkforce / countryPop75Plus) * 100

	const caTotalCareWorkforce = caCareHomeWorkforce + caDomWorkforce
	const regionTotalCareWorkforce = regionCareHomeWorkforce + regionDomWorkforce
	// const countryTotalCareWorkforce = countryCareHomeWorkforce + countryDomWorkforce

	const caCareWorkersPerElderly = (caTotalCareWorkforce / caPop75Plus) * 100
	const regionCareWorkersPerElderly = (regionTotalCareWorkforce / regionPop75Plus) * 100
	// const countryCareWorkersPerElderly = (countryTotalCareWorkforce / countryPop75Plus) * 100

	const countryResidentialStaffingCost = knightFrank.annualStaffingCostPerBed.residential
	const caResidentialStaffingCost = caWageIndex * countryResidentialStaffingCost
	const regionResidentialStaffingCost = regionWageIndex * countryResidentialStaffingCost

	const countryNursingStaffingCost = knightFrank.annualStaffingCostPerBed.nursing
	const caNursingStaffingCost = caWageIndex * countryNursingStaffingCost
	const regionNursingStaffingCost = regionWageIndex * countryNursingStaffingCost

	const caTotalWorkforce = caCareHomeWorkforce + caDomWorkforce
	const caCareHomeForceShare = (caCareHomeWorkforce / caTotalWorkforce) * 100
	const caDomForceShare = (caDomWorkforce / caTotalWorkforce) * 100

	const regionTotalWorkforce = regionCareHomeWorkforce + regionDomWorkforce
	const regionCareHomeForceShare = (regionCareHomeWorkforce / regionTotalWorkforce) * 100
	const regionDomForceShare = (regionDomWorkforce / regionTotalWorkforce) * 100

	const staffingHeaders = ['', '5-Mile Catchment', { value: region.name, color: themeColors.grey }]

	const staffingData: TableData = [
		[
			[
				'Healthcare Workers',
				{ value: formatNumber(caWorkforce, 0, 0), bold: true },
				formatNumber(regionWorkforce, 0, 0),
				// formatNumber(countryWorkforce, 0, 0),
			],
			[
				{ value: 'Per 100 Elderly', bold: false },
				{ value: formatNumber(caHealthPerElderly, 0, 0) },
				formatNumber(regionHealthPerElderly, 0, 0),
				// formatNumber(countryHealthPerElderly, 0, 0),
			],
		],
		[
			[
				'Care Home Workforce (incl. Nurses)',
				{ value: formatNumber(caCareHomeWorkforce), bold: true },
				formatNumber(regionCareHomeWorkforce),
				// formatNumber(countryCareHomeWorkforce),
			],
			[
				{ value: 'Per 100 Elderly', bold: false },
				{ value: formatNumber(caCareHomeWorkforcePerElderly, 0, 0) },
				formatNumber(regionCareHomeWorkforcePerElderly, 0, 0),
				// formatNumber(countryCareHomeWorkforcePerElderly, 0, 0),
			],
			[
				'Domicilliary Workforce',
				{ value: formatNumber(caDomWorkforce), bold: true },
				formatNumber(regionDomWorkforce),
				// formatNumber(countryDomWorkforce),
			],
			[
				{ value: 'Per 100 Elderly', bold: false },
				{ value: formatNumber(caDomWorkforcePerElderly, 0, 0), bold: false },
				formatNumber(regionDomWorkforcePerElderly, 0, 0),
				// formatNumber(countryDomWorkforcePerElderly, 0, 0),
			],
			[
				'Total Workforce',
				{ value: formatNumber(caTotalCareWorkforce), bold: true },
				formatNumber(regionTotalCareWorkforce),
				// formatNumber(countryTotalCareWorkforce),
			],
			[
				{ value: 'Per 100 Elderly', bold: false },
				{ value: formatNumber(caCareWorkersPerElderly, 0, 0), bold: false },
				formatNumber(regionCareWorkersPerElderly, 0, 0),
				// formatNumber(countryCareWorkersPerElderly, 0, 0),
			],
		],
		[
			[
				'Care Wage',
				{ value: formatCurrency(caPersonalCareWage), bold: true },
				formatCurrency(regionPersonalCareWage),
				// formatCurrency(countryPersonalCareWage),
			],
			[
				'Nursing Wage',
				{ value: formatCurrency(caCareWage), bold: true },
				formatCurrency(regionCareWage),
				// formatCurrency(countryCareWage),
			],
		],
		[
			[
				{
					value: 'Staffing cost per resident - Residential ',
					backgroundColor: themeColors.canvas,
					whiteSpace: 'nowrap',
				},
				{ value: formatCurrency(caResidentialStaffingCost, 0), bold: true, backgroundColor: themeColors.canvas },
				{ value: formatCurrency(regionResidentialStaffingCost, 0), backgroundColor: themeColors.canvas },
				// { value: formatCurrency(countryResidentialStaffingCost, 0), backgroundColor: themeColors.canvas },
			],
			[
				{ value: 'Staffing cost per resident - Nursing ', backgroundColor: themeColors.canvas, whiteSpace: 'nowrap' },
				{ value: formatCurrency(caNursingStaffingCost, 0), bold: true, backgroundColor: themeColors.canvas },
				{ value: formatCurrency(regionNursingStaffingCost, 0), backgroundColor: themeColors.canvas },
				// { value: formatCurrency(countryNursingStaffingCost, 0), backgroundColor: themeColors.canvas },
			],
		],
	]

	const colors = [
		[themeColors.primary, themeColors.info],
		['#ddd', '#eee'],
	]

	const values = [caCareHomeForceShare, caDomForceShare]
	const labels = [
		`${formatPercentage(values[0], 0, 0)} - Care Home Workforce`,
		`${formatPercentage(values[1], 0, 0)} - Domillicary Workforce`,
	]

	const model: Model = {
		labels: [],
		datasets: [
			{
				values: [caCareHomeForceShare, caDomForceShare],
			},
			{
				values: [regionCareHomeForceShare, regionDomForceShare],
			},
		],
	}

	const legend = labels.map((label, index) => (
		<Label key={index} point={6} color={colors[0][index]}>
			{label}
		</Label>
	))

	return (
		<Panel height={height} padding={20}>
			<Label point={9}>Staffing Market</Label>
			<VerticalSpace size="md" />
			<Table headers={staffingHeaders} data={staffingData} footers={['']} />
			<DonutChart model={model} colors={colors} size={125} showCenterLabel={false} />
			<SpaceBetween>{legend.reverse()}</SpaceBetween>
		</Panel>
	)
}
export { StaffingMarket }
