import React from 'react'

import { PieChart } from '../../../shared/charts/PieChart'
import { Model } from '../../../shared/charts/types'
import { CenterAligned, SpaceBetween } from '../../../shared/layout/Alignment'
import { themeColors } from '../../../Theme'
import { formatPercentage } from '../../../utils/formatNumber'
import { Label, Panel } from './SubComponents'

interface DonutChartBaseProps {
	size: number
	colors: string[][]
	showCenterLabel?: boolean
}

interface DonutChartWithValues extends DonutChartBaseProps {
	values: number[]
	model?: never // Ensures `model` is NOT used
}

interface DonutChartWithModel extends DonutChartBaseProps {
	values?: never // Ensures `values` is NOT used
	model: Model
}

// Final type ensures either `values` OR `model` is required
type DonutChartProps = DonutChartWithValues | DonutChartWithModel

const DonutChart: React.FC<DonutChartProps> = ({ size, values, colors, model, showCenterLabel = true }) => {
	const modelToUse = model || {
		labels: [],
		datasets: values.map((value) => ({
			id: formatPercentage(value),
			values: [value, 100 - value],
		})),
	}

	return (
		<div style={{ height: size, position: 'relative' }}>
			<CenterAligned>
				<div style={{ width: size }}>
					<PieChart
						model={modelToUse}
						aspectRatio={1}
						responsive={true}
						showDataLabels={false}
						colors={colors}
						cutout="70%"
					/>
					{showCenterLabel && (
						<div style={{ position: 'absolute', top: '54%', left: '50%', transform: 'translate(-50%, -50%)' }}>
							<Label point={10} color={colors[0][0]}>
								{formatPercentage(modelToUse?.datasets[0].values[0], 0, 0)}
							</Label>
						</div>
					)}
				</div>
			</CenterAligned>
		</div>
	)
}

interface ScorePanelProps {
	values: number[]
	title: string
	labels?: string[]
	colors?: string[][]
}
const ScorePanel: React.FC<ScorePanelProps> = ({ title, values, colors, labels = [] }) => {
	const color = values[0] > 55 ? themeColors.primary : themeColors.yellow
	const defaultColors = [
		[color, '#ddd'],
		[themeColors.grey, '#ddd'],
	]
	const colorsToUse = colors || defaultColors

	return (
		<Panel padding={10} height={156}>
			<div className="pb-2">
				<Label position="absolute">{title}</Label>
			</div>
			<DonutChart values={values} colors={colorsToUse} size={110} />
			<div className="pt-2 ">
				<SpaceBetween>
					{labels.map((label, index) => (
						<Label key={index} point={6} color={colorsToUse[index][0]}>
							{label}
						</Label>
					))}
				</SpaceBetween>
			</div>
		</Panel>
	)
}

export { DonutChart, ScorePanel }
