import graphql from 'babel-plugin-relay/macro'

export const CareStatsByCa = graphql`
	query CareStatsByCaQuery($specialism: Specialism!, $oaCode: String!, $radius: RadiusValues!) {
		careStatsByCa(specialism: $specialism, oaCode: $oaCode, radius: $radius) {
			stats {
				code
				type
				careSupplyRate
				numberOfBeds
				nursingBeds
				residentialBeds
				higherRateAllowance
				careHomeWorkers
				domiciliaryCareWorkers
				occupancyRate
				rating
			}
		}
	}
`
