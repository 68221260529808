import { squareMetersToAcres } from './acres'

function formatNumber(
	number: number,
	minimumFractionDigits: number = 0,
	maximumFractionDigits: number = 2,
	delimiter = true,
): string {
	if (isNaN(number)) {
		return '-'
	}
	if (!delimiter) {
		return `${number.toFixed(maximumFractionDigits)}`
	}
	return number.toLocaleString('en-GB', {
		minimumFractionDigits,
		maximumFractionDigits,
	})
}

function formatCurrency(number: number, maxDecimals: number = 2): string {
	return '£\u00A0' + formatNumber(number, 0, maxDecimals)
}

function formatPercentage(number?: number | null, minimumFractionDigits: number = 0, maxDecimals: number = 2): string {
	if (number === undefined || number === null) {
		return '-'
	}
	return formatNumber(number, minimumFractionDigits, maxDecimals) + '%'
}

function formatMiles(number: number, minimumFractionDigits: number = 0, maximumFractionDigits: number = 2): string {
	return formatNumber(number, minimumFractionDigits, maximumFractionDigits) + ' miles'
}

function formatAcres(number: number, minimumFractionDigits: number = 0, maximumFractionDigits: number = 2): string {
	const acres = squareMetersToAcres(number)
	return formatNumber(acres, minimumFractionDigits, maximumFractionDigits) + ' acres'
}

function formatYear(dateString: string): string {
	const date = new Date(dateString)
	return date.getFullYear().toString()
}

function formatDate(dateString: string): string {
	const date = new Date(dateString)
	return date.toLocaleDateString('en-GB', {
		year: 'numeric',
		month: 'long',
		day: 'numeric',
	})
}

function formatter(prefix: string, suffix: string) {
	return (value: string | number) => {
		const formattedValue = formatNumber(Number(value))
		return `${prefix}${formattedValue}${suffix}`
	}
}
export { formatAcres, formatCurrency, formatDate, formatMiles, formatNumber, formatPercentage, formatter, formatYear }
