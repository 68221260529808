import graphql from 'babel-plugin-relay/macro'

export const CreateProject = graphql`
	mutation CreateProjectMutation($input: CreateProjectInput!) {
		createProject(input: $input) {
			address
			area
			client
			date
			id
			ladCode
			latitude
			longitude
			mapImageUrl
			name
			oaCode
			postalCode
			propertyIds
			reference
			settings
			status
			titleNumbers
			townCity
			userId
		}
	}
`
