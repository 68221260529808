import graphql from 'babel-plugin-relay/macro'

export const ProjectsByUserId = graphql`
	query ProjectsByUserIdQuery($userId: ID!) {
		projectsByUserId(userId: $userId) {
			projects {
				address
				area
				client
				date
				id
				ladCode
				latitude
				longitude
				mapImageUrl
				name
				oaCode
				postalCode
				propertyIds
				reference
				settings
				status
				titleNumbers
				townCity
				userId
				userId
			}
		}
	}
`
