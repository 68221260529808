import React from 'react'

import { milesRadiusValueInInts } from '../../../../../Constants'
import { useMvuData } from '../../../../../providers/MvuDataContext'
import { Col, Container, Row } from '../../../../../shared/layout/Grid'
import { VerticalSpace } from '../../../../../shared/layout/Space'
import { Loading } from '../../../../../shared/Loading'
import { Template } from '../../../shared/Template'
import { StabilisedForecast } from '../executiveSummary/StabilisedForecast'
import { BedFeeAnalysis } from './BedFeeAnalysis'
import { InfoPanel } from './InfoPanel'
import { Migration } from './Migration'
import { Profitability } from './Profitability'
import { StaffingMarket } from './StaffingMarket'

const BusinessAnalysis: React.FC = () => {
	const { project, geographies, radius, population: pop, careStats: cs, demographics: dem, mvu } = useMvuData()
	const radiusInt = milesRadiusValueInInts[radius]
	const regionName = geographies[1].name
	const projectName = `${project.name} Project`

	return (
		<Template
			pageTitle="4. Business Analysis"
			pageSubtitle={`${radiusInt} Mile Radius`}
			project={project}
			reportType="Most Viable Use Case Analysis"
			localAuthority={geographies[0].name}
			region={geographies[1].name}
		>
			<Container size="fluid" removePadding={true}>
				<Row gutterSize={2}>
					<Col count={4}>
						<StaffingMarket
							geographies={geographies}
							workingPop={pop.workingAge}
							elderly75PlusPop={pop.over75}
							elderlyCareStats={cs.elderly}
							hourlyWages={dem.hourlyWage}
							industryDistribution={dem.industryDistribution}
							height={522}
						/>
						<VerticalSpace size="xs" />
						<Migration geographies={geographies} migration={dem.migration} height={134} />
					</Col>

					<Col count={4}>
						<InfoPanel
							project={project}
							geographies={geographies}
							radius={radius}
							height={288}
							mvu={mvu}
							popIn3={pop.over75In3}
							popIn6={pop.over75In6}
							elderlyCareStats={cs.elderly}
							unemployment={dem.unemployment}
							gdhi={dem.gdhi}
							housePrices={dem.housePrices}
							hourlyWages={dem.hourlyWage}
							fees={cs.fees}
							funding={cs.funding}
							migration={dem.migration}
							allowanceAwards={cs.aaAwardTypes}
							dementiaStats={cs.dementiaStats}
						/>
						<VerticalSpace size="xs" />
						<BedFeeAnalysis
							height={368}
							projectName={projectName}
							regionName={regionName}
							careFees={cs.fees}
							careFunding={cs.funding}
							elderlyCareStats={cs.elderly}
						/>
					</Col>

					<Col count={4}>
						<Profitability
							regionName={geographies[1].name}
							projectName={project.name}
							elderlyCareStats={cs.elderly}
							hourlyWages={dem.hourlyWage}
							fees={cs.fees}
							funding={cs.funding}
							height={322}
						/>
						<VerticalSpace size="xs" />
						<StabilisedForecast
							projectBeds={project.settings.numberOfBeds || 0}
							elderlyCareStats={cs.elderly}
							hourlyWages={dem.hourlyWage}
							funding={cs.funding}
							fees={cs.fees}
						/>
					</Col>
				</Row>
			</Container>
		</Template>
	)
}

const Suspended: React.FC = () => {
	return (
		<React.Suspense fallback={<Loading height="80vh" />}>
			<BusinessAnalysis />
		</React.Suspense>
	)
}

export { Suspended as BusinessAnalysis }
