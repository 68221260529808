import React, { useState } from 'react'
import * as i from 'react-feather'

import { RadiusValues } from '../../../../graphql/queries/__generated__/OutputAreasByMileRadiusQuery.graphql'
import { LadHeader } from '../../../../shared/LadHeader'
import { RightAligned, SpaceBetween } from '../../../../shared/layout/Alignment'
import { Col, Row } from '../../../../shared/layout/Grid'
import { HorizontalSpace, VerticalSpace } from '../../../../shared/layout/Space'
import { Panel } from '../../../../shared/Panel'
import { SegmentedButton } from '../../../../shared/SegmentedButton'
import { Option, Select } from '../../../../shared/Select'
import { Switch } from '../../../../shared/Switch'
import { CaQuickFacts } from './CaQuickFacts'
import { Map as MyMap, ViewTypes } from './Map'

interface MapProps {
	oaCode: string
	ladCode: string
	lat: number
	long: number
	radius: RadiusValues
	onChange: (lat: number, long: number, radius: RadiusValues) => void
}

const Explorer: React.FC<MapProps> = ({ oaCode, ladCode, lat, long, radius, onChange }) => {
	const [view, setView] = useState('map')
	const [selectingLatLong, setSelectingLatLong] = useState<boolean>(false)
	const [showingLads, setShowingLads] = useState<boolean>(false)
	const [viewType, setViewType] = useState<ViewTypes>('circle')

	const buttonOpts = [
		{
			children: <i.Map size={14} color="#666" />,
			value: 'map',
			disabled: view === 'map',
		},
		{
			children: <i.List size={14} color="#666" />,
			value: 'list',
			disabled: view === 'list',
		},
	]

	const handleToggleShowingLads = (checked: boolean) => {
		setShowingLads(checked)
	}
	const handleToggleSelectingLatLong = (checked: boolean) => {
		setSelectingLatLong(checked)
	}
	const handleOnMapClick = (lat: string, long: string) => {
		if (
			selectingLatLong &&
			window.confirm(`Do you want to update the location to latitude: ${lat}, longitude: ${long}?`)
		) {
			onChange(parseFloat(lat), parseFloat(long), radius)
			setSelectingLatLong(false)
		}
	}

	const handleButtonClick = (value: string) => {
		setView(value)
	}

	const handleOnViewChange = (value: string) => {
		setViewType(value as ViewTypes)
	}

	const options: Option[] = [
		{ value: 'circle', label: 'Circle' },
		{ value: 'oas', label: 'All OAs' },
		{ value: 'ca', label: 'Catchment Area' },
	]

	return (
		<>
			<Row>
				<Col count={3}>
					<Row>
						<Col count={12}>
							<Panel padding={32}>
								<LadHeader ladCode={ladCode} />
							</Panel>
						</Col>
					</Row>
					<VerticalSpace size="sm" />
					<CaQuickFacts oaCode={oaCode} radius={radius} ladCode={ladCode} />
				</Col>
				<Col count={9}>
					<Panel padding={8} height="auto">
						<SpaceBetween>
							<SegmentedButton options={buttonOpts} onClick={handleButtonClick} />
							<RightAligned>
								<Switch on={showingLads} onClick={handleToggleShowingLads} label="Show Local Authorities" />
								<HorizontalSpace size="sm" />
								<Switch on={selectingLatLong} onClick={handleToggleSelectingLatLong} label="Select new location" />
								<HorizontalSpace size="sm" />
								<Select value={viewType} onChange={handleOnViewChange} options={options} />
							</RightAligned>
						</SpaceBetween>
						<VerticalSpace size="xs" />
						<MyMap
							lat={lat}
							long={long}
							radius={radius}
							height="80vh"
							viewType={viewType}
							showingLads={showingLads}
							selectingLatLong={selectingLatLong}
							onMapClick={handleOnMapClick}
						/>
					</Panel>
				</Col>
			</Row>
		</>
	)
}

export { Explorer }
