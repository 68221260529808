import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { getUserId } from '../../../Config'
import { RadiusValues } from '../../../graphql/queries/__generated__/CareStatsByCaQuery.graphql'
import { ProjectsByUserIdQuery } from '../../../graphql/queries/__generated__/ProjectsByUserIdQuery.graphql'
import { ProjectsByUserId } from '../../../graphql/queries/ProjectsByUserId'
import { createProjectModel } from '../../../models/project'
import { MvuDataProvider } from '../../../providers/MvuDataProvider'
import { Loading } from '../../../shared/Loading'
import { Pdf } from '../shared/Pdf'
import { Panel } from '../shared/SubComponents'
import { Appendix } from './pages/Appendix'
import { BusinessAnalysis } from './pages/businessAnalysis/BusinessAnalysis'
import { Competition } from './pages/competition/Competition'
import { ExecutiveSummary } from './pages/executiveSummary/ExecutiveSummary'
import { MarketAnalysis } from './pages/marketAnalysis/MarketAnalysis'
import { SiteInfo } from './pages/siteInfo/SiteInfo'
import { TitlePage } from './pages/TitlePage'

const Mvu: React.FC = () => {
	return (
		<>
			<Pdf backgroundColor="#ffffff">
				<TitlePage />
			</Pdf>
			<Pdf>
				<ExecutiveSummary />
			</Pdf>
			<Pdf>
				<MarketAnalysis />
			</Pdf>
			<Pdf>
				<Competition />
			</Pdf>
			<Pdf>
				<BusinessAnalysis />
			</Pdf>
			<Pdf>
				<SiteInfo />
			</Pdf>
			<Pdf backgroundColor="#ffffff">
				<Appendix />
			</Pdf>
		</>
	)
}

interface MvuProps {
	reference: string
	radius: RadiusValues
}

const MvuWithData: React.FC<MvuProps> = (props) => {
	const { projectsByUserId } = useLazyLoadQuery<ProjectsByUserIdQuery>(ProjectsByUserId, { userId: getUserId() })
	const graphQlProjects = projectsByUserId?.projects || []
	const graphQlProject = graphQlProjects.find((p) => p.reference === props.reference)

	if (!graphQlProject) {
		return <Panel>{`Could not find project: ${props.reference}`}</Panel>
	}

	const project = createProjectModel(graphQlProject)

	return (
		<MvuDataProvider project={project} radius={props.radius}>
			<Mvu />
		</MvuDataProvider>
	)
}

const Suspended: React.FC<MvuProps> = (props) => {
	return (
		<React.Suspense fallback={<Loading height="80vh" />}>
			<MvuWithData {...props} />
		</React.Suspense>
	)
}

export { Suspended as Mvu }
