import React from 'react'
import { Edit, PlusCircle } from 'react-feather'
import { useLazyLoadQuery } from 'react-relay'
import { useNavigate } from 'react-router-dom'

import { getUserId } from '../../Config'
import { GeographiesByCodeQuery } from '../../graphql/queries/__generated__/GeographiesByCodeQuery.graphql'
import { ProjectsByUserIdQuery } from '../../graphql/queries/__generated__/ProjectsByUserIdQuery.graphql'
import { GeographiesByCode } from '../../graphql/queries/GeographiesByCode'
import { ProjectsByUserId } from '../../graphql/queries/ProjectsByUserId'
import { Page } from '../../layout/page/Page'
import { createProjectModel, Project } from '../../models/project'
import { Badge } from '../../shared/Badge'
import { SecondaryButton } from '../../shared/Button'
import { BackgroundImage } from '../../shared/Image'
import { LeftAligned, SpaceBetween } from '../../shared/layout/Alignment'
import { Col, Container, Row } from '../../shared/layout/Grid'
import { HorizontalSpace, VerticalSpace } from '../../shared/layout/Space'
import { Loading } from '../../shared/Loading'
import { Panel } from '../../shared/Panel'
import { Heading, PageTitle, Paragraph } from '../../shared/Text'
import { createStaticImageUrl } from '../../utils/staticMap'

const Projects: React.FC = () => {
	const { projectsByUserId } = useLazyLoadQuery<ProjectsByUserIdQuery>(ProjectsByUserId, { userId: getUserId() })
	const graphQlProjects = projectsByUserId?.projects || []
	const projects = graphQlProjects.map(createProjectModel)
	const projectList = projects.map((project) => <ProjectItem key={project.reference} project={project} />)

	const navigate = useNavigate()
	const goToCreateProject = () => {
		navigate(`/projects/create`)
	}

	return (
		<Page title="Projects">
			<Container>
				<Row>
					<Col count={2}></Col>
					<Col count={8}>
						<SpaceBetween>
							<PageTitle>Projects</PageTitle>
							<SecondaryButton onClick={goToCreateProject}>
								<PlusCircle size={14} color="#666" />
								&nbsp;Create project
							</SecondaryButton>
						</SpaceBetween>
						<div className="list-group">{projectList}</div>
					</Col>
					<Col count={2}></Col>
				</Row>
			</Container>
		</Page>
	)
}

interface ProjectItemProps {
	project: Project
}

const ProjectItem: React.FC<ProjectItemProps> = ({ project }) => {
	const { geographiesByCode: geos } = useLazyLoadQuery<GeographiesByCodeQuery>(GeographiesByCode, {
		code: project.ladCode,
	})
	const ladName = geos[0].name
	const regionName = geos[1].name

	const navigate = useNavigate()
	const goToReport = (id: string) => {
		navigate(`/report/${id}`)
	}
	const goToDataTable = (id: string) => {
		navigate(`/data/${id}`)
	}
	const goToUpdate = (id: string) => {
		navigate(`/projects/update/${id}`)
	}
	const mapUrl = createStaticImageUrl(project.mapImageUrl, 200, 200)

	const numberOfBeds = project.settings.numberOfBeds || 0
	const numberOfExtraCareUnits = project.settings.numberOfExtraCareUnits || 0

	const badgeType =
		project.status === 'approved'
			? 'success'
			: project.status === 'planning'
				? 'info'
				: project.status === 'pipeline'
					? 'dark'
					: 'warning'

	return (
		<>
			<Panel>
				<Row>
					<Col count={12}>
						<LeftAligned>
							<BackgroundImage url={mapUrl} width={240} height={150} />
							<HorizontalSpace size="sm" />
							<div style={{ width: '100%' }}>
								<SpaceBetween>
									<Heading level={4}>{project.name}</Heading>
									<HorizontalSpace size="xs" />
									<Badge type={badgeType}>{project.status}</Badge>
								</SpaceBetween>
								<Paragraph>
									{project.address}, {project.townCity}, {project.postalCode}
								</Paragraph>
								<Paragraph>
									{ladName}, {regionName}
								</Paragraph>
								<Paragraph>{project.titleNumbers.join(', ')}</Paragraph>
								<VerticalSpace size="xs" />
								<Paragraph>
									{`${numberOfBeds} beds`} &bull; {`${numberOfExtraCareUnits} Extra Care Apartments`}
								</Paragraph>

								<VerticalSpace size="sm" />
								<LeftAligned>
									<SecondaryButton onClick={() => goToReport(project.reference)}>MVU</SecondaryButton>
									<HorizontalSpace size="xs" />
									<SecondaryButton onClick={() => goToDataTable(project.reference)}>Data table</SecondaryButton>
									<HorizontalSpace size="xs" />
									<SecondaryButton onClick={() => goToUpdate(project.reference)}>
										<Edit size={14} color="#666" />
										&nbsp; Edit
									</SecondaryButton>
								</LeftAligned>
							</div>
						</LeftAligned>
					</Col>
				</Row>
			</Panel>
			<VerticalSpace size="sm" />
		</>
	)
}

const Suspended: React.FC = () => {
	return (
		<React.Suspense fallback={<Loading height="80vh" />}>
			<Projects />
		</React.Suspense>
	)
}

export { Suspended as Projects }
