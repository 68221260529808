import React from 'react'

import { GraphQlDemographics } from '../../../../../models/demographics'
import { GraphQlGeography } from '../../../../../models/geography'
import { themeColors } from '../../../../../Theme'
import { formatPercentage } from '../../../../../utils/formatNumber'
import { getGeosFromGeographies } from '../../../../../utils/getGeoValuesFromDataset'
import { Panel } from '../../../shared/SubComponents'
import { Table, TableData } from '../../../shared/Table'

interface Props {
	geographies: GraphQlGeography
	migration: GraphQlDemographics
	height: number
}

const Migration: React.FC<Props> = ({ migration, geographies, height }) => {
	const { region } = getGeosFromGeographies(geographies)

	const { datasets } = migration

	const caDomestic = datasets[0].values[0]
	const regionDomestic = datasets[datasets.length - 2].values[0]

	const caInternational = datasets[0].values[1]
	const regionInternational = datasets[datasets.length - 2].values[1]

	const caTotal = caDomestic + caInternational
	const regionTotal = regionDomestic + regionInternational

	const migrantHeaders = [
		{ value: 'Migration', point: 9 },
		'5-Mile Catchment',
		{ value: region.name, color: themeColors.grey },
	]

	const migrantData: TableData = [
		[
			['Domestic', { value: formatPercentage(caDomestic), bold: true }, formatPercentage(regionDomestic)],
			[
				'International',
				{ value: formatPercentage(caInternational), bold: true },
				formatPercentage(regionInternational),
			],
		],
		[[{ value: 'Total' }, { value: formatPercentage(caTotal), bold: false }, { value: formatPercentage(regionTotal) }]],
	]

	return (
		<Panel height={height} padding={20}>
			<Table headers={migrantHeaders} data={migrantData} footers={['']} />
		</Panel>
	)
}

export { Migration }
