import graphql from 'babel-plugin-relay/macro'

export const CareFeeRatesByLadCode = graphql`
	query CareFeeRatesByLadCodeQuery($ladCode: String!) {
		careFeeRatesByLadCode(ladCode: $ladCode) {
			...CareFeesQuickFactFragment
			...CareFeesTableFragment
		}
	}
`
