import { useAuth0 } from '@auth0/auth0-react' // Import the Auth0 hook
import React, { Suspense } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'

import { defaultCa } from '../../Constants'
import { RadiusValues } from '../../graphql/queries/__generated__/HourlyWagesByCaQuery.graphql'
import { LaSelect } from '../../shared/LaSelect/LaSelect'
import { LatLongRadius } from '../../shared/LatLongRadius'
import { CenterAligned, LeftAligned, RightAligned } from '../../shared/layout/Alignment'
import { HorizontalSpace } from '../../shared/layout/Space'
import { EnvBadge } from './EnvBadge'
import { SearchSelect } from './SearchSelect'

interface NavbarProps {
	ladCode?: string
}

type RouteParams = {
	latLong?: string
	radius?: RadiusValues
}

const Navbar: React.FC<NavbarProps> = ({ ladCode }) => {
	const { latLong = '', radius = defaultCa.radius } = useParams<RouteParams>() // Get lad from the route

	const latLongArray = latLong.split(',')
	const lat = parseFloat(latLongArray[0]) || defaultCa.lat
	const long = parseFloat(latLongArray[1]) || defaultCa.long

	const navigate = useNavigate()
	const { user, isAuthenticated, loginWithRedirect } = useAuth0() // Destructure user and auth functions

	React.useEffect(() => {
		const handleClickOutside = () => {
			const navbarToggler = document.querySelector('.navbar-toggler') as HTMLElement
			if (navbarToggler && navbarToggler.getAttribute('aria-expanded') === 'true') {
				setTimeout(() => {
					navbarToggler.click()
				}, 300)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [])

	const pagePath = window.location.pathname.split('/')[1]

	const handleSelectChange = (selectedValue: string) => {
		const page = ['report'].includes(pagePath) ? pagePath : 'lads'

		navigate(`/${page}/${selectedValue}`)
	}

	const handleGoClick = (lat: number, long: number, radius: RadiusValues) => {
		navigate(`/ca/${lat},${long}/${radius}`)
	}

	const selectedLadCode = ladCode

	return (
		<nav className="navbar fixed-top navbar-light" style={{ backgroundColor: 'white', borderBottom: '1px solid #eee' }}>
			<div className="container-fluid">
				<div style={{ width: '20vw' }}>
					<LeftAligned>
						<a href="/">
							<img src={`${process.env.PUBLIC_URL}/img/propmatch.svg`} alt="Propmatch" height={30} />
						</a>
					</LeftAligned>
				</div>

				<Suspense>
					<CenterAligned>
						<SearchSelect pagePath={pagePath} />
						<HorizontalSpace size="xs" />
						{pagePath != 'ca' && <LaSelect onChange={handleSelectChange} value={selectedLadCode} />}
						{pagePath == 'ca' && <LatLongRadius lat={lat} long={long} radius={radius} onGoClick={handleGoClick} />}
					</CenterAligned>
				</Suspense>

				<div style={{ width: '20vw' }}>
					<RightAligned>
						<EnvBadge />
						<button
							className="navbar-toggler"
							type="button"
							data-bs-toggle="collapse"
							data-bs-target="#navbarNav"
							aria-controls="navbarNav"
							aria-expanded="false"
							aria-label="Toggle navigation"
						>
							<span className="navbar-toggler-icon"></span>
						</button>

						<div className="collapse navbar-collapse justify-content-end" id="navbarNav">
							<ul className="navbar-nav">
								<li className="nav-item">
									<Link className="nav-link" to="/projects">
										Projects
									</Link>
								</li>
								<li className="nav-item">
									<Link className="nav-link" to="/">
										Explorer
									</Link>
								</li>
								<li className="nav-item">
									<Link className="nav-link" to="/lads">
										Local Authority List
									</Link>
								</li>
								<li className="nav-item">
									<Link className="nav-link" to="/groups">
										Care Operator Groups
									</Link>
								</li>
								<li className="nav-item">
									<Link className="nav-link" to="/operators">
										Care Operators
									</Link>
								</li>
								<li className="nav-item">
									<Link className="nav-link" to="/sites">
										Care Home List
									</Link>
								</li>
							</ul>
							{isAuthenticated ? (
								<ul className="navbar-nav">
									<li className="nav-item">
										<Link className="nav-link" to="/me">
											<img
												src={user?.picture}
												alt="Profile"
												style={{
													borderRadius: '50%',
													width: '40px',
													height: '40px',
													cursor: 'pointer',
													transition: 'transform 0.2s',
												}}
												onMouseOver={(e) => (e.currentTarget.style.transform = 'scale(1.1)')}
												onMouseOut={(e) => (e.currentTarget.style.transform = 'scale(1)')}
											/>
										</Link>
									</li>
								</ul>
							) : (
								<button className="btn btn-outline-primary" onClick={() => loginWithRedirect()}>
									Login
								</button>
							)}
						</div>
					</RightAligned>
				</div>
			</div>
		</nav>
	)
}

export { Navbar }
