import React from 'react'

import { GraphQlAaAwardTypes } from '../../../../../models/aaAwardTypes'
import { GraphQlCareStats } from '../../../../../models/careStats'
import { GraphQlPopulation } from '../../../../../models/population'
import { Model } from '../../../../../shared/charts/types'
import { VerticalSpace } from '../../../../../shared/layout/Space'
import { getGeoValuesFromDataset } from '../../../../../utils/getGeoValuesFromDataset'
import { SimpleBarChart } from '../../../shared/SimpleBarChart'
import { Label, Panel } from '../../../shared/SubComponents'

interface Props {
	height: number
	over75In3: GraphQlPopulation
	over75In6: GraphQlPopulation
	elderlyCareStats: GraphQlCareStats
	allowanceAwards: GraphQlAaAwardTypes
}

const SurplusBedDemand: React.FC<Props> = ({ over75In3, over75In6, elderlyCareStats, allowanceAwards, height }) => {
	const geosIn3 = over75In3?.geographies || []
	const geosIn6 = over75In6?.geographies || []
	const caPop75_Now = geosIn3[0].ageRanges[0].values[0]
	const caPop75_In3 = geosIn3[0].ageRanges[0].values[1]
	const caPop75_In6 = geosIn6[0].ageRanges[0].values[1]

	const caBeds = elderlyCareStats.stats[0].numberOfBeds
	const { ca: caH } = getGeoValuesFromDataset(allowanceAwards.higherRate)

	const caHRPopRatio = caH / caPop75_Now

	const caHigherRateShortage_Now = Math.round(caBeds - caH)
	const caHigherRateShortage_In3 = Math.round(caBeds - caHRPopRatio * caPop75_In3)
	const caHigherRateShortage_In6 = Math.round(caBeds - caHRPopRatio * caPop75_In6)

	const labels = [
		...over75In3.years.map((year) => year.toString()),
		over75In6.years[over75In6.years.length - 1].toString(),
	]

	const model: Model = {
		labels,
		datasets: [
			{
				values: [
					Math.abs(caHigherRateShortage_Now),
					Math.abs(caHigherRateShortage_In3),
					Math.abs(caHigherRateShortage_In6),
				],
			},
		],
	}

	return (
		<Panel height={height} padding={20}>
			<Label point={9}>Surplus Bed Demand</Label>
			<VerticalSpace size="lg" />
			<SimpleBarChart model={model} barThickness={50} aspectRatio={1.1} />
		</Panel>
	)
}

export { SurplusBedDemand }
