import React from 'react'

import { RadiusValues } from '../../../../../graphql/queries/__generated__/CareStatsByCaQuery.graphql'
import { GraphQlAaAwardTypes } from '../../../../../models/aaAwardTypes'
import { GraphQlCareFunding } from '../../../../../models/careFunding'
import { GraphQlCareStats } from '../../../../../models/careStats'
import { GraphqlDementiaStats } from '../../../../../models/dementiaStats'
import { GraphQlDemographics } from '../../../../../models/demographics'
import { GraphQlGeography } from '../../../../../models/geography'
import { GraphQlPopulation } from '../../../../../models/population'
import { Project } from '../../../../../models/project'
import { calculateMvuSummary } from '../../../../../utils/calculateMvuSummary'
import { SummaryPanel } from '../../../shared/SummaryPanel'

interface InfoPanelProps {
	project: Project
	geographies: GraphQlGeography
	radius: RadiusValues
	height: number
	mvu: string
	popIn3: GraphQlPopulation
	popIn6: GraphQlPopulation
	elderlyCareStats: GraphQlCareStats
	unemployment: GraphQlDemographics
	housePrices: GraphQlDemographics
	gdhi: GraphQlDemographics
	hourlyWages: GraphQlDemographics
	fees: GraphQlDemographics
	funding: GraphQlCareFunding
	allowanceAwards: GraphQlAaAwardTypes
	migration: GraphQlDemographics
	dementiaStats: GraphqlDementiaStats
}

const InfoPanel: React.FC<InfoPanelProps> = ({
	project,
	geographies,
	height,
	mvu,
	popIn3,
	popIn6,
	elderlyCareStats,
	unemployment,
	housePrices,
	hourlyWages,
	gdhi,
	fees,
	funding,
	allowanceAwards,
	migration,
	dementiaStats,
}) => {
	const localAuthorityName = geographies[0].name
	const regionName = geographies[1].name
	const numberOfBeds = project.settings.numberOfBeds || 0

	const promptData = calculateMvuSummary({
		numberOfBeds,
		popIn3,
		popIn6,
		elderlyCareStats,
		unemployment,
		housePrices,
		hourlyWages,
		gdhi,
		fees,
		funding,
		allowanceAwards,
		migration,
		dementiaStats,
	})

	return (
		<SummaryPanel
			title="Summary"
			prompt={`

Analyse the following data as a base for an executive summary on the prospects of our care home project ${project.name}:
Please output a professional executive summary style summary (dont start with a title or executive summary:)
of a STRICT length between 800 to max 940 characters (without line breaks), it is never allowed to succeed 940 characters!
which means u need to be short and precise only using the most crucial information.
You are addressing potential care home operators and local planning authorities who may invest in or partner with this project.
Base your reasoning on the most crucial insights of the data ie quoting only the most important data points in the order of the data listed below.

Use 2-3 paragraphs with line breaks to structure the whole summary! Ensure the entire text remains within the 940 characters maximum! 
When quoting numbers round to 2 decimals if needed or/and quote big numbers like £1,331,796.21 as £1.3M to be efficient with character space and for margins add % as suffix. 
Make sure it is not cut off in the middle of a sentence!

**Location & Context**  
• Property  ${project.name}, in local authority ${localAuthorityName} in ${regionName} (region). 
• Our comparative market demand scoring system identifies **${mvu}** as most beneficial to the local community and financially viable.

**Economic Indicators**  
• CA Gross Disposable Income: £${promptData.caGDHI}; Region: £${promptData.regionGDHI}  
• Unemployment Rate: CA: ${promptData.caUnemployment}%, Region: ${promptData.regionUnemployment}%

**Elderly Population (CA vs. Region)**  
• 75+ Population (2024): CA: ${promptData.caPop75_2024},
• 75+ Population (2027): CA: ${promptData.caPop75_2027}, 
• 75+ Population (2030): CA: ${promptData.caPop75_2030}, 

**Elderly Care Demand & Forecast (CA)**  
• Full-time Care Demand 2024: ${promptData.caDemand_2024}; Bed Shortage 2024: ${promptData.caBedShortage_2024}  
• Demand 2027: ${promptData.caDemand_2027}; Bed Shortage 2027: ${promptData.caBedShortage_2027}  
• Bed Shortage 2030: ${promptData.caBedShortage_2030}

**Staffing & Wages**  
• Total Care Workers per 100 Elderly: CA: ${promptData.caTotalCareWorkforcePerElderly}, Region: ${promptData.regionTotalCareWorkforcePerElderly}  
• Personal Care Wage (hourly): CA: £${promptData.caPersonalCareWage}, Region: £${promptData.regionPersonalCareWage} high care wages mean it is easier to attract and retain staff also from adjacent areas while lower wages imply better margins or room to offer higher wages to attract staff.

**Weekly Bed Fee Analysis**  
Expected Average Residential Fee: £${promptData.projectPrivateFeeResidential} uplifted by Project Fee Multiplier of ${promptData.feeMultiplier} due to expected CQC rating of 83% for our care home compared to average CQC rating in the 5 mile radius of ${promptData.caRating}% allowing for an above average fee for our care home.

Conclude with the project's EBITDARM margin % and valuation.
**Business Forecast for a ${numberOfBeds} beds care home**  
• EBITDARM Margin: ${promptData.projectEBITDARMMarginMixedHome}%)  
• Valuation: £${promptData.projectValuation}  highlight this as a key metric for the project

Use line breaks for better readability and structure but
MAKE SURE THAT YOUR SUMMARY DOES NOT EXCEEDED THE MAX LENGTH OF 940 CHARACTERS!

`}
			cacheKey={`${project.id}_exec_58`}
			height={height}
		/>
	)
}

export { InfoPanel }
