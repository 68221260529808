import * as turf from '@turf/turf'
import React from 'react'

import { themeColors } from '../../../../../Theme'
import { calculateDistance } from '../../../../../utils/calculateDistance'
import { calculateMedian } from '../../../../../utils/calculateMedian'
import { formatNumber, formatPercentage, formatYear } from '../../../../../utils/formatNumber'
import { calculateRating, filterEldery } from '../../../../../utils/sites'
import { CompetitionTableFragment$data } from '../../../shared/__generated__/CompetitionTableFragment.graphql'
import { Panel } from '../../../shared/SubComponents'
import { Table, TableCell, TableData } from '../../../shared/Table'

interface CompetitionPanelProps {
	latitude: number
	longitude: number
	sites: CompetitionTableFragment$data
}

const CompetitionPanel: React.FC<CompetitionPanelProps> = ({ latitude, longitude, sites }) => {
	const elderlySites = [...filterEldery(sites)]

	const sortedSites = elderlySites.sort((a: (typeof elderlySites)[0], b: (typeof elderlySites)[0]) => {
		const fromA = turf.point([a.onspdLatitude!, a.onspdLongitude!])
		const fromB = turf.point([b.onspdLatitude!, b.onspdLongitude!])
		const to = turf.point([latitude, longitude])

		const distanceA = calculateDistance(fromA, to)
		const distanceB = calculateDistance(fromB, to)

		return distanceA - distanceB
	})

	const formattedSites: (string | TableCell)[][] = sortedSites.map((site) => {
		const from = turf.point([site.onspdLatitude!, site.onspdLongitude!])
		const to = turf.point([latitude, longitude])
		const beds = site.numberOfBeds ? formatNumber(site.numberOfBeds) : '-'

		const operatorName = site.operatorName || '-'
		const serviceTypes = site.serviceTypes ? site.serviceTypes.replace('homes', '').replace(',', ', ') : '-'
		const rating = site.currentRatings?.overall?.rating || '-'
		const color = themeColors.colorPalette[3 - calculateRating(rating)]

		return [
			site.name,
			{ value: formatNumber(calculateDistance(from, to)), textAlign: 'center' },
			{ value: operatorName, textAlign: 'left' },
			{ value: formatYear(site.registrationDate) || '-', textAlign: 'center' },
			{ value: serviceTypes, textAlign: 'center' },
			{ value: beds, textAlign: 'center' },
			{ value: rating, textAlign: 'center', color, bold: true },
		]
	})

	const limitedSites = formattedSites.slice(0, 22)

	if (formattedSites.length !== limitedSites.length) {
		limitedSites.push([
			{ value: '...', color: themeColors.grey, textAlign: 'center' },
			{ value: '...', color: themeColors.grey, textAlign: 'center' },
			{ value: '...', color: themeColors.grey, textAlign: 'center' },
			{ value: '...', color: themeColors.grey, textAlign: 'center' },
			{ value: '...', color: themeColors.grey, textAlign: 'center' },
			{ value: '...', color: themeColors.grey, textAlign: 'center' },
			{ value: '...', color: themeColors.grey, textAlign: 'center' },
		])
	}

	const headers: (string | TableCell)[] = [
		{ value: 'Name', color: themeColors.grey },
		{ value: 'Distance (miles)', textAlign: 'center', color: themeColors.grey },
		{ value: 'Operator', textAlign: 'left', color: themeColors.grey },
		{ value: 'Registration Date', textAlign: 'center', color: themeColors.grey },
		{ value: 'Type', textAlign: 'center', color: themeColors.grey },
		{ value: 'Beds', textAlign: 'center', color: themeColors.grey },
		{ value: 'CQC Rating', textAlign: 'center', color: themeColors.grey },
	]

	const footerHeaders: (string | TableCell)[][] = [
		[
			{ value: 'Summary', textAlign: 'left', color: themeColors.grey, bold: true },
			{ value: 'Closest', textAlign: 'center', color: themeColors.grey, bold: true },
			{ value: 'Count', textAlign: 'left', color: themeColors.grey, bold: true },
			{ value: 'Median', textAlign: 'center', color: themeColors.grey, bold: true },
			{ value: 'Count', textAlign: 'center', color: themeColors.grey, bold: true },
			{ value: 'Median', textAlign: 'center', color: themeColors.grey, bold: true },
			{ value: 'Average', textAlign: 'center', color: themeColors.grey, bold: true },
		],
	]

	const residentialSites = elderlySites.filter((site) => site.serviceTypes?.includes('Residential homes'))
	const nursingSites = elderlySites.filter((site) => site.serviceTypes?.includes('Nursing homes'))

	const residentialCount = residentialSites.length
	const nursingCount = nursingSites.length
	const totalCount = elderlySites.length

	/*
	const bedReducer = (acc: number, site: (typeof sites)[0]): number => acc + (site.numberOfBeds || 0)
	const residentialBeds = residentialSites.reduce(bedReducer, 0)
	const nursingBeds = nursingSites.reduce(bedReducer, 0)
	const totalBeds = elderlySites.reduce(bedReducer, 0)
	*/

	const totalBedsMedian = calculateMedian(elderlySites.map((site) => site.numberOfBeds || 0))
	const residentialBedsMedian = calculateMedian(residentialSites.map((site) => site.numberOfBeds || 0))
	const nursingBedsMedian = calculateMedian(nursingSites.map((site) => site.numberOfBeds || 0))

	/*const yearReducer = (acc: number, site: (typeof sites)[0]): number => {
		if (!site.registrationDate) return acc
		const year = formatYear(site.registrationDate)
		acc += parseInt(year, 10)

		return acc
	}
	*/

	const residentialMedianYear = calculateMedian(
		residentialSites.map((site) => parseInt(formatYear(site.registrationDate), 10)),
	)
	const nursingMedianYear = calculateMedian(nursingSites.map((site) => parseInt(formatYear(site.registrationDate), 10)))
	const totalMedianYear = calculateMedian(elderlySites.map((site) => parseInt(formatYear(site.registrationDate), 10)))

	const closestNursing = nursingSites[0]
	const closestResidential = residentialSites[0]
	const closestSite = elderlySites[0]

	const to = turf.point([latitude, longitude])
	const closestNursingDistance = calculateDistance(
		turf.point([closestNursing.onspdLatitude!, closestNursing.onspdLongitude!]),
		to,
	)
	const closestResidentialDistance = calculateDistance(
		turf.point([closestResidential.onspdLatitude!, closestResidential.onspdLongitude!]),
		to,
	)
	const closestDistance = calculateDistance(turf.point([closestSite.onspdLatitude!, closestSite.onspdLongitude!]), to)

	const residentialUniqueOperators = new Set(residentialSites.map((site) => site.operatorId)).size
	const nursingUniqueOperators = new Set(nursingSites.map((site) => site.operatorId)).size
	const totalUniqueOperators = new Set(elderlySites.map((site) => site.operatorId)).size

	const ratingReducer = (acc: number, site: (typeof sites)[0]): number => {
		if (!site.currentRatings) return acc
		const score = calculateRating(site.currentRatings.overall?.rating)
		acc += score
		return acc
	}

	const residentialAverageRating = (residentialSites.reduce(ratingReducer, 0) / residentialCount / 3) * 100
	const nursingAverageRating = (nursingSites.reduce(ratingReducer, 0) / nursingCount / 3) * 100
	const totalAverageRating = (elderlySites.reduce(ratingReducer, 0) / totalCount / 3) * 100

	const footer: (string | TableCell)[][] = [
		[
			'Residential',
			{ value: formatNumber(closestResidentialDistance), textAlign: 'center' },
			{ value: formatNumber(residentialUniqueOperators), textAlign: 'left' },
			{ value: formatNumber(residentialMedianYear, 0, 0, false), textAlign: 'center' },
			{ value: formatNumber(residentialCount), textAlign: 'center' },
			{ value: formatNumber(residentialBedsMedian), textAlign: 'center' },
			{ value: formatPercentage(residentialAverageRating, 0, 0), textAlign: 'center' },
		],
		[
			'Nursing',
			{ value: formatNumber(closestNursingDistance), textAlign: 'center' },
			{ value: formatNumber(nursingUniqueOperators), textAlign: 'left' },
			{ value: formatNumber(nursingMedianYear, 0, 0, false), textAlign: 'center' },
			{ value: formatNumber(nursingCount), textAlign: 'center' },
			{ value: formatNumber(nursingBedsMedian), textAlign: 'center' },
			{ value: formatPercentage(nursingAverageRating, 0, 0), textAlign: 'center' },
		],
		[
			'Total',
			{ value: formatNumber(closestDistance), textAlign: 'center' },
			{ value: formatNumber(totalUniqueOperators), textAlign: 'left' },
			{ value: formatNumber(totalMedianYear, 0, 0, false), textAlign: 'center' },
			{ value: formatNumber(totalCount), textAlign: 'center' },
			{ value: formatNumber(totalBedsMedian), textAlign: 'center' },
			{ value: formatPercentage(totalAverageRating, 0, 0), textAlign: 'center' },
		],
	]

	const tableData: TableData = [limitedSites, footerHeaders, footer]

	return (
		<Panel height="662px" padding={20}>
			<Table data={tableData} headers={headers} />
		</Panel>
	)
}

export { CompetitionPanel }
