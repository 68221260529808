import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { CareFundingType } from '../../Constants'
import { CareFundingByLadCodeQuery } from '../../graphql/queries/__generated__/CareFundingByLadCodeQuery.graphql'
import { CareFundingByLad } from '../../graphql/queries/CareFundingByLad'
import { formatPercentage } from '../../utils/formatNumber'
import { QuickFact, QuickFactPlaceholder } from '../QuickFact'

interface StateFundedQuickFactProps {
	ladCode: string
	compareRegion?: boolean
}

const StateFundedByLad: React.FC<StateFundedQuickFactProps> = ({ ladCode, compareRegion }) => {
	const { careFundingByLadCode: ccs } = useLazyLoadQuery<CareFundingByLadCodeQuery>(CareFundingByLad, {
		careType: CareFundingType.careHome,
		ladCode,
	})

	const ladValues = ccs.StateFunded[0].values
	const regionValues = ccs.StateFunded[ccs.StateFunded.length - 2].values
	const nationalValues = ccs.StateFunded[ccs.StateFunded.length - 1].values
	const comparisonValues = compareRegion ? regionValues : nationalValues
	const currentLadValue = ladValues[ladValues.length - 1]
	const comparisonValue = comparisonValues[comparisonValues.length - 1]

	return (
		<QuickFact
			heading="StateFunded"
			value={formatPercentage(currentLadValue)}
			comparison={formatPercentage(comparisonValue)}
			isComparable={true}
		/>
	)
}

const Suspended: React.FC<StateFundedQuickFactProps> = (props) => {
	return (
		<React.Suspense fallback={<QuickFactPlaceholder />}>
			<StateFundedByLad {...props} />
		</React.Suspense>
	)
}

export { Suspended as StateFundedByLad }
