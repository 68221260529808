import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { openAiVersions } from '../../../Constants'
import { OpenAiVersions, PromptQuery } from '../../../graphql/queries/__generated__/PromptQuery.graphql'
import { Prompt } from '../../../graphql/queries/Prompt'
import { VerticalSpace } from '../../../shared/layout/Space'
import { Label, Panel } from './SubComponents'

interface SummaryPanelProps {
	title?: string
	prompt: string
	cacheKey: string
	version?: OpenAiVersions
	height: number
}

const SummaryPanel: React.FC<SummaryPanelProps> = ({
	title,
	prompt,
	cacheKey,
	height,
	version = openAiVersions.v4,
}) => {
	const data = useLazyLoadQuery<PromptQuery>(Prompt, { content: prompt, key: cacheKey, version })

	if (!data.prompt) {
		return null
	}

	const paragraphArray = data.prompt.response.split('\n')

	return (
		<Panel height={height} padding={20}>
			{title && (
				<>
					<Label point={9}>{title}</Label>
					<VerticalSpace size="xs" />
				</>
			)}
			{paragraphArray.map((item, index) => (
				<div key={index} className="mb-1">
					<Label point={7} bold={false}>
						{item}
					</Label>
				</div>
			))}
		</Panel>
	)
}

export { SummaryPanel }
