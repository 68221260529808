import React from 'react'

interface BadgeProps {
	children: React.ReactNode
	type?: 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark'
}

const Badge: React.FC<BadgeProps> = ({ children, type = 'primary' }) => {
	return (
		<span className={`badge bg-${type}`} style={{ textTransform: 'capitalize' }}>
			{children}
		</span>
	)
}

export { Badge }
