import React from 'react'
import { useFragment, useLazyLoadQuery } from 'react-relay'
import { useNavigate, useParams } from 'react-router-dom'

import { defaultCa } from '../../Constants'
import { RadiusValues } from '../../graphql/queries/__generated__/HourlyWagesByCaQuery.graphql'
import { OutputAreaByLatLongQuery as OaQueryResponseType } from '../../graphql/queries/__generated__/OutputAreaByLatLongQuery.graphql'
import { OutputAreaByLatLong } from '../../graphql/queries/OutputAreaByLatLong'
import { Page } from '../../layout/page/Page'
import { Container } from '../../shared/layout/Grid'
import { VerticalSpace } from '../../shared/layout/Space'
import { Loading } from '../../shared/Loading'
import { Tabbar } from '../lad/Tabbar'
import { SelectedOaFragment$data, SelectedOaFragment$key } from './__generated__/SelectedOaFragment.graphql'
import { SelectedOaFragment } from './SelectedOaFragment'
import { CareHomes } from './tabs/careHomes/CareHomes'
import { Dementia } from './tabs/dementia/Dementia'
import { Economic } from './tabs/economic/Economic'
import { Elderly } from './tabs/elderly/Elderly'
import { ElderlyCareMarket } from './tabs/elderlyCareMarket/ElderlyCareMarket'
import { Explorer } from './tabs/explorer/Explorer'
import { Housing } from './tabs/housing/Housing'
import { Population } from './tabs/population/Population'

type RouteParams = {
	category?: string
	latLong?: string
	radius?: RadiusValues
	tab?: string
}

const Ca: React.FC = () => {
	const navigate = useNavigate()
	const {
		category = 'elderly-care',
		latLong = ``,
		radius = defaultCa.radius,
		tab = 'explorer',
	} = useParams<RouteParams>() // Get lad from the route

	const latLongArray = latLong.split(',')
	const lat = parseFloat(latLongArray[0]) || defaultCa.lat
	const long = parseFloat(latLongArray[1]) || defaultCa.long

	const { outputAreaByLatLong } = useLazyLoadQuery<OaQueryResponseType>(OutputAreaByLatLong, {
		lat: `${lat}`,
		long: `${long}`,
	})
	const selectedOa: SelectedOaFragment$data | null = useFragment<SelectedOaFragment$key>(
		SelectedOaFragment,
		outputAreaByLatLong,
	)

	const { oaCode, ladCode } = selectedOa

	const onTabChange = (key: string) => {
		navigate(`/ca/${lat},${long}/${radius}/${category}/${key}`)
	}

	const onCategoryChange = (category: string) => {
		navigate(`/ca/${lat},${long}/${radius}/${category}`)
	}

	const handleGenerateReport = () => {
		alert('Coming soon')
		// navigate(`/report/ca/${lat},${long}/${radius}`)
	}

	const handleMapSelectionChange = (lat: number, long: number, radius: RadiusValues) => {
		navigate(`/ca/${lat},${long}/${radius}/${category}/${tab}`)
	}

	return (
		<Page title={`Catchment Area - ${lat},${long}`}>
			<Container size="fluid">
				<Tabbar
					category={category}
					tab={tab}
					onCategoryChange={onCategoryChange}
					onTabChange={onTabChange}
					onGenerateReport={handleGenerateReport}
				/>
				<VerticalSpace size="lg" />
				{tab === 'explorer' && (
					<Explorer
						oaCode={oaCode}
						radius={radius}
						ladCode={ladCode}
						lat={lat}
						long={long}
						onChange={handleMapSelectionChange}
					/>
				)}
				{tab === 'economic' && <Economic oaCode={oaCode} radius={radius} />}
				{tab === 'population' && <Population oaCode={oaCode} radius={radius} />}
				{tab === 'housing' && <Housing oaCode={oaCode} radius={radius} />}
				{tab === 'elderly' && <Elderly oaCode={oaCode} radius={radius} />}
				{tab === 'dementia' && <Dementia oaCode={oaCode} radius={radius} />}
				{tab === 'care-homes' && <CareHomes oaCode={oaCode} radius={radius} lat={lat} long={long} />}
				{tab === 'elderly-care-market' && <ElderlyCareMarket oaCode={oaCode} radius={radius} ladCode={ladCode} />}
			</Container>
		</Page>
	)
}

const Suspended: React.FC = () => {
	return (
		<React.Suspense fallback={<Loading height="80vh" />}>
			<Ca />
		</React.Suspense>
	)
}

export { Suspended as Ca }
