import React from 'react'
import { useLazyLoadQuery } from 'react-relay'

import { HousePricesByLadCodeQuery } from '../../graphql/queries/__generated__/HousePricesByLadCodeQuery.graphql'
import { HousePricesByLadCode } from '../../graphql/queries/HousePricesByLad'
import { formatCurrency } from '../../utils/formatNumber'
import { QuickFact, QuickFactPlaceholder } from '../QuickFact'

interface HousePriceAverageQuickFactProps {
	ladCode: string
	compareRegion?: boolean
}

const HousePriceAverageByLad: React.FC<HousePriceAverageQuickFactProps> = ({ ladCode, compareRegion }) => {
	const data = useLazyLoadQuery<HousePricesByLadCodeQuery>(HousePricesByLadCode, { ladCode })
	const { labels, datasets } = data.housePricesByLadCode

	if (datasets.length < 3) {
		return <QuickFact heading="House Price Average" value={'n/a'} isComparable={false} comparison="n/a" />
	}
	const index = labels.length - 1
	const housePriceLad = datasets[0].values[index]
	const housePriceRegion = datasets[1].values[index]
	const housePriceNational = datasets[2].values[index]
	const comparison = compareRegion ? housePriceRegion : housePriceNational

	return (
		<QuickFact
			heading="House Price Average"
			value={formatCurrency(housePriceLad, 0)}
			comparison={formatCurrency(comparison, 0)}
			isComparable={true}
		/>
	)
}

const Suspended: React.FC<HousePriceAverageQuickFactProps> = (props) => {
	return (
		<React.Suspense fallback={<QuickFactPlaceholder />}>
			<HousePriceAverageByLad {...props} />
		</React.Suspense>
	)
}

export { Suspended as HousePriceAverageByLad }
