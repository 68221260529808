// This function is used to get the geo values from the most common dataset structure

function getGeoValuesFromDataset(datasets: any, idx?: number) {
	if (idx === undefined) {
		idx = datasets[0].values.length - 1
	}

	const ca = datasets[0].values[idx]
	const region = datasets[datasets.length - 2].values[idx]
	const country = datasets[datasets.length - 1].values[idx]
	return { ca, region, country }
}

function getValuesFromPopulationDataset(pop: any) {
	const geos = pop?.geographies || []
	if (geos.length === 0) {
		return {}
	}

	const idx = pop.years.length - 1

	const ca = geos[0].ageRanges[0].values[idx]
	const region = geos[geos.length - 2].ageRanges[0].values[idx]
	const country = geos[geos.length - 1].ageRanges[0].values[idx]

	return { ca, region, country }
}

function getGeosFromGeographies(geographies: any) {
	return {
		ca: geographies[0],
		region: geographies[geographies.length - 2],
		country: geographies[geographies.length - 1],
	}
}

export { getGeosFromGeographies, getGeoValuesFromDataset, getValuesFromPopulationDataset }
