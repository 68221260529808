import React, { useEffect, useState } from 'react'
import * as i from 'react-feather'
import { useLazyLoadQuery, useMutation } from 'react-relay'
import { useNavigate, useParams } from 'react-router-dom'

import { DeleteProjectButton } from '../../actions/DeleteProject'
import { getUserId } from '../../Config'
import { ProjectsByUserIdQuery } from '../../graphql/queries/__generated__/ProjectsByUserIdQuery.graphql'
import { ProjectsByUserId } from '../../graphql/queries/ProjectsByUserId'
import { UpdateProject } from '../../graphql/queries/UpdateProject'
import { Page } from '../../layout/page/Page'
import { createProjectModel } from '../../models/project'
import { PrimaryButton } from '../../shared/Button'
import { RightAligned, SpaceBetween } from '../../shared/layout/Alignment'
import { Col, Container, Row } from '../../shared/layout/Grid'
import { VerticalSpace } from '../../shared/layout/Space'
import { Loading } from '../../shared/Loading'
import { Panel } from '../../shared/Panel'
import { parseLatLongFromString } from '../../utils/parseLatLongFromString'

const UpdateProjectPage: React.FC = () => {
	const navigate = useNavigate()
	const { reference } = useParams<{ reference: string }>()

	const [formData, setFormData] = useState({
		name: '',
		client: '',
		date: '',
		numberOfBeds: 60,
		numberOfExtraCareUnits: 0,
		postCode: '',
		latLong: '',
		status: 'pipeline',
	})

	const [validationErrors, setValidationErrors] = useState<Record<string, boolean>>({})

	const data = useLazyLoadQuery<ProjectsByUserIdQuery>(ProjectsByUserId, { userId: getUserId() })
	const graphQlProjects = data.projectsByUserId?.projects || []
	const graphQlProject = graphQlProjects.find((p) => p.reference === reference)

	if (!graphQlProject) {
		return <div>Project not found</div>
	}

	const project = createProjectModel(graphQlProject)

	useEffect(() => {
		if (project) {
			setFormData({
				name: project.name,
				client: project.client,
				date: project.date.split('T')[0],
				numberOfBeds: project.settings.numberOfBeds || 0,
				numberOfExtraCareUnits: project.settings.numberOfExtraCareUnits || 0,
				postCode: project.postalCode || '',
				latLong: `${project.latitude}, ${project.longitude}`,
				status: project.status,
			})
		}
	}, [data])

	useEffect(() => {
		const fetchPostCodeData = async () => {
			if (!formData.postCode.trim()) return

			const response = await fetch(`https://api.postcodes.io/postcodes/${formData.postCode.replaceAll(' ', '')}`)
			const { result } = await response.json()
			if (result) {
				setFormData((prev) => ({
					...prev,
					latLong: result.latitude + ', ' + result.longitude,
				}))

				// Clear latLong validation error when API fills it
				setValidationErrors((prev) => ({
					...prev,
					latLong: false,
				}))
			}
		}

		fetchPostCodeData()
	}, [formData.postCode])

	const [commitMutation, isMutationInFlight] = useMutation(UpdateProject)

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target
		setFormData((prev) => ({
			...prev,
			[name]: value,
		}))

		setValidationErrors((prev) => ({
			...prev,
			[name]: !value.trim(),
		}))
	}

	const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
		const { name, value } = e.target
		setFormData((prev) => ({
			...prev,
			[name]: value,
		}))

		setValidationErrors((prev) => ({
			...prev,
			[name]: !value.trim(),
		}))
	}

	const handleConfirm = () => {
		const requiredFields: (keyof typeof formData)[] = ['name', 'client', 'date', 'latLong']
		const errors: Record<string, boolean> = {}

		// Check required fields
		requiredFields.forEach((field) => {
			if (!formData[field]) {
				errors[field] = true
			}
		})

		// Validate numerical fields
		if (isNaN(Number(formData.numberOfBeds)) || Number(formData.numberOfBeds) < 0) {
			errors.numberOfBeds = true
		}
		if (isNaN(Number(formData.numberOfExtraCareUnits)) || Number(formData.numberOfExtraCareUnits) < 0) {
			errors.numberOfExtraCareUnits = true
		}

		setValidationErrors(errors)

		if (Object.keys(errors).length > 0) {
			console.error(errors)
			return
		}

		const parsedCoordinates = parseLatLongFromString(formData.latLong)

		if (!parsedCoordinates || parsedCoordinates.length !== 2) {
			alert('Invalid latitude/longitude format. Please enter valid coordinates.')
			return
		}

		const [latFloat, longFloat] = parsedCoordinates.map(Number)

		if (isNaN(latFloat) || isNaN(longFloat)) {
			alert('Latitude and Longitude must be valid numbers')
			return
		}

		const parsedDate = new Date(formData.date)
		if (isNaN(parsedDate.getTime())) {
			alert('Invalid date format. Please enter a valid date.')
			return
		}

		commitMutation({
			variables: {
				id: project.id,
				input: {
					reference,
					latitude: latFloat,
					longitude: longFloat,
					name: formData.name,
					client: formData.client,
					date: parsedDate.toISOString(),
					status: formData.status,
					settings: JSON.stringify({
						...project.settings,
						numberOfBeds: formData.numberOfBeds,
						numberOfExtraCareUnits: formData.numberOfExtraCareUnits,
					}),
				},
			},
			onCompleted: () => {
				navigate('/projects')
			},
			onError: (error) => {
				console.error('Mutation error:', error)
			},
		})
	}

	const getFieldClass = (field: string) => (validationErrors[field] ? 'form-control is-invalid' : 'form-control')

	return (
		<Page title="Update Project">
			<Container>
				<Row>
					<Col count={4}></Col>
					<Col count={4}>
						<Panel>
							<SpaceBetween>
								<DeleteProjectButton projectId={project.id} />
							</SpaceBetween>
							<VerticalSpace size="md" />
							<div className="form-group">
								<Row>
									<Col count={12}>
										<label htmlFor="name">Project Name</label>
										<input
											type="text"
											id="name"
											name="name"
											value={formData.name}
											onChange={handleChange}
											className={getFieldClass('name')}
										/>
									</Col>
								</Row>
							</div>
							<VerticalSpace size="sm" />
							<div className="form-group">
								<Row>
									<Col count={12}>
										<label htmlFor="client">Client</label>
										<input
											type="text"
											id="client"
											name="client"
											value={formData.client}
											onChange={handleChange}
											className={getFieldClass('client')}
										/>
									</Col>
								</Row>
							</div>
							<VerticalSpace size="sm" />
							<div className="form-group">
								<Row>
									<Col count={12}>
										<label htmlFor="postCode">Post Code</label>
										<input
											type="text"
											id="postCode"
											name="postCode"
											value={formData.postCode}
											onChange={handleChange}
											className={getFieldClass('postCode')}
										/>
									</Col>
								</Row>
							</div>
							<VerticalSpace size="sm" />
							<div className="form-group">
								<Row>
									<Col count={12}>
										<label htmlFor="latLong">Latitude & Longitude</label>
										<input
											type="text"
											id="latLong"
											name="latLong"
											value={formData.latLong}
											onChange={handleChange}
											className={getFieldClass('latLong')}
										/>
									</Col>
								</Row>
							</div>
							<VerticalSpace size="sm" />
							<div className="form-group">
								<Row>
									<Col count={12}>
										<label htmlFor="date">Project Date</label>
										<input
											type="date"
											id="date"
											name="date"
											value={formData.date}
											onChange={handleChange}
											className={getFieldClass('date')}
										/>
									</Col>
								</Row>
							</div>
							<VerticalSpace size="sm" />
							<div className="form-group">
								<Row>
									<Col count={6}>
										<label htmlFor="numberOfBeds">No. of Beds</label>
										<input
											type="number"
											id="numberOfBeds"
											name="numberOfBeds"
											value={formData.numberOfBeds}
											onChange={handleChange}
											min="0"
											className="form-control"
										/>
									</Col>
									<Col count={6}>
										<label htmlFor="numberOfExtraCareUnits">No. of Extra Care Units</label>
										<input
											type="number"
											id="numberOfExtraCareUnits"
											name="numberOfExtraCareUnits"
											value={formData.numberOfExtraCareUnits}
											onChange={handleChange}
											min="0"
											className="form-control"
										/>
									</Col>
								</Row>
							</div>
							<VerticalSpace size="sm" />
							<div className="form-group">
								<Row>
									<Col count={6}>
										<label htmlFor="status">Status</label>
										<select
											id="status"
											name="status"
											value={formData.status}
											onChange={handleSelectChange}
											className="form-control"
										>
											<option value="pipeline">Pipeline</option>
											<option value="planning">Planning</option>
											<option value="approved">Approved</option>
										</select>
									</Col>
								</Row>
							</div>
							<VerticalSpace size="md" />
							<RightAligned height="auto">
								<PrimaryButton onClick={handleConfirm} disabled={isMutationInFlight}>
									<i.Edit size={14} />
									&nbsp;Update Project
								</PrimaryButton>
							</RightAligned>
						</Panel>
					</Col>
				</Row>
			</Container>
		</Page>
	)
}

const Suspended: React.FC = () => {
	return (
		<React.Suspense fallback={<Loading height="80vh" />}>
			<UpdateProjectPage />
		</React.Suspense>
	)
}

export { Suspended as UpdateProjectPage }
