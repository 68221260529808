import React from 'react'

import { getStage } from '../../Config'
import { themeColors } from '../../Theme'

const EnvBadge: React.FC = () => {
	const stage = getStage()

	if (!['dev', 'dev-staging', 'staging'].includes(stage)) {
		return null
	}

	const color = ['dev', 'dev-staging'].includes(stage) ? themeColors.colorPalette[1] : themeColors.colorPalette[2]
	const style: React.CSSProperties = {
		textTransform: 'capitalize',
		backgroundColor: color,
		color: '#fff',
		padding: '0.15rem 0.5rem',
		borderRadius: '0.25rem',
		marginRight: '1rem',
		fontSize: '0.75rem',
	}

	return <div style={style}>{stage}</div>
}

export { EnvBadge }
