import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Page } from '../../layout/page/Page'
import { Container } from '../../shared/layout/Grid'
import { VerticalSpace } from '../../shared/layout/Space'
import { Tabbar } from './Tabbar'
import { CareHomes } from './tabs/careHomes/CareHomes'
import { Dementia } from './tabs/dementia/Dementia'
import { Economic } from './tabs/economy/Economic'
import { Elderly } from './tabs/elderly/Elderly'
import { ElderlyCareMarket } from './tabs/elderlyCareMarket/ElderlyCareMarket'
import { Explorer } from './tabs/explorer/Explorer'
import { Housing } from './tabs/housing/Housing'
import { Population } from './tabs/population/Population'

type RouteParams = Record<string, string | undefined>

const Lad: React.FC = () => {
	const navigate = useNavigate()

	const { category = 'elderly-care', ladCode = 'E08000008', tab = 'explorer' } = useParams<RouteParams>() // Get lad from the route

	const onTabChange = (key: string) => {
		navigate(`/lads/${ladCode}/${category}/${key}`)
	}

	const handleGenerateReport = () => {
		alert('Coming soon')
		// navigate(`/report/${ladCode}`)
	}

	const onCategoryChange = (category: string) => {
		navigate(`/lads/${ladCode}/${category}`)
	}

	return (
		<Page title={`Local Authority - ${ladCode}`}>
			<Container size="fluid">
				<Tabbar
					category={category}
					tab={tab}
					onCategoryChange={onCategoryChange}
					onTabChange={onTabChange}
					onGenerateReport={handleGenerateReport}
				/>
				<VerticalSpace size="lg" />
				{tab === 'explorer' && <Explorer ladCode={ladCode} />}
				{tab === 'economic' && <Economic ladCode={ladCode} />}
				{tab === 'population' && <Population ladCode={ladCode} />}
				{tab === 'housing' && <Housing ladCode={ladCode} />}
				{tab === 'elderly' && <Elderly ladCode={ladCode} />}
				{tab === 'dementia' && <Dementia ladCode={ladCode} />}
				{tab === 'care-homes' && <CareHomes ladCode={ladCode} />}
				{tab === 'elderly-care-market' && <ElderlyCareMarket ladCode={ladCode} />}
			</Container>
		</Page>
	)
}

export { Lad }
