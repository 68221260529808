import React from 'react'

import { milesRadiusValueInInts } from '../../../../../Constants'
import { RadiusValues } from '../../../../../graphql/queries/__generated__/AAAwardTypesByCaQuery.graphql'
import { GraphQlAaAwardTypes } from '../../../../../models/aaAwardTypes'
import { GraphQlCareStats } from '../../../../../models/careStats'
import { VerticalSpace } from '../../../../../shared/layout/Space'
import { themeColors } from '../../../../../Theme'
import { formatNumber } from '../../../../../utils/formatNumber'
import { getGeoValuesFromDataset } from '../../../../../utils/getGeoValuesFromDataset'
import { Label, Panel } from '../../../shared/SubComponents'
import { Table, TableData } from '../../../shared/Table'

interface Props {
	regionName: string
	height: number
	radius: RadiusValues
	elderlyCareStats: GraphQlCareStats
	allowanceAwards: GraphQlAaAwardTypes
}

const MarketEquilibrium: React.FC<Props> = ({ elderlyCareStats, regionName, allowanceAwards, radius, height }) => {
	const {
		ca: caH,
		region: regionH,
		//country: countryH
	} = getGeoValuesFromDataset(allowanceAwards.higherRate)

	const { stats: ecStats } = elderlyCareStats
	const ecCa = ecStats[0]
	const ecRegion = ecStats[ecStats.length - 2]
	const radiusInt = milesRadiusValueInInts[radius]
	const careCapacityHeaders = ['', `${radiusInt} Mile Catchment`, { value: regionName, color: themeColors.grey }]

	const careCapacityRows: TableData = [
		[
			[
				{ value: 'Total Elderly Beds' },

				{ value: formatNumber(ecCa.numberOfBeds), bold: true },
				formatNumber(ecRegion.numberOfBeds),
			],
			[
				{ value: 'Total Elderly Bed Demand' },
				{ value: formatNumber(caH), bold: true },
				{ value: formatNumber(regionH) },
			],
		],
		[
			[
				{ value: 'Surplus Bed Demand' },
				{
					value: formatNumber(caH - ecCa.numberOfBeds),
					bold: true,
					color: caH - ecCa.numberOfBeds > 0 ? themeColors.primary : undefined,
				},
				{ value: formatNumber(regionH - ecRegion.numberOfBeds) },
			],
		],
	]

	return (
		<Panel height={height} padding={20}>
			<Label point={9}>Market Equilibrium</Label>
			<VerticalSpace size="sm" />
			<Table headers={careCapacityHeaders} data={careCapacityRows} footers={['']} />
		</Panel>
	)
}
export { MarketEquilibrium }
