import React from 'react'

import { LeftAligned, VerticallyAligned } from '../../../shared/layout/Alignment'
import { HeavyText } from '../../../shared/Text'
import { themeColors } from '../../../Theme'

interface LabelProps {
	children: React.ReactNode
	color?: string
	point?: number
	bold?: boolean
	align?: 'left' | 'center' | 'right'
	position?: 'relative' | 'absolute' | 'fixed'
	weight?: 'bold' | 'normal'
}

const Label: React.FC<LabelProps> = ({
	children,
	point = 6,
	color = themeColors.darkText,
	bold = true,
	align = 'left',
	position = 'relative',
}) => {
	const className = bold ? 'fw-bold m-0 p-0' : 'm-0 p-0'
	return (
		<p
			className={className}
			style={{ fontSize: `${point}pt`, letterSpacing: '-0.4px', color, textAlign: align, position }}
		>
			{children}
		</p>
	)
}

interface PanelProps {
	children: React.ReactNode
	height?: string | number
	padding?: string | number
	transparent?: boolean
}

const Panel: React.FC<PanelProps> = ({ children, height = '100%', padding, transparent = false }) => {
	const styles = transparent
		? {
				height,
				padding,
			}
		: {
				borderRadius: '5px',
				border: '0.5px solid #ddd',
				height,
				padding,
				backgroundColor: 'white',
			}

	return <div style={styles}>{children}</div>
}

interface TitlePanelProps {
	title: string
}

const TitlePanel: React.FC<TitlePanelProps> = ({ title }) => {
	return (
		<Panel height={45}>
			<Label point={7}>{title}</Label>
		</Panel>
	)
}

interface TextPanelProps {
	title: string
	subtitle: string
	transparent?: boolean
}

const TextPanel: React.FC<TextPanelProps> = ({ title, subtitle, transparent = false }) => {
	return (
		<Panel height={45} padding={15} transparent={transparent}>
			<LeftAligned>
				<div>
					<Label color={themeColors.grey}>{title}</Label>
					<Label>{subtitle}</Label>
				</div>
			</LeftAligned>
		</Panel>
	)
}

interface PageTitleProps {
	title: string
	subtitle?: string
	primary?: boolean
	point?: number
	children?: React.ReactNode
}

const PageTitle: React.FC<PageTitleProps> = ({ title, subtitle, children, primary = false, point = 10 }) => {
	const color = primary ? themeColors.primary : themeColors.grey
	return (
		<Panel height={45} padding={20} transparent={true}>
			<LeftAligned>
				<Label point={point}>
					<HeavyText>{title}</HeavyText>
				</Label>
				{subtitle && (
					<Label point={point} color={color}>
						&nbsp;&nbsp;|&nbsp;&nbsp;{subtitle}
					</Label>
				)}
				{children}
			</LeftAligned>
		</Panel>
	)
}

interface SectionPanelProps {
	title: string
	subtitle?: string
	primary?: boolean
	point?: number
	children?: React.ReactNode
	transparent?: boolean
}

const SectionPanel: React.FC<SectionPanelProps> = ({
	title,
	subtitle,
	children,
	primary = false,
	point = 10,
	transparent = false,
}) => {
	const color = primary ? themeColors.primary : themeColors.grey
	return (
		<Panel height={45} padding={20} transparent={transparent}>
			<LeftAligned>
				<Label point={point}>{title}</Label>
				{subtitle && (
					<Label point={point} color={color}>
						&nbsp;&nbsp;{subtitle}
					</Label>
				)}
				{children}
			</LeftAligned>
		</Panel>
	)
}

interface BackgroundImagePanelProps {
	src: string
	width?: string | number
	height?: string | number
}

const BackgroundImagePanel: React.FC<BackgroundImagePanelProps> = ({ src, width = '100%', height = 60 }) => {
	return (
		<div>
			<div
				style={{
					backgroundImage: `url(${src})`,
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					height,
					width,
					borderRadius: '5px',
					position: 'relative',
				}}
			/>
		</div>
	)
}

interface ImagePanelProps {
	src: string
	width?: string | number
	transparent?: boolean
	padding?: string | number
}

const ImagePanel: React.FC<ImagePanelProps> = ({ src, width = 140, transparent = false, padding = 0 }) => {
	// try to resolve conflict
	return (
		<Panel padding={padding} transparent={transparent}>
			<VerticallyAligned>
				<img
					src={src}
					alt="Logo"
					style={{
						width,
						objectFit: 'contain',
						display: 'block',
						margin: '0 auto',
					}}
				/>
			</VerticallyAligned>
		</Panel>
	)
}

const PropmatchLogoPanel: React.FC = () => (
	<div style={{ height: 40 }}>
		<ImagePanel src={`${process.env.PUBLIC_URL}/img/logo/logo-propmatch.png`} transparent={true} />
	</div>
)

export {
	BackgroundImagePanel,
	ImagePanel,
	Label,
	PageTitle,
	Panel,
	PropmatchLogoPanel,
	SectionPanel,
	TextPanel,
	TitlePanel,
}
