import React, { useEffect, useState } from 'react'
import * as i from 'react-feather'
import { useMutation } from 'react-relay'
import { useNavigate } from 'react-router-dom'

import { getUserId } from '../../Config'
import { CreateProject } from '../../graphql/queries/CreateProject'
import { Page } from '../../layout/page/Page'
import { PrimaryButton } from '../../shared/Button'
import { RightAligned } from '../../shared/layout/Alignment'
import { Col, Container, Row } from '../../shared/layout/Grid'
import { VerticalSpace } from '../../shared/layout/Space'
import { Panel } from '../../shared/Panel'
import { PageTitle } from '../../shared/Text'
import { parseLatLongFromString } from '../../utils/parseLatLongFromString'

const CreateProjectPage: React.FC = () => {
	const navigate = useNavigate()

	const [formData, setFormData] = useState<{
		name: string
		client: string
		date: string
		numberOfBeds: number
		numberOfExtraCareUnits: number
		postCode: string
		latLong: string
	}>({
		name: '',
		client: '',
		date: new Date().toISOString().split('T')[0],
		numberOfBeds: 60,
		numberOfExtraCareUnits: 0,
		postCode: '',
		latLong: '',
	})

	const [validationErrors, setValidationErrors] = useState<Record<string, boolean>>({})

	useEffect(() => {
		const fetchPostCodeData = async () => {
			if (!formData.postCode.trim()) return

			const response = await fetch(`https://api.postcodes.io/postcodes/${formData.postCode.replaceAll(' ', '')}`)
			const { result } = await response.json()
			if (result) {
				setFormData({
					...formData,
					latLong: result.latitude + ', ' + result.longitude,
				})

				// Clear latLong validation error when API fills it
				setValidationErrors((prev) => ({
					...prev,
					latLong: false,
				}))
			}
		}

		fetchPostCodeData()
	}, [formData.postCode])

	const [commitMutation, isMutationInFlight] = useMutation(CreateProject)

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { name, value } = e.target
		setFormData((prev) => ({
			...prev,
			[name]: value,
		}))

		setValidationErrors((prev) => ({
			...prev,
			[name]: !value.trim(),
		}))
	}

	const handleConfirm = () => {
		const requiredFields: (keyof typeof formData)[] = ['name', 'client', 'date', 'latLong']
		const errors: Record<string, boolean> = {}

		// Check required fields
		requiredFields.forEach((field) => {
			if (!formData[field]) {
				errors[field] = true
			}
		})

		// Validate numerical fields
		if (isNaN(Number(formData.numberOfBeds)) || Number(formData.numberOfBeds) < 0) {
			errors.numberOfBeds = true
		}
		if (isNaN(Number(formData.numberOfExtraCareUnits)) || Number(formData.numberOfExtraCareUnits) < 0) {
			errors.numberOfExtraCareUnits = true
		}

		setValidationErrors(errors)

		if (Object.keys(errors).length > 0) {
			return
		}

		const parsedCoordinates = parseLatLongFromString(formData.latLong)

		if (!parsedCoordinates || parsedCoordinates.length !== 2) {
			alert('Invalid latitude/longitude format. Please enter valid coordinates.')
			return
		}

		const [latFloat, longFloat] = parsedCoordinates.map(Number)

		if (isNaN(latFloat) || isNaN(longFloat)) {
			alert('Latitude and Longitude must be valid numbers')
			return
		}

		const parsedDate = new Date(formData.date)
		if (isNaN(parsedDate.getTime())) {
			alert('Invalid date format. Please enter a valid date.')
			return
		}

		commitMutation({
			variables: {
				input: {
					latitude: latFloat,
					longitude: longFloat,
					name: formData.name,
					client: formData.client,
					date: parsedDate.toISOString(),
					userId: getUserId(),
					settings: JSON.stringify({
						numberOfBeds: formData.numberOfBeds,
						numberOfExtraCareUnits: formData.numberOfExtraCareUnits,
					}),
				},
			},
			updater: (store) => {
				const root = store.getRoot()

				// Get the linked record for 'projectsByUserId'
				const userProjects = root.getLinkedRecord('projectsByUserId', { userId: getUserId() })

				if (!userProjects) return

				// Get the list of existing projects
				const projects = userProjects.getLinkedRecords('projects') || []

				// Get the new project created by the mutation (root field 'createProject')
				const newProject = store.getRootField('createProject') // This should match the field name in your mutation

				// If the mutation returns a new project, append it to the existing list
				if (newProject) {
					userProjects.setLinkedRecords([...projects, newProject], 'projects')
				}
			},
			onCompleted: () => {
				// window.location.href = '/projects'
				navigate('/projects')
			},
			onError: (error) => {
				console.error('Mutation error:', error)
			},
		})
	}

	const getFieldClass = (field: string) => (validationErrors[field] ? 'form-control is-invalid' : 'form-control')

	return (
		<Page title="Create Project">
			<Container>
				<Row>
					<Col count={4}></Col>
					<Col count={4}>
						<Panel>
							<PageTitle>Create Project</PageTitle>
							<VerticalSpace size="md" />
							<div className="form-group">
								<Row>
									<Col count={12}>
										<label htmlFor="name">Project Name</label>
										<input
											type="text"
											id="name"
											name="name"
											value={formData.name}
											onChange={handleChange}
											className={getFieldClass('name')}
										/>
									</Col>
								</Row>
							</div>
							<VerticalSpace size="sm" />
							<div className="form-group">
								<Row>
									<Col count={12}>
										<label htmlFor="client">Client</label>
										<input
											type="text"
											id="client"
											name="client"
											value={formData.client}
											onChange={handleChange}
											className={getFieldClass('client')}
										/>
									</Col>
								</Row>
							</div>
							<VerticalSpace size="sm" />
							<div className="form-group">
								<Row>
									<Col count={12}>
										<label htmlFor="postCode">Post Code</label>
										<input
											type="text"
											id="postCode"
											name="postCode"
											value={formData.postCode}
											onChange={handleChange}
											className={getFieldClass('postCode')}
										/>
									</Col>
								</Row>
							</div>
							<VerticalSpace size="sm" />
							<div className="form-group">
								<Row>
									<Col count={12}>
										<label htmlFor="latLong">Latitude & Longitude</label>
										<input
											type="text"
											id="latLong"
											name="latLong"
											value={formData.latLong}
											onChange={handleChange}
											className={getFieldClass('latLong')}
										/>
									</Col>
								</Row>
							</div>
							<VerticalSpace size="sm" />
							<div className="form-group">
								<Row>
									<Col count={12}>
										<label htmlFor="date">Project Date</label>
										<input
											type="date"
											id="date"
											name="date"
											value={formData.date}
											onChange={handleChange}
											className={getFieldClass('date')}
										/>
									</Col>
								</Row>
							</div>
							<VerticalSpace size="sm" />
							<div className="form-group">
								<Row>
									<Col count={6}>
										<label htmlFor="numberOfBeds">No. of Beds</label>
										<input
											type="number"
											id="numberOfBeds"
											name="numberOfBeds"
											value={formData.numberOfBeds}
											onChange={handleChange}
											min="0"
											className="form-control"
										/>
									</Col>
									<Col count={6}>
										<label htmlFor="numberOfExtraCareUnits">No. of Extra Care Units</label>
										<input
											type="number"
											id="numberOfExtraCareUnits"
											name="numberOfExtraCareUnits"
											value={formData.numberOfExtraCareUnits}
											onChange={handleChange}
											min="0"
											className="form-control"
										/>
									</Col>
								</Row>
							</div>
							<VerticalSpace size="md" />
							<RightAligned height="auto">
								<PrimaryButton onClick={handleConfirm} disabled={isMutationInFlight}>
									<i.PlusCircle size={14} />
									&nbsp;Add Project
								</PrimaryButton>
							</RightAligned>
						</Panel>
					</Col>
				</Row>
			</Container>
		</Page>
	)
}

export { CreateProjectPage }
