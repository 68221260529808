import { getGoogleMapsParams } from '../Config'

function createStaticImageUrl(src: string, width = 600, height = 400, zoom = 16, mapType = 'satellite', scale = 2) {
	const apiKey = getGoogleMapsParams().googleMapsApiKey
	return `${src}&zoom=${zoom}&size=${width}x${height}&scale=${scale}&maptype=${mapType}&key=${apiKey}`
}

function createStaticMapWithPin(lat: number, long: number, zoom = 6) {
	const apiKey = getGoogleMapsParams().googleMapsApiKey
	return `https://maps.googleapis.com/maps/api/staticmap?zoom=${zoom}&size=600x600&maptype=roadmap&markers=color:red%7Clabel:A%7C${lat},${long}&key=${apiKey}&scale=2`
}

/*
function createStaticMapWithMileRadius(lat: number, long: number, zoom = 6, radius = 5) {
	const apiKey = getGoogleMapsParams().googleMapsApiKey
	return `https://maps.googleapis.com/maps/api/staticmap?zoom=${zoom}&size=600x600&maptype=satellite&markers=color:red%7Clabel:A%7C${lat},${long}&key=${apiKey}&scale=2`
}
*/

function createStaticMapWithPins(lat: number, long: number, pins: { lat: number; long: number }[]) {
	const apiKey = getGoogleMapsParams().googleMapsApiKey
	const markers = pins.map((pin) => `color:red%7C${pin.lat},${pin.long}`).join('&markers=')
	const greenPin = `color:0x35DC7E%7Clabel:P%7C${lat},${long}`
	return `https://maps.googleapis.com/maps/api/staticmap?zoom=13&center=${lat},${long}&size=600x600&maptype=roadmap&markers=${greenPin}&markers=${markers}&key=${apiKey}&scale=2`
}

export { createStaticImageUrl, createStaticMapWithPin, createStaticMapWithPins }
